import { GroupingComponentConfig } from "@/types";

export const config: GroupingComponentConfig = {
  hideOnMenu: true,
  info: {
    name: "Milestone",
    type: "milestone",
    collectionKey: "milestones",
    vocabulary: {
      singular_upper: "Milestone",
      singular_lower: "milestone",
      plural_upper: "Milestones",
      plural_lower: "milestones",
    },
  },
  fields: {
    name: {
      title: "Name",
      valueKey: "name",
      fieldData: {
        type: "text",
        defaultValue: "New Milestone",
        placeholder: "Milestone Name",
      },
      required: true,
    },
  },
};

export default config;