import { ComponentConfig } from "@/types";

const config: ComponentConfig = {
  info: {
    name: "Additional Requests",
    addon: true,
    type: "additional_request",
    collectionKey: "additional_requests",
    vocabulary: {
      singular_upper: "Additional Request",
      singular_lower: "additional request",
      plural_upper: "Additional Requests",
      plural_lower: "additional requests",
    },
    default_view: "table",
    icon: "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Fadd.svg?alt=media&token=c6701db5-14a8-49c0-9b26-83f9ea2f2ec3",
  },
  fields: {
    scope_request: {
      title: "Scope Request",
      valueKey: "scope_request",
      fieldData: {
        type: "textArea",
        placeholder: "",
      },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["vendor", "client", "admin"],
      },
      required: true,
    },
    description: {
      title: "Description",
      valueKey: "description",
      fieldData: {
        type: "textArea",
        placeholder: "",
      },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["vendor", "client", "admin"],
      },
      required: true,
    },
    reason: {
      title: "Reason for Opening",
      valueKey: "reason",
      fieldData: {
        type: "textArea",
        placeholder: "",
      },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["vendor", "client", "admin"],
      },
      required: true,
    },
    projected_effort: {
      title: "Projected Effort",
      valueKey: "projected_effort",
      fieldData: {
        type: "number",
        placeholder: "ex: 6",
        minValue: "-10000",
      },
      required: false,
    },
    projected_cost: {
      title: "Projected Cost",
      valueKey: "projected_cost",
      fieldData: {
        type: "number",
        placeholder: "ex: 600",
        minValue: "-10000",
      },
      required: false,
    },
    client_decision: {
      title: "Client Decision",
      valueKey: "client_decision",
      fieldData: {
        type: "select",
        defaultValue: "pending",
        options: {
          pending: {
            id: "pending",
            name: "Pending",
            color: "#4B4B4B",
            backgroundColor: "#FFEAB6",
          },
          dispute: {
            id: "dispute",
            name: "Dispute",
            color: "#4B4B4B",
            backgroundColor: "#dcceff",
          },
          approved: {
            id: "approved",
            name: "Approved",
            color: "#4B4B4B",
            backgroundColor: "#D0F7C3",
          },
          rejected: {
            id: "rejected",
            name: "Rejected",
            color: "#4B4B4B",
            backgroundColor: "#FEE2D5",
          },
        },
        permissions: {
          view: ["viewer", "vendor", "client", "admin"],
          edit: ["vendor", "client", "admin"],
        },
        options_order: ["pending", "dispute", "approved", "rejected"],
      },
      editingDisabled: true,
    },
    created: {
      title: "Date Opened (Age)",
      valueKey: "created",
      fieldData: {
        type: "date_with_age",
      },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["admin"],
      },
      editingDisabled: true,
    },
    request_group: {
      title: "Additional Request Group",
      hidden: true,
      valueKey: "request_group",
      fieldData: {
        type: "object",
        collectionKey: "request_groups",
        primaryKey: "name",
      },
    },
  },
  search: {
    searchableAttributes: ["scope_request", "description", "reason"],
  },
  notifications: {
    slack: {
      color: "#2F7EFF",
      primaryField: "scope_request",
      fields: {
        create: [
          "scope_request",
          "description",
          "reason",
          "projected_effort",
          "projected_cost",
          "client_decision",
        ],
        edit: [
          "scope_request",
          "description",
          "reason",
          "projected_effort",
          "projected_cost",
          "client_decision",
        ],
      },
      buttons: [
        {
          text: "View Request",
        },
      ],
    },
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["admin"],
  },
  cardlist: {
    enabled: false,
    fieldOrder: [
      "scope_request",
      "description",
      "reason",
      "projected_effort",
      "projected_cost",
      "client_decision",
    ],
  },
  table: {
    index_row_key: "scope_request",
    pivotBy: "request_group",
    exclude_from_new_button: ["additional_requests"],
    columns: [
      "scope_request",
      "description",
      "reason",
      "projected_effort",
      "projected_cost",
      "client_decision",
      "created",
    ],
    summaries: {
      projected_effort: {
        type: "sum",
      },
      projected_cost: {
        type: "sum",
      },
    },
  },
};

export default config;