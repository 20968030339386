import { HiOutlineDocumentReport } from "react-icons/hi";
import React from "react";
import { MdOutlineArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ReportNotification } from "@/types/reports";
import { Project } from "@/types/project";

export function NotificationBanner({
  notification,
}: {
  notification: ReportNotification;
}) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row justify-between items-center bg-white w-full p-4 px-5 border border-solid border-neutral-200 rounded-2xl shadow-sm mb-6">
      <div className="flex flex-row gap-3 items-center text-lg">
        <div className="bg-blue-500 text-white flex flex-row items-center justify-center p-2 rounded-xl text-xl">
          <HiOutlineDocumentReport />
        </div>
        <span>A new performance report for {notification.projectTitle} is available 🥳</span>
      </div>
      <div
        onClick={() => navigate(`/p/${
          notification.projectId
        }/performance/${
          notification.reportId
        }`)}
        className="text-white flex flex-row gap-2 items-center text-sm cursor-pointer bg-blue-600 px-4 py-2 rounded-lg hover:bg-blue-500"
      >
        <span>View Report</span>
        <MdOutlineArrowForward className="text-lg" />
      </div>
    </div>
  );
}
