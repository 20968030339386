import { projectsReducers, projectReducers } from "./projects";
import { threadReducer } from "./threads";
import { featureReducers } from "./features";
import { collectionsReducer } from "./collections";
import { doDeepLinkActionReducer } from "./deepLinkActions";
import { historyReducers } from "./history";
import { configsReducers } from "./configs";
import { rolesReducer } from "./roles";
import { growthReducers } from "./growth";
import notifications, { notificationsState } from "./notifications";
import { searchReducer } from "./search";
import { currentUserReducers } from "./user";
import { collaboratorReducers } from "./collaborator";
import { appReducers } from "./app";
import { reportsReducers } from "./reports";
import { asyncThunkCreator, buildCreateSlice } from "@reduxjs/toolkit";
import { pageReducers } from "./page";
import { networkStatusReducer } from "./networkStatus";

export const createAppSlice = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

export const reducer = {
  projects: projectsReducers,
  project: projectReducers,
  network_status: networkStatusReducer,
  threads: threadReducer,
  features: featureReducers,
  collections: collectionsReducer,
  doDeepLinkAction: doDeepLinkActionReducer,
  history: historyReducers,
  configs: configsReducers,
  roles: rolesReducer,
  notifications,
  notificationsState,
  search: searchReducer,
  growth: growthReducers,
  currentUser: currentUserReducers,
  collaborators: collaboratorReducers,
  app: appReducers,
  reports: reportsReducers,
  page: pageReducers,
};
