import { AppState } from "@/types";
import { useSelector } from "react-redux";
import { deepEqualCheck } from "../components/utils";

export type Sections = Record<string, any>;

export function useSections(projectId?: string): {
  sections: Sections;
  loading: boolean;
} {
  if (!projectId) {
    return {
      sections: {},
      loading: true,
    };
  }

  const sections = useSelector(
    (state: AppState) => state.configs[projectId as string]?.sections,
    deepEqualCheck
  );

  return {
    sections: sections ?? {},
    loading: false,
  };
}
