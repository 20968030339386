import React, { Component } from "react";

import moment from "moment";

import "react-vis/dist/style.css";
import {
  XAxis,
  YAxis,
  ChartLabel,
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries,
  DiscreteColorLegend,
  Crosshair,
  FlexibleWidthXYPlot,
} from "react-vis";

import { Card } from "../Objects";

export default class AnalyticsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crosshairValues: [],
    };
  }

  /**
   * Event handler for onMouseLeave.
   * @private
   */
  _onMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };

  /**
   * Event handler for onMouseLeave.
   * @private
   */
  _onMouseLeave = () => {
    this.setState({ crosshairValues: [] });
  };

  /**
   * Event handler for onNearestX.
   * @param {Object} value Selected value.
   * @param {index} index Index of the value in the data array.
   * @private
   */
  _onNearestX = (_, { index }) => {
    const crosshairValues = this.props.items.map((item) => item.data[index]);
    this.setState({ crosshairValues });
  };

  getYScale = () => {
    let max = 0;

    this.props.items.forEach((item) => {
      if (item.data != null) {
        item.data.forEach((datapoint) => {
          max = Math.max(datapoint.y, max);
        });
      }
    });

    return max + 5;
  };

  render() {
    return (
      <div>
        <div
          style={{
            fontSize: 16,
            fontWeight: 500,
            paddingBottom: 15,

            color: "#4e4e4e",
            fontFamily: "Brown",
            fontWeight: 500,
            textAlign: "left",
          }}
        >
          {this.props.title}
        </div>

        <Card style={{ padding: "30px" }}>
          <div
            style={{
              display: "inline-block",
              float: "right",
            }}
          />

          <FlexibleWidthXYPlot
            onMouseLeave={this._onMouseLeave}
            height={300}
            xType={this.props.xType}
            yDomain={[0, this.getYScale()]}
            margin={{ left: 60, right: 60 }}
            stackBy={this.props.stackBy}
          >
            <HorizontalGridLines />

            <XAxis
              attr="x"
              attrAxis="y"
              tickFormat={function tickFormat(d) {
                return moment(d).format("M/D");
              }}
            />

            <YAxis attr="y" attrAxis="x" orientation="left" />

            <ChartLabel
              text={this.props.subtitle}
              className="alt-y-label"
              includeMargin={false}
              xPercent={-0.06}
              yPercent={0.45}
              style={{
                transform: "rotate(-90)",
                textAnchor: "end",
              }}
            />

            {this.props.seriesType == "line" &&
              this.props.items.map((item, index) => (
                <LineSeries
                  key={item.name}
                  opacity={0.5}
                  strokeStyle="solid"
                  color={item.color}
                  data={item.data}
                  onNearestX={index === 0 ? this._onNearestX : () => {}}
                />
              ))}
            {this.props.seriesType == "verticalBar" &&
              this.props.items.map((item, index) => (
                <VerticalBarSeries
                  key={item.name}
                  strokeStyle="solid"
                  color={item.color}
                  data={item.data}
                  onNearestX={index === 0 ? this._onNearestX : () => {}}
                />
              ))}
            {this.state.crosshairValues[0] ? (
              <Crosshair values={this.state.crosshairValues}>
                <div
                  style={{
                    background: "white",
                    padding: "20px",
                    borderRadius: "8px",
                    width: "150px",
                    border: "1px solid #f2f2f2",
                    boxShadow: "0 1px 5px 0 rgba(0,0,0,.1)",
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <h3>{this.props.subtitle}</h3>
                  {this.props.items.map((item, index) => (
                    <p
                      key={`${item.name}-${index}`}
                      style={{ color: "#67696B" }}
                    >
                      {item.name}: {this.state.crosshairValues[index].y}
                    </p>
                  ))}
                </div>
              </Crosshair>
            ) : null}
          </FlexibleWidthXYPlot>

          <DiscreteColorLegend
            style={{ textAlign: "center" }}
            orientation="horizontal"
            items={this.props.items.map((item) => ({
              title: item.name,
              color: item.color,
            }))}
          />
        </Card>
      </div>
    );
  }
}
