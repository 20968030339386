import React, { useState, useRef, useEffect } from 'react'
import { Input, Select, Tooltip } from 'antd'
import _ from 'lodash'
import LinkIcon from '../../../../images/link_icon.svg'
import LockedIcon from '../../../../images/locked_icon.svg'

const { Option } = Select

export default function Link({ error, isEditing, field, updateField, disabled }) {
  const [fieldEditable, setFieldEditable] = useState(field)

  function updateTitle(e) {
    var value = e.target.value

    var fieldCopy = _.cloneDeep(field)

    fieldCopy['title'] = value

    setFieldEditable(fieldCopy)
  }

  function sendUpdate() {
    updateField(fieldEditable)
  }

  return (
    <div>
      <div className='fields'>
        <div class='header-fields'>
          <img
            className='header-icon'
            style={{ height: '17px' }}
            src={LinkIcon}
          />
          <span className='header-text'>Link</span>
          {disabled && (
            <Tooltip
              placement='right'
              title={'This is a default field and cannot be edited'}
            >
              <img
                className='locked-icon'
                src={LockedIcon}
              />
            </Tooltip>
          )}
        </div>

        <div className='input-box-with-title'>
          <label class='required'>Field Title {error?.title && <span style={{ marginLeft: "5px", color: "#ff5858" }}>Field Cannot be Blank</span>}</label>
          <div className={'input-value' + (error?.title ? " error" : "")}>
            <Input
              placeholder={'Example: Location Met'}
              value={fieldEditable.title}
              onChange={e => updateTitle(e)}
              disabled={disabled}
              onBlur={() => sendUpdate()}
            />
          </div>
        </div>
      </div>{' '}
    </div>
  )
}
