import React, { useState, useEffect } from "react";

import _ from "lodash";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Input, Select, Tooltip } from "antd";

const { Option } = Select;

import DropdownIcon from "../../../../images/dropdown_icon.svg"
import LockedIcon from "../../../../images/locked_icon.svg"
import PlusPurp from "../../../../images/plus_purp.svg"
import DraggerOption from "../../../../images/dragger_option.svg"
import TrashIcon from "../../../../images/trashv3_icon.svg"

export default function Dropdown({
  isEditing,
  field,
  updateField,
  disabled,
  error,
}) {
  const [fieldEditable, setFieldEditable] = useState(field);


  useEffect(() => {
    // If the parent has been updated, let's update the child
    if (!_.isEqual(fieldEditable, field)) {
      var fieldTemp = _.cloneDeep(field);

      setFieldEditable(fieldTemp);
    }
  }, [field]);

  function updateTitle(e) {
    var value = e.target.value;

    var fieldCopy = _.cloneDeep(field);

    fieldCopy["title"] = value;

    setFieldEditable(fieldCopy);
  }

  function sendUpdate() {
    updateField(fieldEditable);
  }

  function updateOption(newValue, id) {
    var fieldEditableTemp = _.cloneDeep(fieldEditable);

    if (fieldEditableTemp.fieldData?.options) {
      fieldEditableTemp.fieldData.options[id].name = newValue;
    }

    setFieldEditable(fieldEditableTemp);
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function addNewValue() {
    var fieldEditableTemp = _.cloneDeep(fieldEditable);

    // If there is no fieldData, make it
    if (!fieldEditableTemp.fieldData) {
      fieldEditableTemp.fieldData = {};
    }

    // If there are no options
    if (!fieldEditableTemp?.fieldData?.options) {
      fieldEditableTemp.fieldData.options = {};
    }

    if (!fieldEditableTemp?.fieldData?.options_order) {
      fieldEditableTemp.fieldData.options_order = [];
    }

    var newId = makeid(5);

    fieldEditableTemp.fieldData.options[newId] = {
      id: newId,
      name: "",
    };

    fieldEditableTemp.fieldData.options_order.push(newId);

    setFieldEditable(fieldEditableTemp);
  }

  function deleteValue(index) {
    var fieldEditableTemp = _.cloneDeep(fieldEditable);

    if (index > -1) {
      var id = fieldEditableTemp.fieldData?.options_order[index];

      fieldEditableTemp.fieldData.options_order.splice(index, 1);

      delete fieldEditableTemp.fieldData?.options[id];
    }

    setFieldEditable(fieldEditableTemp);
  }

  useEffect(() => {
    // If we upated the child, let's update the parent
    if (!_.isEqual(fieldEditable, field)) {
      updateField(fieldEditable);
    }
  }, [fieldEditable]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    var fieldEditableTemp = _.cloneDeep(fieldEditable);

    const newOptions = reorder(
      _.cloneDeep(fieldEditableTemp.fieldData?.options_order),
      result.source.index,
      result.destination.index
    );

    fieldEditableTemp.fieldData.options_order = newOptions;

    setFieldEditable(fieldEditableTemp);
  }

  return (
    <div>
      <div className="fields">
        <div class="header-fields">
          <img
            className="header-icon"
            src={DropdownIcon}
            style={{ height: "17px" }}
          />
          <span className="header-text">Dropdown</span>

          {disabled && (
            <Tooltip
              placement="right"
              title={"This is a default field and cannot be edited"}
            >
              <img
                className="locked-icon"
                src={LockedIcon}
              />
            </Tooltip>
          )}
        </div>

        <div className="input-box-with-title">
          <label class="required">
            Field Title{" "}
            {error?.title && (
              <span style={{ marginLeft: "5px", color: "#ff5858" }}>
                Field Cannot be Blank
              </span>
            )}
          </label>
          <div className={"input-value" + (error?.title ? " error" : "")}>
            <Input
              disabled={disabled}
              placeholder={"Example: Location Met"}
              value={fieldEditable.title}
              onChange={(e) => updateTitle(e)}
              onBlur={() => sendUpdate()}
            />
          </div>
        </div>

        <div style={{ padding: "0px 10px" }} className="data-item">
          <div className="data-item-value">
            <label class="required">
              Dropdown Options{" "}
              {error?.dropdown && Object.keys(error?.dropdown).length > 0 && (
                <span style={{ marginLeft: "5px", color: "#ff5858" }}>
                  Dropdowns cannot have empty options
                </span>
              )}
              {error?.dropdowns_empty && (
                <span style={{ marginLeft: "5px", color: "#ff5858" }}>
                  Dropdowns must have at least one option
                </span>
              )}
            </label>

            <div className="selection-options-box">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={field?.valueKey}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {fieldEditable?.fieldData?.options_order &&
                        fieldEditable?.fieldData?.options_order?.map(function (
                          id,
                          index
                        ) {
                          var option = fieldEditable?.fieldData?.options[id];
                          var optionError = null;

                          if (error?.dropdown && error?.dropdown[id]) {
                            optionError = true;
                          }

                          if (option) {
                            return (
                              <SelectionOption
                                key={id}
                                value={option}
                                updateOption={updateOption}
                                deleteValue={deleteValue}
                                index={index}
                                id={id}
                                error={optionError}
                              />
                            );
                          }
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className="add-option-button" onClick={() => addNewValue()}>
                <img
                  className="add-option-icon"
                  src={PlusPurp}
                />
                Add Option
              </div>
            </div>
          </div>
        </div>

        <div className="input-box-with-title">
          <label class="required">Default value</label>
          <div className={"input-value" + (error?.title ? " error" : "")}>
            <Select
              style={{ width: "100%" }}
              value={fieldEditable?.fieldData?.defaultValue}
              onChange={(value) => {
                var fieldCopy = _.cloneDeep(field);

                fieldCopy["fieldData"]["defaultValue"] = value;

                setFieldEditable(fieldCopy);

                sendUpdate();
              }}
            >
              {fieldEditable?.fieldData?.options_order &&
                fieldEditable?.fieldData?.options_order?.map(function (
                  id,
                  index
                ) {
                  var option = fieldEditable?.fieldData?.options[id];
                  if (option) {
                    return (
                      <Option value={id} key={"default_value_" + id}>
                        {option.name}
                      </Option>
                    );
                  }
                })}
            </Select>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export function SelectionOption({
  id,
  value,
  updateOption,
  index,
  deleteValue,
  error,
}) {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="selection-option"
        >
          <img
            {...provided.dragHandleProps}
            className="selection-option-dragger"
            src={DraggerOption}
          />
          <div className={"selection-option-data" + (error ? " error" : "")}>
            <Input
              placeholder="Example: My Great Option"
              value={value.name}
              onChange={(e) => updateOption(e.target.value, id)}
            />
          </div>
          <img
            onClick={() => deleteValue(index)}
            className="selection-option-delete"
            src={TrashIcon}
          />
        </div>
      )}
    </Draggable>
  );
}

var isArrayEqual = function (x, y) {
  var equal = true;

  if (x == null && y != null) {
    return false;
  }

  if (x != null && y == null) {
    return false;
  }

  if (x == null && y == null) {
    return true;
  }

  if (x.length != y.length) {
    equal = false;
  }

  var i = 0;

  x.forEach(function (x_object) {
    if (_.isEqual(x_object, y[i])) {
      i = i + 1;
    } else {
      equal = false;
    }
  });

  return equal;
};
