import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppState } from "@/types";

// Case reducers
const historySlice = createSlice({
  name: "history",
  initialState: {},
  reducers: {
    loadHistory: (
      state: AppState["history"],
      action: PayloadAction<{
        objectId: string;
        history: any;
      }>
    ) => {
      const { objectId, history } = action.payload;

      if (!state[objectId]) {
        state[objectId] = {};
      }

      state[objectId] = { ...history };
    },
  },
});

export const historyReducers = historySlice.reducer;
export const { loadHistory } = historySlice.actions;