import React from "react";

export default function ChatBubbleGrayIcon(props) {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      version="1.1"
      {...props}
    >
      <title>chat_bubble_gray</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1-Copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="DevManage-Screen"
          transform="translate(-2555.000000, -1217.000000)"
        >
          <g id="Group-6" transform="translate(1325.000000, 998.000000)">
            <g
              id="chat_bubble_gray"
              transform="translate(1230.000000, 219.000000)"
            >
              <path
                d="M10.5,2 C6.12114562,1.99971779 2.4586083,5.32610443 2.03863636,9.68477273 L2,10.09625 L2,19 L10.5,19 C15.1944204,19 19,15.1944204 19,10.5 C19,5.80557963 15.1944204,2 10.5,2 Z"
                id="Path"
              ></path>
              <path
                d="M10,0 C4.84753988,0.000102178668 0.538575186,3.91513902 0.046,9.044 L0,9 L0,10 L0,20 L10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,3.38176876e-16 10,0 Z M10,18.8 L1.2,18.8 L1.2,9.582 L1.24,9.156 C1.69560244,4.42754157 5.82176527,0.914132628 10.562392,1.21802547 C15.3030187,1.52191831 18.9468057,5.53341162 18.7949344,10.2813404 C18.6430631,15.0292692 14.7503571,18.7996938 10,18.8 Z"
                id="Shape"
                fill="#9BA2AB"
                fillRule="nonzero"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
