import React, { useEffect, useState } from "react";
import { Modal, Popover, Switch } from "antd";
import _ from "lodash";
import ProjectCreationModal from "./ProjectCreationModal";

import { BiSearchAlt2 } from "react-icons/bi";
import FloatingActionButton from "../FloatingActionButton";
import Labels from "./Tags/Labels";
import EmptyIcon from "../../images/empty.svg";
import { useCurrentUser } from "../../hooks";
import Loading from "../Loading";
import { useSortedProjects } from "./useProjects";
import { useDispatch } from "react-redux";
import { updateUser } from "../../actions/user";
import { NotificationBanner } from "./NotificationBanner";
import "../../styles/Dash.css";
import { ReportNotification } from "@/types/reports";

export default function Dashboard({ }) {
  const [showArchived, setShowArchived] = useState(false);
  const [creationModalVisible, setCreationModalVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showLabels, setShowLabels] = useState(false);
  const currentUser = useCurrentUser();

  const [hideAvatars, setHideAvatars] = useState(true);

  useEffect(() => {
    if (currentUser.hide_avatars) {
      setHideAvatars(currentUser.hide_avatars);
    } else {
      setHideAvatars(false);
    }
  }, [currentUser.hide_avatars]);

  const dispatch = useDispatch();

  const [filteredReportNotifications, setFilteredReportNotifications] =
    useState<ReportNotification[] | null>(null);

  const {
    activeProjects,
    archivedProjects,
    loading: projectsLoading,
    reportNotifications,
  } = useSortedProjects({
    sorting: "date_started",
    searchTerm,
  });

  useEffect(() => {
    if (reportNotifications) {
      const seenProjectIds = new Set();
      var filteredNotifications = Object.keys(reportNotifications)
        .sort((a, b) => {
          var notificationA = reportNotifications[a];
          var notificationB = reportNotifications[b];

          // If either notification is null, sort it last
          if (!notificationA) return 1;
          if (!notificationB) return -1;

          const dateA = notificationA.date_created
            ? new Date(notificationA.date_created)
            : null;
          const dateB = notificationB.date_created
            ? new Date(notificationB.date_created)
            : null;

          // If either date is null, sort it last
          if (!dateA) return 1;
          if (!dateB) return -1;

          if (dateA > dateB) {
            return -1;
          } else if (dateA < dateB) {
            return 1;
          } else {
            return 0;
          }
        })
        .flatMap((key): ReportNotification | undefined => {
          var notification = reportNotifications[key];

          if (
            notification.unreadUsers.includes(currentUser.id) &&
            !seenProjectIds.has(notification.projectId)
          ) {
            seenProjectIds.add(notification.projectId);
            return notification;
          }
          return undefined; // Explicitly return undefined if condition is not met
        })
        .filter(
          (notification): notification is ReportNotification =>
            notification !== undefined
        );

      setFilteredReportNotifications(filteredNotifications);
    } else {
      setFilteredReportNotifications([]);
    }
  }, [reportNotifications]);

  if (projectsLoading) {
    <Loading />;
  }

  return (
    <div className="App">
      <div className="pb-0.5">
        <Modal
          title="New Project"
          className="p-5"
          open={creationModalVisible}
          closable={false}
          footer={null}
        >
          <ProjectCreationModal
            closeModal={() => setCreationModalVisible(false)}
            currentUser={currentUser}
          />
        </Modal>

        <div className="dashContainer">
          {currentUser.acc_type == "2" && (
            <div className="mb-4 flex gap-3 items-center justify-end">
              <div className="flex flex-row gap-2 items-center">
                Show Avatars
                <Switch
                  size="small"
                  checked={!hideAvatars}
                  onChange={(checked) => {
                    setHideAvatars(!checked);

                    if (currentUser) {
                      dispatch(updateUser({ hide_avatars: !checked }));
                    }
                  }}
                />
              </div>
            </div>
          )}

          {filteredReportNotifications &&
            filteredReportNotifications.map((notification) => {
              return <NotificationBanner notification={notification} />;
            })}

          {currentUser.acc_type == "2" && (
            <div className="mb-4 flex gap-2 items-center justify-end">
              <Popover
                placement="bottom"
                trigger="click"
                content={<Labels hide={() => setShowLabels(false)} />}
                open={showLabels}
                onOpenChange={(open) => setShowLabels(open)}
                id="label-popover"
              >
                <div
                  className={`topbar-button filter
                  ${currentUser.label_filters?.length > 0 ? `enabled` : ""}`}
                  onClick={() => {
                    setShowLabels(!showLabels);
                  }}
                >
                  Filter{" "}
                  {currentUser.label_filters?.length > 0
                    ? `(${currentUser.label_filters?.length})`
                    : null}
                </div>
              </Popover>

              <div className="searchbar">
                <BiSearchAlt2 className="searchbar-icon" />
                <input
                  type="text"
                  className="searchbar-input"
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  placeholder="Search Projects"
                />
              </div>

              <div
                className="topButton"
                onClick={() => setCreationModalVisible(true)}
              >
                New Project
              </div>
            </div>
          )}

          {currentUser.acc_type != "2" && (
            <div style={{ marginBottom: "15px" }}>
              <div className="searchbar">
                <BiSearchAlt2 className="searchbar-icon" />
                <input
                  type="text"
                  className="searchbar-input"
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  placeholder="Search Projects"
                />
              </div>

              <div style={{ clear: "right" }}> </div>
            </div>
          )}

          <div
            className="text-xl text-gray-600 font-medium text-left mb-5"
            style={{
              fontFamily: "Brown",
            }}
          >
            Your Projects
          </div>

          {!projectsLoading && activeProjects.length == 0 && (
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <div>
                <img style={{ width: "80" }} src={EmptyIcon} />
              </div>

              <div
                className="text-black mt-1 text-lg"
                style={{
                  fontFamily: "Avenir Next",
                }}
              >
                No Projects
              </div>
            </div>
          )}

          <div className="projectsGrid">{activeProjects}</div>

          {archivedProjects.length > 0 && (
            <div className="text-gray-700 font-Brown font-medium text-left mb-8 text-lg mt-6">
              Archived
              <span
                className="cursor-pointer ml-2 text-blue-400 font-Brown font-medium text-left mb-20 text-base"
                onClick={() => setShowArchived(!showArchived)}
              >
                {showArchived ? "(hide)" : "(show)"}
              </span>
            </div>
          )}

          {showArchived && archivedProjects.length == 0 && (
            <div style={{ textAlign: "center", marginTop: "30px" }}>
              <div>
                <img style={{ width: "80" }} src={EmptyIcon} />
              </div>

              <div
                className="text-slate-900 mt-1 text-lg"
                style={{
                  fontFamily: "Avenir Next",
                }}
              >
                Nothing Archived
              </div>
            </div>
          )}

          {showArchived && archivedProjects.length != 0 && (
            <div className="projectsGrid">{archivedProjects}</div>
          )}
        </div>

        <FloatingActionButton />
      </div>
    </div>
  );
}
