import { ComponentConfig } from "@/types";

const config: ComponentConfig = {
  info: {
    name: "Bugs",
    addon: false,
    type: "bug",
    collectionKey: "bugs",
    vocabulary: {
      singular_upper: "Bug",
      singular_lower: "bug",
      plural_upper: "Bugs",
      plural_lower: "bugs",
    },
    icon: "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Fbugs.svg?alt=media&token=f2c0e5d6-e83f-42cb-a4c0-b173df519977",
  },
  fields: {
    priority: {
      title: "Priority Level",
      valueKey: "priority",
      hideTitle: true,
      fieldData: {
        type: "tag",
        defaultValue: 3,
        options: {
          3: {
            id: 3,
            name: "Low (P3)",
            color: "#000000",
            backgroundColor: "#ffe800",
          },
          2: {
            id: 2,
            name: "Minor (P2)",
            color: "#000000",
            backgroundColor: "#FFC403",
          },
          1: {
            id: 1,
            name: "Major (P1)",
            color: "#ffffff",
            backgroundColor: "#F18100",
          },
          0: {
            id: 0,
            name: "Critical (P0)",
            color: "#ffffff",
            backgroundColor: "#ff0000",
          },
        },
        options_order: [3, 2, 1, 0],
      },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["vendor", "client", "admin"],
      },
    },
    overages: {
      title: "Overages",
      valueKey: "overages",
      fieldData: {
        type: "reference",
        itemTypes: ["overages"],
        linkedFields: {
          overages: "discussion_tickets",
        },
      },
    },
    status: {
      title: "Status",
      valueKey: "status",
      fieldData: {
        type: "select",
        defaultValue: "open",
        options: {
          open: {
            id: "open",
            name: "Open",
            color: "#4B4B4B",
            backgroundColor: "#FFEAB6",
          },
          in_progress: {
            id: "in_progress",
            name: "In Progress",
            color: "#4B4B4B",
            backgroundColor: "#dcceff",
          },
          in_review: {
            id: "in_review",
            name: "Ready for Review",
            color: "#4B4B4B",
            backgroundColor: "#CEDFFF",
          },
          declined: {
            id: "declined",
            name: "Declined",
            color: "#4B4B4B",
            backgroundColor: "#f7d1c3",
          },
          closed: {
            id: "closed",
            name: "Closed",
            color: "#4B4B4B",
            backgroundColor: "#D0F7C3",
          },
        },
        permissions: {
          view: ["viewer", "vendor", "client", "admin"],
          edit: ["vendor", "client", "admin"],
        },
        options_order: [
          "open",
          "in_progress",
          "in_review",
          "declined",
          "closed",
        ],
      },
      editingDisabled: true,
      hidden: true,
    },
    decline_reason: {
      title: "Decline Reason",
      valueKey: "decline_reason",
      fieldData: {
        type: "textArea",
        placeholder: "Example: Declined because this is actually a feedback",
      },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["vendor", "client", "admin"],
      },
      hidden: false,
      showRules: {
        or: [{ "==": [{ var: "status" }, "declined"] }],
      },
    },
    impacted_status_items: {
      title: "Impacted Status Items",
      valueKey: "impacted_status_items",
      fieldData: {
        type: "reference",
        itemTypes: ["status_items"],
        linkedFields: {
          status_items: "impacting_items",
        },
      },
    },
    created: {
      title: "Date Opened (Age)",
      valueKey: "created",
      fieldData: {
        type: "date_with_age",
      },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["admin"],
      },
      editingDisabled: true,
    },
    description: {
      title: "Description of the bug",
      valueKey: "description",
      fieldData: {
        type: "textArea",
        placeholder:
          "Example: Website crashes when client tries to pay for a milestone in Aloa Pay",
      },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["vendor", "client", "admin"],
      },
      required: true,
    },
    steps: {
      title: "Steps to Reproduce",
      valueKey: "steps",
      fieldData: {
        type: "textArea",
        placeholder:
          "Example: 1) Go to the project page, 2) click on a milestone that’s pending paymemnt, 3) click “Accept and Pay” then it crashes; Alternatively, upload a screen recording going through these steps.",
      },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["vendor", "client", "admin"],
      },
      required: true,
    },
    attachments: {
      title: "Attachments",
      valueKey: "attachments",
      fieldData: { type: "attachments" },
      permissions: {
        view: ["viewer", "vendor", "client", "admin"],
        edit: ["vendor", "client", "admin"],
      },
      hideTitle: true,
    },
  },
  search: {
    searchableAttributes: ["description", "steps"],
  },
  notifications: {
    slack: {
      color: "#E97360",
      primaryField: "description",
      fields: {
        create: [
          "description",
          "steps",
          "location",
          "priority",
          "impacted_status_items",
        ],
        edit: [
          "description",
          "steps",
          "location",
          "priority",
          "status",
          "impacted_status_items",
        ],
      },
      buttons: [
        {
          text: "Mark as Fixed",
        },
      ],
    },
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["admin"],
  },
  badge: {
    rules: { "==": [{ var: "status" }, "open"] },
  },
  table: {
    index_row_key: "description",
    pivotBy: "priority",
    columns: [
      "description",
      "priority",
      "status",
      "created",
      "steps",
      "impacted_status_items",
      "attachments",
    ],
    pivotByFixedValues: [0, 1, 2, 3], // When specified, we populate use this as pivot values and ignore pivotBy
    exclude_from_new_button: ["bugs"],
  },
  cardlist: {
    enabled: true,
    fieldOrder: [
      "priority",
      "created",
      "decline_reason",
      "description",
      "steps",
      "impacted_status_items",
      "attachments",
    ],
    actionButtons: [
      {
        name: "Open",
        field: "status",
        newValue: "open",
        showRules: {
          or: [{ "==": [{ var: "status" }, "in_progress"] }],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
      {
        name: "Re-open",
        actions: [
          {
            type: "update",
            fields: [
              {
                field: "status",
                newValue: "open",
              },
              {
                field: "decline_reason",
                newValue: null,
              },
            ],
          },
        ],
        showRules: {
          or: [
            { "==": [{ var: "status" }, "closed"] },
            { "==": [{ var: "status" }, "in_review"] },
            { "==": [{ var: "status" }, "declined"] },
          ],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
      {
        name: "In Progress",
        actions: [
          {
            type: "update",
            fields: [
              {
                field: "status",
                newValue: "in_progress",
              },
            ],
          },
        ],
        showRules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "open"] },
          ],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
      {
        name: "Ready for Review",
        showRules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "open"] },
            { "==": [{ var: "status" }, "in_progress"] },
          ],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
        actions: [
          {
            type: "update",
            fields: [
              {
                field: "status",
                newValue: "in_review",
              },
            ],
          },
        ],
      },
      {
        name: "Close",
        showRules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "in_review"] },
          ],
        },
        permissions: {
          edit: ["client", "admin"],
        },
        actions: [
          {
            type: "update",
            fields: [{ field: "status", newValue: "closed", hidden: true }],
            buttonTitle: "Close Bug",
          },
        ],
      },
      {
        name: "Decline",
        showRules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "open"] },
          ],
        },
        permissions: {
          edit: ["client", "admin", "vendor"],
        },
        actions: [
          {
            type: "modal",
            message:
              "Please specify the reason you believe this should be declined",
            fields: [
              {
                field: "decline_reason",
                required: true,
              },
              { field: "status", newValue: "declined", hidden: true },
            ],
            buttonTitle: "Confirm",
          },
        ],
      },
    ],
    sorting: {
      age_descending: {
        name: "Sort by age (descending)",
        key: "age_descending",
        value: "created",
        ascending: false,
      },
      age_ascending: {
        name: "Sort by age (ascending)",
        key: "age_ascending",
        value: "created",
        ascending: true,
      },
      priority_descending: {
        name: "Sort by priority (descending)",
        key: "priority_descending",
        value: "priority",
        ascending: false,
      },
      priority_ascending: {
        name: "Sort by priority (ascending)",
        key: "priority_ascending",
        value: "priority",
        ascending: true,
      },
    },
    defaultTab: "open",
    tabs: [
      {
        name: "Open",
        key: "open",
        rules: { "==": [{ var: "status" }, "open"] },
      },
      {
        name: "In Progress",
        key: "in_progress",
        rules: { "==": [{ var: "status" }, "in_progress"] },
      },
      {
        name: "Ready for Review",
        key: "in_review",
        rules: { "==": [{ var: "status" }, "in_review"] },
      },
      {
        name: "Declined",
        key: "declined",
        rules: { "==": [{ var: "status" }, "declined"] },
      },
      {
        name: "Closed",
        key: "closed",
        rules: { "==": [{ var: "status" }, "closed"] },
      },
    ],
  },
};

export default config;