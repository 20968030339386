import { Project } from "@/types/project";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ProjectsState = {
  data: Partial<Project>[];
  loaded: boolean;
};

type ProjectState = {
  data: Project;
  loaded: boolean;
};


const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    data: [],
    loaded: false,
  },
  reducers: {
    loadProjects(
      state: ProjectsState,
      action: PayloadAction<{
        projects: Partial<Project>[];
      }>
    ) {
      state.data = action.payload.projects;
      state.loaded = true;
    },
  },
});

const projectSlice = createSlice({
  name: "project",
  initialState: {
    data: {} as Project,
    loaded: false,
  },
  reducers: {
    loadProject(
      state: ProjectState,
      action: PayloadAction<{
        project: Project;
      }>
    ) {
      state.data = action.payload.project;
      state.loaded = true;
    },
  },
});


export const { loadProjects } = projectsSlice.actions
export const { loadProject } = projectSlice.actions;

export const projectsReducers = projectsSlice.reducer;
export const projectReducers = projectSlice.reducer;

