import React from "react";
import { Droppable } from "react-beautiful-dnd";
import CardListDraggable from "./CardListDraggable";

export default function CardListDroppable({ children }) {
  return (
    <Droppable droppableId="cardListDroppable" type="card">
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          isDraggingOver={snapshot.isDraggingOver}
          style={{
            minHeight: "150px"
          }}
        >
          {children}
        </div>
      )}
    </Droppable>
  );
}
