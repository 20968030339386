import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type NetworkStatusState = {
  saved: boolean;
  message: string;
};

const networkStatusSlice = createSlice({
  name: "network_status",
  initialState: {
    saved: true,
    message: "All Changes Saved",
  },
  reducers: {
    changeNetworkStatus(
      state: NetworkStatusState,
      action: PayloadAction<{
        saved: boolean;
        message: string;
      }>
    ) {
      state.saved = action.payload.saved;
      state.message = action.payload.message;
    },
  },
});

export const networkStatusReducer = networkStatusSlice.reducer;

export const changeNetworkStatus =
  (saved: boolean, message: string) => (dispatch) => {
    dispatch(
      networkStatusSlice.actions.changeNetworkStatus({ saved, message })
    );
  };
