import React from "react";
import * as sanitizeHtml from "sanitize-html";
import styled from "styled-components";
import { createTagSpan } from "./TextField/helpers";
import "./TextField/TextField.scss";
import { useCollaboratorsForProject } from "../../hooks/useCollborators";
import { useCurrentUser } from "../../hooks";

// TODO: Handle replacign tags when the user is not found
export default function MessageBubbleContent({ message }) {
  let content = message.content;

  const collaborators = useCollaboratorsForProject();
  const currentUser = useCurrentUser();

  const tagPhrases = [...content.matchAll(/@([\w|]+)/g)];

  tagPhrases.forEach((phrase) => {
    const user = collaborators.find(
      (collaborator) => collaborator.id === phrase[1]
    );

    if (user) {
      const isCurrentUser = user.id === currentUser.id;
      content = content.replace(phrase[0], createTagSpan(user, isCurrentUser));
    }
  });

  content = content.replace(
    /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g,
    '<a target="_blank" href="$1">$1</a>'
  );

  return (
    <>
      {message.content && (
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(content, {
              allowedTags: ["a", "span"],
              allowedAttributes: {
                a: ["href", "target"],
                span: ["class", "id"],
              },
            }),
          }}
        ></span>
      )}
    </>
  );
}

const CommentBubble = styled.span``;
