import { createSlice } from "@reduxjs/toolkit";
import { initialState } from ".";

export const loadingSlice = createSlice({
  name: "app",
  initialState: initialState.loading,
  reducers: {
    setLoading: (state, action) => {
      state = action.payload;
    },
  },
});

export const appReducers = loadingSlice.reducer;
export const { setLoading } = loadingSlice.actions;
