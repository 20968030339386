import { Dropdown, Menu, Popconfirm } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { BiCheck } from "react-icons/bi";
import { FiMoreVertical } from "react-icons/fi";

export default function Subtask({
  parentObject,
  subtask,
  index,
  updateObject,
}) {
  const [subtaskEditable, setSubtaskEditable] = useState(subtask);

  useEffect(() => {
    if (!_.isEqual(subtask, subtaskEditable)) {
      setSubtaskEditable(subtask);
    }
  }, [subtask]);

  // Function to updateSubtasks
  const subtaskUpdated = (newSubtask) => {
    // Let's get the updated subtasks object
    const subtasks = _.cloneDeep(parentObject.subtasks);

    // Let's find the subtask that was updated
    const index = subtasks.findIndex((subtask) => subtask.id === newSubtask.id);

    // Let's update the subtask
    subtasks[index] = newSubtask;

    // Let's update the object calling the updateObject function
    updateObject(subtasks, "subtasks");
  };

  const confirmDeletion = () => {
    // Let's delete the subtask
    const subtasks = _.cloneDeep(parentObject.subtasks);

    // Let's find the subtask that was updated
    const index = subtasks.findIndex(
      (subtask) => subtask.id === subtaskEditable.id
    );

    // Let's remove the subtask
    subtasks.splice(index, 1);

    // Let's update the object calling the updateObject function
    updateObject(subtasks, "subtasks");
  };

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            confirmDeletion();
          }}
        >
          Delete subtask
        </div>
      ),
    },
  ];

  return (
    <Draggable
      draggableId={"subtask_" + subtask.id}
      key={"subtask_" + subtask.id}
      index={index}
      type="subtask"
    >
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            data-cy={"row-draghandle_subtask_" + parentObject.id}
          >
            <div
              className={`subtask flex flex-row gap-2 items-center h-11 pl-2 bg-white border-solid border-neutral-100 ${subtaskEditable.complete && "opacity-50"
                }`}
            >
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
                placement="bottomRight"
              >
                <FiMoreVertical className="subtask-dropdown text-neutral-400 cursor-pointer w-5 h-5" />
              </Dropdown>
              {!subtaskEditable.complete && (
                <div
                  onClick={() => {
                    setSubtaskEditable({
                      ...subtaskEditable,
                      complete: true,
                    });

                    subtaskUpdated({
                      ...subtaskEditable,
                      complete: true,
                    });
                  }}
                  className="cursor-pointer rounded-full border border-solid border-neutral-300 w-5 h-5 flex flex-row items-center justify-center"
                >
                  <BiCheck className="text-neutral-300" />
                </div>
              )}

              {subtaskEditable.complete && (
                <div
                  onClick={() => {
                    setSubtaskEditable({
                      ...subtaskEditable,
                      complete: false,
                    });

                    subtaskUpdated({
                      ...subtaskEditable,
                      complete: false,
                    });
                  }}
                  className="cursor-pointer rounded-full border border-solid border-green-500 w-5 h-5 flex flex-row items-center justify-center bg-green-500"
                >
                  <BiCheck className="text-white" />
                </div>
              )}
              <input
                placeholder={"Subtask description"}
                className="border-none w-full outline-none text-[13px]"
                value={subtaskEditable.description}
                onChange={(e) => {
                  setSubtaskEditable({
                    ...subtaskEditable,
                    description: e.target.value,
                  });
                }}
                onBlur={() => {
                  // If subtask description changed
                  if (subtaskEditable.description !== subtask.description) {
                    subtaskUpdated(subtaskEditable);
                  }
                }}
                maxLength={100}
              />
            </div>
          </div>
        );
      }}
    </Draggable>
  );
}
