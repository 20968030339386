import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useParams } from "react-router-dom";
import { useFeatureFlagEnabled } from "posthog-js/react";
// Actual Components
import SearchModal from "./Search";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { deepEqualCheck, getReferencedObjects, getSubObjects } from "./utils";
// Actions
import actions from "../actions";
import { setLoading } from "../store/loading";
import _ from "lodash";
import { AppState } from "@/types";
import {
  ProjectTableRoute,
  ProjectAnalyticsRoute,
  ProjectCardListRoute,
  ProjectEditSectionRoute,
  ProjectSettingsRoute,
  ProjectCreateSectionRoute,
} from "./Routes";
import { PerformanceReportsPage } from "./Reports/PerformanceReportsPage";
import { PerformanceReport } from "./Reports/PerformanceReport";
import { Sidebar } from "./Sidebar";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { useSections } from "../hooks/useSections";
import { useProject } from "../hooks/useProject";
import { UserRole, useUserRole } from "../hooks/useUserRole";
import { AccessDenied } from "./Routes/AccessDenied";
import { posthog } from "../clients/posthog";
import { POSTHOG_FEATURE_FLAGS } from "../constants";

export default function App() {
  let { projectId } = useParams();

  const { project, loading } = useProject(projectId, true);

  const currentUser = useCurrentUser();
  const currentUserRole = useUserRole(projectId);
  const { sections: components } = useSections(projectId);

  const collections = useSelector((state: AppState) => state.collections);
  const page = useSelector((state: AppState) => state.page, deepEqualCheck);

  const reportingEnabled =
    (useFeatureFlagEnabled(POSTHOG_FEATURE_FLAGS.reports) &&
      project.performance_reports_enabled) ??
    false;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading && project) {
      posthog.group("project", project.name);
    }
  }, [project, loading]);

  useEffect(() => {
    const cleanupFunctions: (() => void)[] = [];

    if (
      projectId == project.id &&
      page &&
      !_.isEmpty(components) &&
      components[page]
    ) {
      // Get the keys of all data types required for current page
      const collectionKeys = [
        components[page].info.collectionKey,
        ...getSubObjects(components[page]),
        ...getReferencedObjects(components[page]),
      ];

      // Determine if we need to show the loading screen in the app
      // For each collection key, if there's some data in the store for the project,
      // then we can render with initial data and don't need to set screen to loading
      // (we don't want the initial table to render, then go to skeleton, then be back to table
      const collectionDataMissing = collectionKeys.some(
        (key) => !collections?.[projectId!]?.[key]
      );

      dispatch(setLoading(collectionDataMissing));

      Promise.all(
        collectionKeys.map((collection) => {
          try {
            return dispatch(
              actions.collections.fetchCollectionFromFirebase(
                collection,
                projectId,
                null,
                (func) => {
                  cleanupFunctions.push(func);
                }
              )
            );
          } catch (error) {
            return Promise.resolve();
          }
        })
      ).then(() => {
        dispatch(setLoading(false));
      });
    }

    return () => {
      cleanupFunctions.forEach((func) => func());
    };
  }, [page, components, projectId, project.id]);

  if (project.collaborators == null || currentUser.email == null) {
    return null;
  }

  if (currentUserRole == UserRole.NotAllowed) {
    document.title = `Aloa Manage`;
    return <AccessDenied />;
  }

  if (project.name) {
    document.title = `${project.name} - Aloa Manage`;
  }

  return (
    <div>
      <div className={`home ${currentUser.isCompact && "compact-mode"}`}>
        {!loading && project ? (
          <>
            <SearchModal projectId={projectId} components={components} />

            <Sidebar />

            <Routes>
              <Route path="/settings" element={<ProjectSettingsRoute />} />

              <Route path="/analytics" element={<ProjectAnalyticsRoute />} />

              {reportingEnabled && (
                <>
                  <Route
                    path="/performance"
                    element={<PerformanceReportsPage />}
                  />
                  <Route
                    path="/performance/:reportId"
                    element={<PerformanceReport />}
                  />
                </>
              )}

              {Object.keys(components).map((section) => (
                <Route
                  key={section}
                  path={"/settings/editor/section/" + section}
                  element={<ProjectEditSectionRoute section={section} />}
                />
              ))}

              <Route
                path={"/editor/section/create"}
                element={<ProjectCreateSectionRoute />}
              />
              {Object.keys(components).map((section) => (
                <Route
                  key={section}
                  path={"/cardlist/" + section}
                  element={<ProjectCardListRoute section={section} />}
                />
              ))}
              {Object.keys(components).map((section) => (
                <Route
                  key={section}
                  path={"/table/" + section}
                  element={<ProjectTableRoute section={section} />}
                />
              ))}
            </Routes>
          </>
        ) : (
          <div style={{ textAlign: "center", marginTop: "20%" }}>
            <Spin
              size="large"
              indicator={<LoadingOutlined style={{ fontSize: 25 }} spin />}
            />
          </div>
        )}
      </div>
    </div>
  );
}
