import React from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import { getUserInitials } from "../../utils";

export default function TagCard({ user, onClick, selected, onMouseEnter }) {
  return (
    <TagCardContainer
      selected={selected}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      {user.image ? (
        <TagImg src={user.image} />
      ) : (
        <AvatarImg size={20}>{getUserInitials(user)}</AvatarImg>
      )}
      <TagName>{user.name}</TagName>
    </TagCardContainer>
  );
}

const TagCardContainer = styled.div`
  cursor: pointer;
  border-radius: 6px;
  padding: 5px 8px 5px 8px;
  margin: 0 -8px 0 -8px;
  background-color: ${(props) => (props.selected ? "#40a9ff" : "#ffffff")};
  color: ${(props) => (props.selected ? "#ffffff" : "#000000")};
`;

const TagName = styled.div`
  margin-left: 3px;
  display: inline-block;
`;

const TagImg = styled.img`
  border-radius: 10px;
  width: 20px;
  height: 20px;
`;

const AvatarImg = styled(Avatar)`
  color: #f56a00;
  background-color: #fde3cf;
  vertical-align: middle;
`;
