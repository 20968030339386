import React from "react";
import { stringReplaceAll } from "../../utils";
import { FieldProps } from "./types";

export default function TextArea({
  field,
  valueEditable,
  checkPermissionsAndUpdateValue,
  activelyEditing,
  setActivelyEditing,
  subtaskRow = false,
}: FieldProps<string>) {
  return (
    <div className="full-width">
      {!activelyEditing && (
        <div
          style={
            subtaskRow ? { padding: "10px 10px 10px 0px" } : { padding: "10px" }
          }
        >
          <input
            className="table-input"
            placeholder={field.fieldData.placeholder}
            value={stringReplaceAll(valueEditable, "\n", " ")}
            onChange={(e) => checkPermissionsAndUpdateValue(e.target.value)}
            //    onBlur={e => finishedEditing(e, "name")}
            onFocus={() => setActivelyEditing(true)}
          />
        </div>
      )}

      {activelyEditing && (
        <div className="pop-out">
          <textarea
            autoFocus
            className="expanded-input"
            placeholder={field.fieldData.placeholder}
            value={valueEditable}
            onChange={(e) => {
              checkPermissionsAndUpdateValue(e.target.value);
            }}
            //      onBlur={e => finishedEditing(e, "name")}
            onBlur={() => setActivelyEditing(false)}
            style={subtaskRow ? { marginLeft: "-20px" } : {}}
          />
        </div>
      )}
    </div>
  );
}
