import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { fetchCollaborator } from "../../actions/collaborator";
import { Avatar } from "antd";
import { getInitialsFromUser } from "../utils";
import { AppState } from "@/types";

export default function CollaboratorContentWidget({ id }) {
  const dispatch = useDispatch();
  const collaborator = useSelector(
    (state: AppState) => state.collaborators[id] ?? { data: {} }
  ).data;

  useEffect(() => {
    dispatch(fetchCollaborator(id));
  }, [id]);

  if (_.isEmpty(collaborator)) {
    return null;
  } else {
    return (
      <div
        className="user-chip"
        style={{ backgroundColor: "#ffffff", marginBottom: "5px" }}
      >
        {collaborator.image && (
          <img className="user-image" src={collaborator.image} />
        )}

        {!collaborator.image && (
          <Avatar
            size={25}
            style={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              verticalAlign: "middle",
            }}
          >
            {getInitialsFromUser(collaborator)}
          </Avatar>
        )}
        <span className="user-name">{collaborator.name}</span>
      </div>
    );
  }
}
