import { AppState } from "@/types";
import { useSelector } from "react-redux";
import { useProject } from "./useProject";
import { useCurrentUser } from "./useCurrentUser";

export enum UserRole {
  NotAllowed = "not-allowed",
  Admin = "admin",
  User = "user",
  Viewer = "viewer",
}

export function useUserRole(projectId?: string): string {
  const { project } = useProject(projectId);
  const currentUser = useCurrentUser();

  let userRole = UserRole.NotAllowed;

  // Get current user 'role' value from project
  if (project?.collaborators) {
    // If the user is a collaborator, get their role
    var collaboratorSettings = project.collaborators.find(
      (collaborator) => collaborator.user === currentUser.id
    );

    if (collaboratorSettings) {
      userRole = collaboratorSettings.role as UserRole;
    }
  }

  return userRole;
}
