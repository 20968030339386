import React, { Component } from "react";
import { Avatar, Popconfirm, message, Menu } from "antd";
import firebase, { db } from "../../firebase";
import AttachmentCard from "../AttachmentModal/AttachmentCard";
import moment from "moment";
import MessageBubbleContent from "./MessageBubbleContent";
import DefaultAvatar from "../../images/default-avatar.png";

export default class MessageBubble extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deletingSpinnerId: "",
      attachments: [],
    }; // <- set up react state
  }

  componentDidMount() {
    if (this.props.message.attachments != null) {
      this.setState({ attachments: this.props.message.attachments });
    }
  }

  confirm(e) {
    var message = Object.assign({}, this.props.message);
    var attachments = this.state.attachments;

    if (attachments == null || attachments.length == 0) {
      this.props.deleteThread(message, this.props.projectId);
    } else {
      attachments.forEach(
        function (attachment) {
          var desertRef = firebase
            .storage()
            .ref("projects/" + this.props.projectId + "/" + attachment.id);

          // Delete the file
          desertRef
            .delete()
            .then(
              function () {
                // File deleted successfully
                attachments.splice(
                  attachments.findIndex(({ id }) => id == attachment.id),
                  1
                );

                if (attachments.length == 0) {
                  this.props.deleteThread(message, this.props.projectId);
                }
              }.bind(this)
            )
            .catch(
              function (error) {
                // Uh-oh, an error occurred!

                //    message.error("Something went wrong deleting the attachment")
                // Delete the thread anyway
                this.props.deleteThread(message, this.props.projectId);
              }.bind(this)
            );
        }.bind(this)
      );
    }
  }

  cancel(e) {}

  updateAttachments(attachments) {
    db.collection("projects")
      .doc(this.props.projectId)
      .collection("threads")
      .doc(this.props.message.id)
      .set(
        {
          attachments: attachments,
        },
        { merge: true }
      )
      .then(
        function () {
          this.setState({ attachments: attachments });
        }.bind(this)
      )
      .catch(
        function (error) {
          message.error("There was an error removing your attachment: ", error);
        }.bind(this)
      );
  }

  render() {
    var message = Object.assign({}, this.props.message);
    var attachment_cards = [];
    var attachments = this.state.attachments;

    var name = message.user.name;
    var initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();

    if (attachments != null) {
      attachments.forEach(
        function (attachment) {
          var isDeleting = false;

          if (attachment.id == this.state.deletingSpinnerId) {
            isDeleting = true;
          }

          attachment_cards.push(
            <AttachmentCard
              key={attachment.id}
              currentUser={this.props.currentUser}
              attachment={attachment}
              attachments={attachments}
              projectId={this.props.projectId}
              updateAttachments={this.updateAttachments.bind(this)}
              isDeleting={isDeleting}
            />
          );
        }.bind(this)
      );
    }

    if (message.timestamp != null) {
      var date = moment(message.timestamp);
      if (!date.isValid()) {
        message.timestamp = message.timestamp.toDate();
      }
    }

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <Popconfirm
            title="Are you sure you want to delete this comment?"
            onConfirm={this.confirm.bind(this)}
            onCancel={this.cancel}
            okText="Yes"
            cancelText="No"
          >
            <span
              style={{
                fontSize: "12px",
                fontFamily: "Avenir Next",
                cursor: "pointer",
                color: "#0b5eff",
              }}
            >
              Delete
            </span>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    );

    return (
      <div style={{ marginTop: "20px", display: "flex" }}>
        <div style={{ verticalAlign: "top" }}>
          <div
            style={{
              backgroundColor: "#ffffff",
              display: "inline-block",
              verticalAlign: "middle",
              marginTop: "5px",
              width: "100%",
            }}
          >
            {message.user.image != null && message.user.image != "" && (
              <img
                onError={(ev) => (ev.target.src = DefaultAvatar)}
                style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                src={message.user.image}
              />
            )}

            {(message.user.image == null || message.user.image == "") && (
              <Avatar
                size={40}
                style={{
                  fontSize: "14px",
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  verticalAlign: "middle",
                }}
              >
                {initials}
              </Avatar>
            )}
          </div>
        </div>
        <div
          style={{
            width: "95%",
            verticalAlign: "middle",
            textAlign: "left",
            marginLeft: "5px",
          }}
        >
          <div
            style={{
              display: "inline-block",
              verticalAlign: "top",
              marginLeft: "7px",
              textAlign: "left",
              width: "100%",
            }}
          >
            <div className="message-bubble-name">{message.user.name} </div>

            <div style={{ clear: "both" }}> </div>
            {message.content && (
              <div className="comment-content">
                {message.content && <MessageBubbleContent message={message} />}
              </div>
            )}

            <div>{attachment_cards}</div>

            <span
              style={{
                marginLeft: "2px",
                fontWeight: "400",
                fontSize: "14px",
                color: "#939393",
              }}
            >
              {moment(message.timestamp).format("MMM DD, YYYY h:mm a")}
            </span>
            {this.props.currentUser.id == message.user.id && (
              <Popconfirm
                title="Are you sure you want to delete this comment?"
                onConfirm={this.confirm.bind(this)}
                onCancel={this.cancel}
                okText="Yes"
                cancelText="No"
              >
                {" • "}{" "}
                <span
                  style={{
                    fontSize: "14px",
                    fontFamily: "Avenir Next",
                    cursor: "pointer",
                    fontWeight: 500,
                    color: "#518cfe",
                  }}
                >
                  Delete
                </span>
              </Popconfirm>
            )}
          </div>

          <div style={{ clear: "right" }}> </div>
        </div>
      </div>
    );
  }
}
