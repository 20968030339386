import React, { useState, useRef, useEffect } from 'react'
import { Input, Select, Tooltip } from 'antd'
import _ from 'lodash'
import AvatarIcon from '../../../../images/avatar_icon.svg'
import LockedIcon from '../../../../images/locked_icon.svg'

const { Option } = Select

export default function Avatar({ isEditing, field, updateField, disabled }) {
  const [fieldEditable, setFieldEditable] = useState(field)

  function updateTitle(e) {
    var value = e.target.value

    var fieldCopy = _.cloneDeep(field)

    fieldCopy['title'] = value

    setFieldEditable(fieldCopy)
  }

  return (
    <div>
      <div className='fields'>
        <div class='header-fields'>
          <img
            className='header-icon'
            style={{ height: '17px' }}
            src={AvatarIcon}
          />
          <span className='header-text'>Avatar</span>
          {disabled && (
            <Tooltip
              placement='right'
              title={'This is a default field and cannot be edited'}
            >
              <img
                className='locked-icon'
                src={LockedIcon}
              />
            </Tooltip>
          )}
        </div>
        <div className='input-box-with-title'>
          <label class='required'>No Settings</label>
        </div>
      </div>
    </div>
  )
}
