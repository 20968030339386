import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, Dropdown, Menu } from "antd";
import moment from "moment";
import actions from "../../../actions";
import circleDropdownImg from "../../../images/circle_dropdown.svg";
import { deepEqualCheck } from "../../utils";
import { useCurrentUserPermissions } from "../../../hooks";
import { AppState } from "@/types";

export default function TableSectionTitle({
  sectionHeaderValue,
  field,
  projectId,
  collapseSection,
  collapsed,
  component,
  confirmDeletion,
}) {
  // This is to be used if pivot item is an object and it is ediable
  const [sectionObject, setSectionObject] = useState({});
  const [sectionObjectEditable, setSectionObjectEditable] = useState({
    [field?.fieldData?.primaryKey]: "",
  });
  const permissions = useCurrentUserPermissions();
  const [sectionMenuVisible, setSectionMenuVisible] = useState(false);

  // These are also only used if pivot item is a custom object and we need
  // to pull it from the state or make changes
  const collections = useSelector(
    (state: AppState) => state.collections,
    deepEqualCheck
  );

  const dispatch = useDispatch();

  var sectionObjectType = field?.fieldData?.type ?? "text";

  useEffect(() => {
    // If this is a custom object, let's load it from the collection
    let originalObject = {};

    if (sectionObjectType === "object" && sectionHeaderValue !== null) {
      if (
        collections[projectId] &&
        collections[projectId][field.fieldData.collectionKey]
      ) {
        originalObject =
          collections[projectId][field.fieldData.collectionKey][
          sectionHeaderValue
          ];

        setSectionObject(originalObject);
        setSectionObjectEditable(originalObject);
      }
    }
  }, [collections]);

  // This is used for editing the text of the section title if applicable
  function changeSectionObjectPrimaryValue(value) {
    var tempSectionObject = Object.assign({}, sectionObjectEditable);

    tempSectionObject[field.fieldData.primaryKey] = value;

    setSectionObjectEditable(tempSectionObject);
  }

  const menu = (
    <Menu selectedKeys={[]}>
      {sectionObjectType == "object" &&
        permissions?.sections[component.info.collectionKey]?.edit && (
          <Menu.Item>
            <Popconfirm
              title="Deleting group deletes all items within the group, do you want to continue?"
              onConfirm={() => {
                confirmDeletion(sectionObjectType, collections, field);
                setSectionMenuVisible(false);
              }}
              okText="Yes"
              cancelText="No"
            >
              <span data-cy={"section-delete_" + sectionHeaderValue}>
                Delete Group
              </span>
            </Popconfirm>
          </Menu.Item>
        )}
      <Menu.Item>
        <span
          data-cy={"section-collapse_" + sectionHeaderValue}
          onClick={() => {
            setSectionMenuVisible(false);
            collapseSection();
          }}
        >
          {collapsed ? "Expand Group" : "Collapse Group"}
        </span>
      </Menu.Item>
    </Menu>
  );

  function editingFinished() {
    // Check if there is a change in the object
    if (
      sectionObjectEditable[field.fieldData.primaryKey] !=
      sectionObject[field.fieldData.primaryKey]
    ) {
      dispatch(
        actions.collections.updateObjectInCollection(
          sectionObjectEditable,
          field.fieldData.collectionKey,
          projectId,
          component.badge?.rules
        )
      );

      // Now we re-set the object
      setSectionObject(Object.assign({}, sectionObjectEditable));
    }
  }

  return (
    <div className="section-header" data-cy="section-header">
      {field && (
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          visible={sectionMenuVisible}
          onVisibleChange={function (flag) {
            setSectionMenuVisible(flag);
          }}
        >
          <img
            src={circleDropdownImg}
            className="group-dropdown"
            data-cy={"section-actions_" + sectionHeaderValue}
          />
        </Dropdown>
      )}

      {sectionObjectType != "object" &&
        sectionObjectType != "checkbox" &&
        (sectionHeaderValue === null || sectionHeaderValue === "undefined") && (
          <div className="table-section-text" data-cy="section-headval">
            (Empty)
          </div>
        )}

      {(sectionObjectType == "text" ||
        sectionObjectType == "password" ||
        sectionObjectType == "textArea") && (
          <div className="table-section-text" data-cy="section-headval">
            {sectionHeaderValue}
          </div>
        )}

      {(sectionObjectType == "tag" || sectionObjectType == "select") && (
        <div className="table-section-text" data-cy="section-headval">
          {field.fieldData.options[sectionHeaderValue] &&
            field.fieldData.options[sectionHeaderValue].name}
        </div>
      )}

      {sectionObjectType == "checkbox" && (
        <div className="table-section-text" data-cy="section-headval">
          {field.title && (
            <span>
              {(sectionHeaderValue == null ||
                sectionHeaderValue == false ||
                sectionHeaderValue == "false" ||
                sectionHeaderValue == "undefined") && <span>Not </span>}{" "}
              {field.title}
            </span>
          )}
        </div>
      )}

      {(sectionObjectType == "date" ||
        sectionObjectType == "date_with_age") && (
          <div className="table-section-text" data-cy="section-headval">
            <span>
              {sectionHeaderValue == null && <span>No Date</span>}
              {sectionHeaderValue != null && (
                <span> {moment(sectionHeaderValue).format("MMMM Do, YYYY")}</span>
              )}
            </span>
          </div>
        )}

      {sectionObjectType == "object" && (
        <div
          className="table-section-text"
          data-cy={"section-headval-obj_" + sectionHeaderValue}
        >
          {sectionObjectEditable && (
            <input
              className="section-header-text"
              placeholder={field.fieldData.placeholder}
              value={sectionObjectEditable[field.fieldData.primaryKey]}
              onChange={(e) => changeSectionObjectPrimaryValue(e.target.value)}
              onBlur={editingFinished}
            />
          )}

          {(!sectionObjectEditable || sectionObjectEditable == "undefined") && (
            <span>(Empty)</span>
          )}
        </div>
      )}
    </div>
  );
}
