import React from "react";
import MediaQuery from "react-responsive";
import _ from "lodash";
import { DesktopSidebar } from "./DesktopSidebar";
import { MobileSidebar } from "./MobileSidebar";

export function Sidebar() {
  return (
    <>
      <MediaQuery minWidth={701}>
        <DesktopSidebar />
      </MediaQuery>

      <MediaQuery maxWidth={700}>
        <MobileSidebar />
      </MediaQuery>
    </>
  );
}
