interface CircularProgressProps {
  percentage: number;
  size: "small" | "medium" | "large";
}

export const CircularProgress = ({
  percentage,
  size = "large",
}: CircularProgressProps) => {
  // Define size configurations
  const sizeConfig = {
    small: { radius: 10, strokeWidth: 3.5, svgSize: 28 },
    medium: { radius: 14, strokeWidth: 5, svgSize: 40 },
    large: { radius: 28, strokeWidth: 10, svgSize: 80 },
  };

  // Get the current size configuration
  const { radius, strokeWidth, svgSize } = sizeConfig[size];

  // Calculate the stroke-dasharray based on the percentage
  const circumference = 2 * Math.PI * radius;
  const progress = (percentage / 100) * circumference;

  return (
    <div className="flex justify-center items-center">
      <svg
        className="transform rotate-[-90deg]"
        width={svgSize}
        height={svgSize}
      >
        <circle
          className="text-gray-100"
          strokeWidth={strokeWidth}
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={svgSize / 2}
          cy={svgSize / 2}
        />
        <circle
          className={
            percentage > 50
              ? "text-green-500"
              : percentage > 25
                ? "text-yellow-500"
                : "text-red-500"
          }
          strokeWidth={strokeWidth}
          strokeDasharray={`${progress} ${circumference}`}
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r={radius}
          cx={svgSize / 2}
          cy={svgSize / 2}
        />
      </svg>
    </div>
  );
};
