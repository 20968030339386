import sanitizeHtml from "sanitize-html";
import { items, types } from "./config";
import sendNotification from "./sendNotification";

const fieldNotSpecified = "";

/**
 * Send a notification for a new comment
 * @param {Object} project Project notification involves
 * @param {Object} comment New comment notification is about
 * @param {Object} user User intializing notification
 */
export const newComment = (
  project,
  comment,
  user,
  component,
  collaborators = {}
) => {
  // Remove all html tags from the comment, as we don't want them sent to slack
  const commentData = {
    ...comment,
    content: sanitizeHtml(comment.content, {
      allowedTags: [],
      allowedAttributes: {},
    }),
  };

  sendNotification(
    items.comment,
    fieldNotSpecified,
    types.new,
    project,
    commentData,
    user,
    component,
    collaborators
  );
};

export default {
  newComment,
};
