import React from "react";
import { getPrimaryValueFromObject } from "../utils";
import "./ReferenceModal.scss";
import { XIcon } from "@heroicons/react/outline";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

export function LinkedItemCard({
  projectId,
  component,
  linkedItem,
  object,
  removeItem,
  activelyEditing,
  isAdding = false,
  addNewItem,
  type,
}) {
  const navigate = useNavigate();

  const objectName = component.info.vocabulary.singular_upper;

  return (
    <div
      onClick={() => {
        if (isAdding) {
          addNewItem(object, type);
        } else {
          navigate(
            `/p/${projectId}/${component.info.default_view || "cardlist"}/${linkedItem.itemType
            }?item=${object.id}`
          );
        }
      }}
      className="lim-card"
      key={object.id}
    >
      <div className="lim-card-container">
        <div className="lim-card-content">
          <div className="lim-card-item-number">
            <img src={component.info.icon} className="lim-card-title-icon" />
            {objectName} #{object.ext_id}
          </div>
          <div className="lim-card-field-title">
            {
              component.fields[component?.notifications?.slack?.primaryField]
                ?.title
            }
          </div>
          <div className="lim-card-item-primary-value">
            {getPrimaryValueFromObject(object, component)}
          </div>
        </div>
        {activelyEditing && (
          <XIcon
            className="lim-remove-button"
            onClick={(e) => {
              e.stopPropagation();
              removeItem(object, linkedItem.itemType);
            }}
          />
        )}
      </div>
    </div>
  );
}

export function LinkedItemChip({ component, object }) {
  return (
    <div className="user-chip" key={object.id}>
      <img src={component.info.icon} className="component-image" />
      <span className="user-name">
        {getPrimaryValueFromObject(object, component)}
      </span>
    </div>
  );
}
