import bugs from "./BugLoggerComponent";
import feedbacks from "./FeedbacksComponent";
import credentials from "./CredentialsComponent";
import clarifications from "./ClarificationsComponent";
import standups from "./StandupsComponent";
import environments from "./EnvironmentsComponent";
import status_items from "./StatusTableComponent";
import documentations from "./DocumentationsComponent";
import files from "./FilesComponent";
import timesheet_entries from "./TimesheetComponent";
import additional_requests from "./AdditionalRequestComponent";
import milestones from "./MilestoneComponent";
import request_groups from "./RequestGroupComponent";
import timesheet_groups from "./TimesheetGroups";
import meeting_minutes from "./MeetingMinutesComponent";
import overages from "./OveragesComponent";

export default {
  bugs,
  feedbacks,
  credentials,
  clarifications,
  standups,
  environments,
  status_items,
  documentations,
  files,
  timesheet_entries,
  additional_requests,
  milestones,
  request_groups,
  timesheet_groups,
  meeting_minutes,
  overages,
};
