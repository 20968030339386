import { User } from "@/types";
import { useEffect, useState } from "react";
import { db } from "../firebase";

type UsersResponse = {
  users: Record<string, Partial<User>>;
  loading: boolean;
};

export function useUsers(loadAll?: boolean): UsersResponse {
  const [users, setUsers] = useState<Record<string, Partial<User>>>({});

  useEffect(() => {
    if (loadAll) {
      // Legit just going to load every single user (Since we have like...30 of them)
      // Definitley need to change this
      db.collection("users").onSnapshot(function (querySnapshot) {
        var users: Record<string, Partial<User>> = {};

        querySnapshot.forEach(function (doc) {
          var data = doc.data();
          var user = {
            name: data.name,
            image: data.image,
          };
          users[doc.id] = user;
        });

        setUsers(users);
      });
    }
  }, []);

  return {
    users,
    loading: Object.entries(users).length === 0,
  };
}
