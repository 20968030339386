import { Report } from "@/types/reports";

export const getReportPassedPercentage = (report?: Report) => {
  const passedItems = report?.items.filter((item) => item.passed && !item.skipped) ?? [];
  const itemsLength = report?.items.filter((item) => !item.skipped).length ?? 1;
  return Math.round((passedItems.length / itemsLength) * 100);
};

export const formatReportDates = (value: Date | string) => {
  const date = new Date(value);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

// Function to convert date to string relative to current date
// (e.g. 3 hours ago, 4 days ago, 2 weeks ago, 1 month ago, etc.)
// If the date is over a year, return the number of years
export const formatReportDateString = (value: Date | string) => {
  const date = new Date(value);
  const now = new Date();
  const elapsed = now.getTime() - date.getTime();
  const elapsedSeconds = elapsed / 1000;
  const elapsedMinutes = elapsedSeconds / 60;
  const elapsedHours = elapsedMinutes / 60;
  const elapsedDays = elapsedHours / 24;
  const elapsedMonths = elapsedDays / 30;
  const elapsedYears = elapsedDays / 365;

  if (elapsedSeconds < 60) {
    return "just now";
  } else if (elapsedMinutes < 2) {
    return "1 minute ago";
  } else if (elapsedMinutes < 60) {
    return `${Math.floor(elapsedMinutes)} minutes ago`;
  } else if (elapsedHours < 2) {
    return "1 hour ago";
  } else if (elapsedHours < 24) {
    return `${Math.floor(elapsedHours)} hours ago`;
  } else if (elapsedDays < 2) {
    return "1 day ago";
  } else if (elapsedDays < 30) {
    return `${Math.floor(elapsedDays)} days ago`;
  } else if (elapsedMonths < 2) {
    return "1 week ago";
  } else if (elapsedMonths < 12) {
    return `${Math.floor(elapsedMonths)} weeks ago`;
  } else if (elapsedMonths < 2) {
    return "1 month ago";
  } else if (elapsedMonths < 12) {
    return `${Math.floor(elapsedMonths)} months ago`;
  } else if (elapsedYears < 2) {
    return "1 year ago";
  } else {
    return `${Math.floor(elapsedYears)} years ago`;
  }
};
