import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TwitterPicker } from "react-color";
import { v4 as uuidv4 } from "uuid";

import { updateUser } from "../../../actions/user";

import _ from "lodash";

export default function CreateLabel({
  searchTerm,
  setSearchTerm,
  setCreateLabelActive,
  currentUser,
  project,
}) {
  const [colorPickerActive, setColorPickerActive] = useState(false);
  const [newColor, setNewColor] = useState("#e91e63");
  const [newLabel, setNewLabel] = useState(searchTerm);

  const dispatch = useDispatch();

  const createLabelLocal = () => {
    const newId = uuidv4();

    // Let's update the current user
    const newLabels = currentUser.labels ? { ...currentUser.labels } : {};
    newLabels[newId] = {
      name: newLabel,
      color: newColor,
      textColor: "#ffffff",
      id: newId,
    };

    const currentUserTemp = _.cloneDeep(currentUser);

    currentUserTemp.labels = newLabels;

    // Let's also add this label to the project
    const newProjectLabels = currentUserTemp.project_labels || {};
    newProjectLabels[project.id] = newProjectLabels[project.id] || [];
    newProjectLabels[project.id].push(newId);

    currentUserTemp.project_labels = newProjectLabels;

    dispatch(updateUser(currentUserTemp));

    setNewColor("#e91e63");
    setNewLabel("");

    if (setSearchTerm) {
      setSearchTerm("");
    }

    setCreateLabelActive(false);
  };

  return (
    <div className={`create-label-container inline`}>
      <div className={`label-input-container`}>
        <div className="label-color-picker">
          <div className="color-picker-color-outer">
            <div
              onClick={() => {
                setColorPickerActive(!colorPickerActive);
              }}
              className="color-picker-color"
              style={{
                backgroundColor: newColor,
              }}
            />
          </div>

          {colorPickerActive ? (
            <div className="color-picker-container">
              <TwitterPicker
                color={newColor}
                onChange={(color) => {
                  setNewColor(color.hex);
                  setColorPickerActive(false);
                }}
              />
            </div>
          ) : null}
        </div>
        <input
          value={newLabel}
          onChange={(e) => setNewLabel(e.target.value)}
          placeholder="Label Name"
          className="label-name-input"
        />
      </div>

      <div className="create-label-footer">
        <div
          onClick={() => {
            setCreateLabelActive(false);
          }}
          className="create-label-cancel"
        >
          Cancel
        </div>
        <div
          onClick={() => {
            createLabelLocal();
          }}
          className="create-label-create"
        >
          Create
        </div>
      </div>
    </div>
  );
}
