export default {
  hideOnMenu: true,
  info: {
    name: "Timesheet Group",
    type: "timesheet_group",
    collectionKey: "timesheet_groups",
    vocabulary: {
      singular_upper: "Timesheet Group",
      singular_lower: "timesheet group",
      plural_upper: "Timesheet Groups",
      plural_lower: "timesheet groups",
    },
  },
  fields: {
    name: {
      title: "Name",
      valueKey: "name",
      fieldData: {
        type: "text",
        defaultValue: "New Timesheet Group",
        placeholder: "Group Name",
      },
      required: true,
    },
  },
};
