import { AppState } from "@/types";
import { useDispatch, useSelector } from "react-redux";
import { deepEqualCheck } from "../components/utils";
import { useEffect, useState } from "react";
import actions from "../actions";
import defaultRoles from "../components/ProjectConfigs/Roles";
import { Project } from "../types/project";
import { fetchReportNotificationsForProject, fetchReportsForProject } from "../store/reports";
import { loadRolesForProject } from "../store/roles";
import { Roles } from "@/types/roles";

export function useProject(
  projectId?: string,
  root?: boolean
): { project: Project; loading: boolean } {
  const dispatch = useDispatch();

  const project = useSelector(
    (state: AppState) => state.project.data,
    deepEqualCheck
  );

  // Fetch the sections and roles for the project if the project is loaded for the first time
  useEffect(() => {
    const cleanupFunctions: (() => void)[] = [];

    if (root && project && project?.id == projectId) {
      dispatch(fetchReportsForProject(projectId));
      dispatch(fetchReportNotificationsForProject(projectId));

      if (Object.keys(project).length != 0) {
        const fetchSections = dispatch(
          actions.configs.fetchSectionsFromFirebase(projectId, (func) => {
            cleanupFunctions.push(func);
          })
        );

        Promise.allSettled([fetchSections]).then((result) => {
          // Now that we have the configs
          if (project.roles) {
            dispatch(
              loadRolesForProject({ projectId, roles: project.roles })
            );
          } else {
            console.log("Loading default roles");
            dispatch(loadRolesForProject({ projectId, roles: defaultRoles }));
          }
        });
      }
    }

    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup());
    };
  }, [project?.id, projectId]);

  useEffect(() => {
    if (root) {
      // Clear out any previous project being used
      let unsubscribeProject = () => { };

      dispatch(
        actions.project.fetchProject(projectId, (func) => {
          unsubscribeProject = func;
        })
      );

      return unsubscribeProject;
    }
  }, [projectId]);

  // For a brief moment while swapping projects, the project in the store will not match the path
  // this happens when the project is being fetched from the server. Here we want to show the loading screen
  const projectInStoreMatchesPath = project?.id === projectId;

  return { project, loading: !projectInStoreMatchesPath };
}

export function useComponentsForProject(projectId: string) {
  return useSelector((state: AppState) => state.configs[projectId]?.sections);
}
