import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { scrollToRef } from "../utils";
import TableField from "./Fields";
import actions from "../../actions";
import sendNotification from "../../api/notifications/sendNotification";
import _ from "lodash";
import Subtask from "./Subtask";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import {
  useCurrentUser,
  useCurrentUserPermissions,
  useCollaboratorsForProject,
} from "../../hooks";
import { ComponentConfig } from "@/types";

interface TableRowProps {
  object: any;
  projectId: string;
  project: any;
  component: ComponentConfig;
  index: number;
  components: any;
}

function TableRow({
  object,
  projectId,
  project,
  component,
  index,
  components,
}: TableRowProps) {
  const location = useLocation();
  const is13Inch = useMediaQuery({ maxWidth: 1500 });

  const [objectEditable, setObjectEditable] = useState(
    Object.assign({}, object)
  );
  const [editedFields, setEditedFields] = useState<any[]>([]);

  const currentUser = useCurrentUser();
  const permissions = useCurrentUserPermissions();
  const collaborators = useCollaboratorsForProject();

  const [subtasksExpanded, setSubtasksExpanded] = useState(false);

  const dispatch = useDispatch();

  const objectRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const openQuery = "open";

    if (query.has("item") && query.get("item") === object.id && objectRef) {
      scrollToRef(objectRef);
      setTableRowHighlighted(true, 0);

      if (!query.has(openQuery) || !query.get(openQuery)) {
        setTableRowHighlighted(false, 4000);
      }
    }
  }, [location.search]);

  // If object is updated and is out of sync with objectEditable, we will
  // set object editable (this happens with remote changes and realtime)
  useEffect(() => {
    if (!_.isEqual(object, objectEditable)) {
      setObjectEditable({ ...object });
    }
  }, [object]);

  function updateObject(newValue, type) {
    const objectTemp = Object.assign({}, objectEditable);
    objectTemp[type] = newValue;

    if (!editedFields.includes(type)) {
      editedFields.push(type);
      setEditedFields(editedFields);
    }

    let notificationType = "update";

    // If you're updating the index row of an item for the first time, and
    // it was created within an hour, we'll treat the notification as "new"
    if (
      objectTemp[type] &&
      !objectEditable[type] &&
      type == component?.table?.index_row_key
    ) {
      const dateCreated = objectEditable.created;
      const ONE_HOUR = 60 * 60 * 1000;
      if (
        dateCreated &&
        new Date().getTime() - dateCreated.toDate().getTime() < ONE_HOUR
      ) {
        notificationType = "new";
      }
    }

    sendNotification(
      component.info.collectionKey,
      type,
      notificationType,
      project,
      objectTemp,
      currentUser,
      component,
      collaborators
    );

    setObjectEditable(objectTemp);

    dispatch(
      actions.collections.updateObjectInCollection(
        objectTemp,
        component.info.collectionKey,
        projectId,
        component.badge?.rules
      )
    );
  }

  const setTableRowHighlighted = (enabled, highlightDisableDelay) => {
    const currentRef = objectRef.current;
    const highlightClass = "highlight";
    // The baseZ class is necessary on the table row to give a smooth "highlight -> no highlight" transition
    const baseZ = "base-z";

    if (currentRef?.classList) {
      if (enabled) {
        if (currentRef.classList) {
          currentRef.classList.add(baseZ);
          currentRef.classList.add(highlightClass);
        }
      } else {
        return setTimeout(() => {
          if (currentRef.classList) {
            currentRef.classList.remove(highlightClass);
            setTimeout(
              () => currentRef.classList.remove(baseZ),
              highlightDisableDelay + 500
            );
          }
        }, highlightDisableDelay);
      }
    }
  };

  // Check if there is an index row, if so, put it at the front
  let hasIndexField = false;
  let indexField: JSX.Element | null = null;

  if (component.table != null && component.table.index_row_key != null) {
    hasIndexField = true;

    const field = component.fields[component.table.index_row_key];

    if (field != null) {
      indexField = (
        <TableField
          field={field}
          key={component.table.index_row_key}
          value={objectEditable[field.valueKey]}
          updateValue={updateObject}
          collectionKey={component.info.collectionKey}
          isIndexRow={true}
          objectId={object.id}
          project={project}
          created_by_user={objectEditable.created_by_user}
          last_updated_timestamp={objectEditable.last_updated_timestamp}
          components={components}
          subtasksExpanded={subtasksExpanded}
          setSubtasksExpanded={setSubtasksExpanded}
          subtasks={object.subtasks}
          object={object}
        />
      );
    }
  }

  var tableColumns: string[] = [];

  if (component?.table?.columns) {
    tableColumns = component.table.columns;
  } else {
    tableColumns = Object.keys(component.fields);
  }

  return (
    <Draggable draggableId={object.id} key={object.id} index={index}>
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            data-cy={"row-draghandle_" + object.id}
          >
            <div
              className="table-row"
              ref={objectRef}
              data-cy={"table-row-" + object.id}
            >
              {hasIndexField && indexField}

              {tableColumns.map((key, index) => {
                const field = component.fields[key];

                if (
                  component.table?.index_row_key !== key &&
                  field != null &&
                  permissions?.sections[component.info.collectionKey]?.fields[
                    field.valueKey
                  ]?.read != false
                ) {

                  return (
                    <TableField
                      field={field}
                      key={key + "_" + object.id}
                      value={objectEditable[field.valueKey]}
                      updateValue={updateObject}
                      objectId={object.id}
                      project={project}
                      collectionKey={component.info.collectionKey}
                      created_by_user={objectEditable.created_by_user}
                      components={components}
                      last_updated_timestamp={objectEditable.last_updated_timestamp}
                      isIndexRow={!hasIndexField && index == 0}
                      subtasks={object.subtasks}
                      object={object}
                      setSubtasksExpanded={undefined}
                    />
                  );
                }
              })}
              {/*
              <TableRowFooter
                object={object}
                projectId={projectId}
                allowComments={true}
                component={component}
                setTableRowHighlighted={setTableRowHighlighted}
              /> */}
            </div>
            {component.info.collectionKey == "status_items" &&
              subtasksExpanded && (
                <div>
                  <Droppable droppableId={object.id} type="subtask">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {object.subtasks &&
                          object.subtasks.map((subtask, index) => {
                            return (
                              <Subtask
                                subtask={subtask}
                                index={index}
                                key={subtask.id}
                                parentObject={object}
                                updateObject={updateObject}
                              />
                            );
                          })}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  <div
                    onClick={() => {
                      const subtask = {
                        id: Math.random().toString(36).substring(7),
                        description: "",
                        complete: false,
                      };

                      const subtasks = _.cloneDeep(object.subtasks || []);
                      subtasks.push(subtask);

                      updateObject(subtasks, "subtasks");
                    }}
                    className="cursor-pointer flex flex-row gap-2 items-center h-12 pl-6 bg-white border-solid border-neutral-100 text-neutral-300 hover:text-blue-700"
                  >
                    <span className="text-sm">+ Add Subtask</span>
                  </div>
                </div>
              )}
          </div>
        );
      }}
    </Draggable>
  );
}

/*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
const rowEqual = (prevProps, nextProps) => {
  const isEqual =
    _.isEqual(prevProps.component, nextProps.component) &&
    _.isEqual(prevProps.components, nextProps.components) &&
    _.isEqual(prevProps.object, nextProps.object) &&
    prevProps.index == nextProps.index;

  return isEqual;
};

export default React.memo(TableRow, rowEqual);
