import React from "react";
import { useState, useEffect } from "react";

import { Tooltip } from "antd";

export default function Field({ field, view = "cardlist" }) {
  if (field.legacy) {
    return (
      <Tooltip
        title={
          "Legacy fields are fields that have been removed for new items, but still present for existing items."
        }
      >
        <span className={`legacy-chip ${view === "table" ? "table" : ""}`}>
          Legacy
        </span>
      </Tooltip>
    );
  } else {
    return null;
  }
}
