import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Card } from "../Objects";
import { Avatar } from "antd";
import { Link } from "react-router-dom";

import moment from "moment";

import DefaultAvatar from "../../images/default-avatar.png";
import { AppState } from "@/types";
import { User } from "@/types";

const mapDispatchToProps = {};

function SearchCard({
  highlightResult,
  image,
  created,
  timestamp,
  created_by_user,
  comment_user,
  componentInfo,
  id,
  item_id,
  close,
  projectId,
}) {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const created_by_user_data = useSelector(
    (state: AppState) =>
      state.collaborators[created_by_user || comment_user || ""]
  );

  useEffect(() => {
    if (created_by_user_data != null) {
      setUser(created_by_user_data.data);
    }
    setLoading(false);
  }, [created_by_user_data]);

  const getItemUrl = () => {
    return `/p/${projectId}/${componentInfo.default_view ?? "cardlist"}/${componentInfo.collectionKey
      }?${componentInfo.vocabulary.singular_lower.replace(" ", "_")}=${id}`;
  };

  const getCommentUrl = () => {
    return `/p/${projectId}/${componentInfo.default_view ?? "cardlist"}/${componentInfo.collectionKey
      }?${componentInfo.vocabulary.singular_lower.replace(
        " ",
        "_"
      )}=${item_id}&open=true`;
  };

  return (
    <div>
      <Link
        to={componentInfo.name == "Threads" ? getCommentUrl() : getItemUrl()}
        onClick={() => {
          close();
        }}
      >
        {/* @ts-ignore */}
        <Card
          style={{
            width: "100%",
            padding: "5px",
            marginBottom: "12px",
            cursor: "pointer",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                //    alignItems: "center",
                marginTop: "14px",
                marginLeft: "20px",
                marginRight: "10px",
              }}
            >
              <img style={{ height: "20px" }} src={image} />
            </div>
            <div>
              <div
                style={{
                  fontFamily: '"Avenir Next"',
                  marginTop: "3px",
                  fontSize: "16px",
                  borderRadius: "6px",
                  color: "#1f292e",
                  whiteSpace: "pre-wrap",

                  maxWidth: "95%",
                  //    backgroundColor: "#f2f3f5",
                  padding: "5px",
                  fontWeight: 400,
                  //    display: "inline-block",
                  verticalAlign: "middle",
                }}
                dangerouslySetInnerHTML={{
                  __html: highlightResult,
                }}
              ></div>

              {user == null && (timestamp != null || created != null) && (
                <div style={{ marginBottom: "20px" }}>
                  {timestamp != null && (
                    <span
                      style={{
                        marginLeft: "2px",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#939393",
                      }}
                    >
                      {moment(new Date(timestamp._seconds * 1000)).format(
                        "MMM DD, YYYY (h:mm a)"
                      )}
                    </span>
                  )}
                  {created != null && (
                    <span
                      style={{
                        marginLeft: "2px",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#939393",
                      }}
                    >
                      {moment(new Date(created._seconds * 1000)).format(
                        "MMM DD, YYYY (h:mm a)"
                      )}
                    </span>
                  )}
                </div>
              )}

              {user != null && (
                <div style={{ marginBottom: "20px", display: "flex" }}>
                  <div style={{ verticalAlign: "top" }}>
                    <div
                      style={{
                        backgroundColor: "#ffffff",
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginTop: "5px",
                        width: "100%",
                      }}
                    >
                      {user.image != null && user.image != "" && (
                        <img
                          // @ts-ignore
                          onError={(ev) => (ev.target.src = DefaultAvatar)}
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                          }}
                          src={user.image}
                        />
                      )}

                      {(user.image == null || user.image == "") && (
                        <Avatar
                          size={20}
                          style={{
                            fontSize: "14px",
                            color: "#f56a00",
                            backgroundColor: "#fde3cf",
                            verticalAlign: "middle",
                          }}
                        >
                          {((user.name.match(/\b\w/g) || []).shift() || "") +
                            (
                              (user.name.match(/\b\w/g) || []).pop() || ""
                            ).toUpperCase()}
                        </Avatar>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "95%",
                      verticalAlign: "middle",
                      textAlign: "left",
                      marginLeft: "5px",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                        verticalAlign: "top",
                        marginLeft: "4px",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: '"Avenir Next"',
                          fontWeight: "600",
                          color: "rgb(36, 41, 46)",
                          fontSize: "15px",
                          marginTop: "5px",
                          textDecoration: "none",
                          float: "left",
                        }}
                      >
                        {user.name}{" "}
                        {timestamp != null && (
                          <span
                            style={{
                              marginLeft: "2px",
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "#939393",
                            }}
                          >
                            {moment(new Date(timestamp._seconds * 1000)).format(
                              "MMM DD YYYY (h:mm a)"
                            )}
                          </span>
                        )}
                        {created != null && (
                          <span
                            style={{
                              marginLeft: "2px",
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "#939393",
                            }}
                          >
                            {moment(new Date(created._seconds * 1000)).format(
                              "MMM DD YYYY (h:mm a)"
                            )}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
}

export default connect(() => ({}), mapDispatchToProps)(SearchCard);
