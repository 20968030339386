import React from "react";
import AttachmentModal, { Attachment } from "../../AttachmentModal";
import { FieldProps } from "./types";

export default function AttachmentsField({
  valueEditable,
  projectId,
  checkPermissionsAndUpdateValue,
}: FieldProps<Attachment[]>) {
  return (
    <div className="attachments">
      <AttachmentModal
        attachments={valueEditable}
        projectId={projectId!}
        type={"table"}
        updateAttachments={checkPermissionsAndUpdateValue}
      />
    </div>
  );
}
