import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Input, Select, DatePicker, message, Checkbox } from "antd";
import AttachmentModal from "../../AttachmentModal";
import ReferenceModal from "../../ReferenceModal";
import AttachmentCard from "../../AttachmentModal/AttachmentCard";
import moment from "moment";
import {
  newTimestampFromDate,
  stringReplaceAll,
  copyStringToClipboard,
} from "../../utils";
import CollaboratorField from "./CollaboratorField.js";
import LegacyChip from "./LegacyChip.js";
import RequiredChip from "./RequiredChip.js";
import { useCurrentUserPermissions } from "../../../hooks";
import { TextField } from "./TextField";

const { TextArea } = Input;
const Option = Select.Option;

export default function Field({
  field,
  value,
  isEditing,
  updateValue,
  projectId,
  collectionKey,
  objectId = "undefinedObjectId",
  components,
  processedLinkedFields,
  setProcessedLinkedFields,
  overrideEditingDisabled = false,
  overrideRequired,
}) {
  const [valueEditable, setValueEditable] = useState(value);

  const [showPassword, setShowPassword] = useState(false);

  const permissions = useCurrentUserPermissions();

  function checkPermissionsAndUpdateValue(value) {
    var canEdit = false;

    if (permissions?.sections[collectionKey]?.fields[field.valueKey]?.edit) {
      canEdit = true;
    }

    if (
      permissions?.sections[collectionKey]?.fields[field.valueKey]?.edit == null
    ) {
      // If null, check the component permissions
      if (permissions?.sections[collectionKey]?.edit) {
        canEdit = true;
      }
    }

    if (canEdit) {
      setValueEditable(value);
    } else {
      message.warning("You don't have permission to edit " + field.title);
    }
  }

  function validateInput(input) {
    var floatInput = parseFloat(input);
    var valid = true;

    if (field.fieldData.minValue != null) {
      var floatMinValue = parseFloat(field.fieldData.minValue);
      if (floatInput < floatMinValue) {
        valid = false;
        message.error(`Value must be greater than ${floatMinValue}`);
      }
    }

    if (field.fieldData.maxValue != null) {
      var floatMaxValue = parseFloat(field.fieldData.maxValue);
      if (floatInput > floatMaxValue) {
        valid = false;
        message.error(`Value must be less than ${floatMaxValue}`);
      }
    }

    if (valid) {
      checkPermissionsAndUpdateValue(input);
    }
  }

  useEffect(() => {
    // If we are no longer editing, update the parent value
    if (value != valueEditable) {
      // Let's check permissions first

      updateValue(valueEditable, field.valueKey);
    }
  }, [valueEditable]);

  useEffect(() => {
    // If there are changes to the parent
    setValueEditable(value);
  }, [value]);

  if (!field.fieldData) {
    return <div>Error: missing field data</div>;
  }

  return (
    <div className="field">
      {!isEditing && !field.hidden && valueEditable != null && (
        <div>
          {!field.hideTitle && (
            <div className="fieldTitle">
              {field.title} <LegacyChip field={field} />
            </div>
          )}

          {field.hideTitle && <div style={{ marginTop: "19px" }} />}

          {field.fieldData.type == "tag" && (
            <div
              className="tag"
              data-cy={objectId + "_" + field.valueKey}
              style={{
                color:
                  field.fieldData.options[valueEditable] != null
                    ? field.fieldData.options[valueEditable].color
                    : "#000000",
                backgroundColor:
                  field.fieldData.options[valueEditable] != null
                    ? field.fieldData.options[valueEditable].backgroundColor
                    : "#ffffff",
              }}
            >
              {field.fieldData.options[valueEditable] != null
                ? field.fieldData.options[valueEditable].name
                : "(Invalid Value) " + valueEditable}
            </div>
          )}

          {field.fieldData.type == "date_with_age" &&
            valueEditable.seconds != null && (
              <div className="fieldValue">
                {moment(valueEditable.toDate()).format("MMMM Do, YYYY")}
                <span style={{ color: "#ff6c17" }}>
                  {" "}
                  ({moment().diff(moment(valueEditable.toDate()), "days")} days
                  old)
                </span>
              </div>
            )}

          {field.fieldData.type == "checkbox" && (
            <Checkbox checked={valueEditable} disabled={true}></Checkbox>
          )}

          {field.fieldData.type == "date" && valueEditable.seconds != null && (
            <div className="fieldValue">
              {moment(valueEditable.toDate()).format("MMMM Do, YYYY")}
            </div>
          )}

          {(field.fieldData.type == "text" ||
            field.fieldData.type == "textArea") && (
              <TextField
                objectId={objectId}
                field={field}
                value={valueEditable}
              />
            )}

          {field.fieldData.type == "number" && (
            <div
              className="fieldValue"
              data-cy={objectId + "_" + field.valueKey}
            >
              {valueEditable}
            </div>
          )}

          {field.fieldData.type == "link" && (
            <div className="fieldValue">
              <a
                onClick={() => {
                  var url = valueEditable.match(/^https?:/)
                    ? valueEditable
                    : "http://" + valueEditable;
                  window.open(url);
                }}
              >
                {valueEditable}
              </a>
            </div>
          )}

          {field.fieldData.type == "select" && (
            <div className="fieldValue">
              {field.fieldData.options[valueEditable].name}{" "}
            </div>
          )}

          {field.fieldData.type == "password" && (
            <div
              style={{
                display: "inline-block",
              }}
            >
              {showPassword && (
                <div className="passwordField">{valueEditable}</div>
              )}

              {!showPassword && (
                <div className="passwordFieldHidden">{valueEditable}</div>
              )}
              <div
                onClick={() => {
                  copyStringToClipboard(valueEditable);
                  message.success("Password copied to clipboard!");
                  setShowPassword(!showPassword);
                }}
                className="hairline-button reveal-button"
              >
                {showPassword && <span>Hide</span>}

                {!showPassword && <span>Reveal and Copy</span>}
              </div>
            </div>
          )}

          {field.fieldData.type == "attachments" && valueEditable != null && (
            <div style={{ width: "100%" }}>
              {valueEditable.map(function (attachment) {
                return (
                  <AttachmentCard
                    attachment={attachment}
                    attachments={valueEditable}
                    projectId={projectId}
                    updateAttachments={checkPermissionsAndUpdateValue}
                    editable={false}
                    data-cy={objectId + "_" + field.valueKey}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}

      {isEditing &&
        (!field.editingDisabled || overrideEditingDisabled) &&
        !field.legacy && (
          <div>
            <div className={`fieldTitle${field.required ? " py-1.5" : ""}`}>
              {field.title}{" "}
              <RequiredChip field={field} overrideRequired={overrideRequired} />
            </div>

            {(field.fieldData.type == "text" ||
              field.fieldData.type == "password") && (
                <Input
                  style={{ marginTop: "7px" }}
                  placeholder={field.fieldData.placeholder}
                  value={valueEditable}
                  onChange={(e) => checkPermissionsAndUpdateValue(e.target.value)}
                  data-cy={objectId + "_" + field.valueKey}
                />
              )}

            {field.fieldData.type == "checkbox" && (
              <Checkbox
                style={{ marginTop: "7px" }}
                checked={valueEditable}
                onChange={(e) =>
                  checkPermissionsAndUpdateValue(e.target.checked)
                }
              ></Checkbox>
            )}

            {field.fieldData.type == "number" && (
              <Input
                type="number"
                style={{ marginTop: "7px", width: "100px" }}
                placeholder={field.fieldData.placeholder}
                value={stringReplaceAll(valueEditable, "\n", " ")}
                onChange={(e) => validateInput(e.target.value)}
                data-cy={objectId + "_" + field.valueKey}
              />
            )}

            {field.fieldData.type == "link" && (
              <Input
                //  addonBefore="http://"
                placeholder={field.fieldData.placeholder}
                value={valueEditable}
                onChange={(e) => checkPermissionsAndUpdateValue(e.target.value)}
                data-cy={objectId + "_" + field.valueKey}
              />
            )}

            {field.fieldData.type == "textArea" && (
              <TextArea
                placeholder={field.fieldData.placeholder}
                className="textArea"
                value={valueEditable}
                onChange={(e) => checkPermissionsAndUpdateValue(e.target.value)}
                autoSize={{ minRows: 3 }}
                data-cy={objectId + "_" + field.valueKey}
              />
            )}

            {field.fieldData.type == "date" && (
              <DatePicker
                allowClear={false}
                style={{ marginTop: "5px" }}
                value={valueEditable ? moment(valueEditable.toDate()) : null}
                onChange={(e) => {
                  if (e != null) {
                    checkPermissionsAndUpdateValue(
                      newTimestampFromDate(e.toDate())
                    );
                  }
                }}
                format={"MM/DD/YYYY"}
                data-cy={objectId + "_" + field.valueKey}
              />
            )}

            {field.fieldData.type == "select" && (
              <Select
                value={valueEditable}
                className="dropdown"
                onChange={(value) => checkPermissionsAndUpdateValue(value)}
                data-cy={objectId + "_" + field.valueKey}
              >
                {field.fieldData.options_order &&
                  field.fieldData.options_order.map(function (optionId) {
                    var option = field.fieldData.options[optionId];

                    return (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    );
                  })}
              </Select>
            )}

            {field.fieldData.type == "tag" && (
              <Select
                value={valueEditable}
                className="dropdown"
                onChange={(value) => checkPermissionsAndUpdateValue(value)}
                data-cy={objectId + "_" + field.valueKey}
              >
                {field.fieldData.options_order &&
                  field.fieldData.options_order.map(function (optionId) {
                    var option = field.fieldData.options[optionId];

                    return (
                      <Option key={option.id} value={option.id}>
                        {option.name}
                      </Option>
                    );
                  })}
              </Select>
            )}

            {field.fieldData.type == "attachments" && (
              <div className="attachments">
                <AttachmentModal
                  attachments={valueEditable}
                  projectId={projectId}
                  type={collectionKey}
                  updateAttachments={checkPermissionsAndUpdateValue}
                  data-cy={objectId + "_" + field.valueKey}
                />
              </div>
            )}
          </div>
        )}

      {field.fieldData.type == "reference" && (
        <div style={{ width: "100%" }}>
          <ReferenceModal
            referencedItems={valueEditable}
            projectId={projectId}
            updateReferencedItems={checkPermissionsAndUpdateValue}
            collectionKey={collectionKey}
            viewType="card"
            currentObjectId={objectId}
            field={field}
            activelyEditing={isEditing}
            processedLinkedFields={processedLinkedFields}
            setProcessedLinkedFields={setProcessedLinkedFields}
          />
        </div>
      )}

      {field.fieldData.type == "collaborator" && (
        <CollaboratorField
          projectId={projectId}
          field={field}
          valueEditable={valueEditable}
          checkPermissionsAndUpdateValue={checkPermissionsAndUpdateValue}
          activelyEditing={isEditing}
        />
      )}
    </div>
  );
}
