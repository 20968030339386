import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useCurrentUser, useProject, useSetPage, useUsers } from "../../hooks";
import { ThreadView } from "../Objects";
import { TbLayoutSidebarRightCollapse } from "react-icons/tb";
import { IoArrowBackOutline } from "react-icons/io5";
import { PiChatTeardropDotsBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import { AppState } from "../../types";
import { ReportItem } from "./ReportItem";
import { CircularProgress } from "./Common/CircularProgress";
import { ActivityPopover } from "./Common/ActivityPopover";
import { formatReportDates, getReportPassedPercentage } from "./utils";
import { IoMdEye } from "react-icons/io";
import {
  updateReportAsSeen,
  updateReportThreadRead,
  updateReportNotificationAsSeen,
  deleteReport,
} from "../../store/reports";
import { MdOutlineMoreVert } from "react-icons/md";
import { Badge, Dropdown, Menu, message } from "antd";
import { reportsComponent } from "../ComponentConfigs/Reports";
import moment from "moment";
import { DownloadReportLink } from "./Common/ReportPdf";

export function PerformanceReport() {
  const { users, loading: usersLoading } = useUsers(true);

  const { projectId, reportId } = useParams();
  const setPage = useSetPage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  const [commentsActive, setCommentsActive] = useState(false);

  const { project } = useProject(projectId!);
  const { loading, report } = useSelector((state: AppState) => {
    const loading = state.reports.loading;
    const report = state.reports.data?.[projectId!]?.[reportId!];
    return { loading, report };
  });

  const notificationsForProject = useSelector((state: AppState) => {
    const notificationsArray = Object.values(state.reports.notifications);
    return notificationsArray.filter(
      (notification) => notification.projectId === projectId
    );
  });

  const percentage = useMemo(() => getReportPassedPercentage(report), [report]);

  useEffect(() => {
    setPage("performance");
  }, [setPage]);

  useEffect(() => {
    if (report) {
      const lastSeenByUser = report.seenBy
        ?.filter((seenBy) => seenBy.user === currentUser.id)
        .sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())[0];
      const lastSeenTime = lastSeenByUser ? moment(lastSeenByUser.date) : null;
      const currentTime = moment();
      const diffInMinutes = lastSeenTime
        ? currentTime.diff(lastSeenTime, "minutes")
        : null;

      if (!lastSeenByUser || (diffInMinutes !== null && diffInMinutes > 1)) {
        dispatch(
          updateReportAsSeen({
            report,
            userId: currentUser.id,
          })
        );
      }
    }
  }, [currentUser.id, report]);

  // If the report has not been seen by the current user, mark it as seen
  useEffect(() => {
    // If there are notifications, go through and check if the current user has seen the report
    if (notificationsForProject.length > 0) {
      notificationsForProject.forEach((notification) => {
        if (notification.unreadUsers.includes(currentUser.id)) {
          dispatch(
            updateReportNotificationAsSeen({
              notification: notification,
              userId: currentUser.id,
            })
          );
        }
      });
    }
  }, [notificationsForProject]);

  const comments = useSelector(
    (state: AppState) => (reportId && state.threads[reportId]) || {}
  );

  useEffect(() => {
    // Update thread_read_timestamps on report if the user has the comments open
    if (commentsActive) {
      dispatch(
        updateReportThreadRead({
          report,
          userId: currentUser.id,
        })
      );
    }
  }, [commentsActive]);

  const commentCount = useMemo(
    () =>
      Object.values(comments).filter(
        (thread: any) => thread.item_id == report.id
      ).length,
    [comments, report, currentUser.id]
  );
  const unreadComments = useMemo(
    () =>
      Object.values(comments).filter(
        (thread: any) =>
          thread.item_id == report.id &&
          thread.last_updated_timestamp &&
          thread.created_by_user != currentUser.id &&
          thread.last_updated_timestamp.toDate() >
          new Date(report.thread_read_timesetamps?.[currentUser.id] ?? 0)
      ).length > 0,
    [comments, report, currentUser.id]
  );

  const handleShareLink = () => {
    // Copy link to clipboard
    const url = `${window.location.origin}/p/${projectId}/performance/${reportId}`;
    navigator.clipboard.writeText(url);

    // Show notification
    message.success("Link copied to clipboard");
  };
  const handleDeleteReport = () => {
    if (reportId && projectId) {
      dispatch(deleteReport({ reportId, projectId }));
      message.success("Report deleted");
      navigate(`/p/${projectId}/performance`);
    } else {
      message.error("Invalid report or project ID");
    }
  };
// ... existing code ...


  const menu = (
    <Menu>
      <Menu.Item onClick={handleShareLink}>Share link</Menu.Item>
      <Menu.Item onClick={handleDeleteReport} danger>Delete report</Menu.Item>
    </Menu>
  );

  if (loading || !report) {
    return <div>Loading...</div>;
  }

  const reportItems = report.items.filter((item) => !item.skipped);

  return (
    <div className="sectionLarge pb-6">
      <div className="mb-4 flex flex-row gap-2 items-center font-medium cursor-pointer text-lg hover:text-blue-500">
        <span
          className="flex flex-1 items-center"
          onClick={() => navigate(`/p/${projectId}/performance`)}
        >
          <IoArrowBackOutline />
          <span>Back</span>
        </span>
        <DownloadReportLink project={project} report={report}>
          <span className="flex gap-2 items-center text-base text-blue-500">
            <span>Save</span>
            <FaFileDownload size={18} />
          </span>
        </DownloadReportLink>
      </div>
      <div className="flex flex-row gap-10 bg-white p-8 rounded-xl shadow-lg">
        <div
          className={`flex flex-grow ${commentsActive ? "basis-3/5" : "basis-5/5"
            } flex-col gap-5 items-start`}
        >
          <div className="flex flex-row items-start justify-between w-full">
            <div className="flex flex-col gap-1">
              <div className="text-3xl font-medium text-neutral-600">
                Report #{report.ext_id ?? 0}
              </div>
              <div className="text-lg text-neutral-400">
                {formatReportDates(report.periodStart)} -{" "}
                {formatReportDates(report.periodEnd)}
              </div>
            </div>
            <div className="flex flex-row gap-0 items-center mt-2">
              {currentUser.acc_type == "2" && (
                <ActivityPopover report={report} users={users}>
                  <div className="font-semibold text-base flex flex-row gap-1 items-center mr-4 text-blue-500 cursor-pointer">
                    <IoMdEye className="w-5 h-5" />
                    <span>{report.seenBy?.length || 0}</span>
                  </div>
                </ActivityPopover>
              )}
              {commentsActive ? (
                <TbLayoutSidebarRightCollapse
                  onClick={() => {
                    setCommentsActive(false);
                  }}
                  className="w-7 h-7 text-neutral-600 cursor-pointer hover:text-blue-500"
                />
              ) : (
                <span className="flex align-middle gap-0.5">
                  <Badge
                    count={commentCount}
                    color={unreadComments ? "" : "#999999"}
                  >
                    <PiChatTeardropDotsBold
                      onClick={() => {
                        setCommentsActive(true);
                      }}
                      className="w-7 h-7 text-neutral-500 cursor-pointer hover:text-blue-500"
                    />
                  </Badge>
                </span>
              )}
              <Dropdown overlay={menu}>
                <MdOutlineMoreVert className="w-7 h-7 text-neutral-500 cursor-pointer hover:text-blue-500" />
              </Dropdown>
            </div>
          </div>

          <div className="w-full flex flex-row justify-between gap-0 items-end">
            <div className="flex flex-row gap-2 items-center col-span-4 justify-end">
              <CircularProgress percentage={percentage} size="small" />
              <div className="text-neutral-500 font-medium text-lg">
                {reportItems.filter((item) => item.passed).length}/
                {reportItems.length} checks passed
              </div>
            </div>
          </div>

          {reportItems.map((item) => (
            <ReportItem
              key={item.id}
              title={item.title}
              description={item.description}
              isPassed={item.passed}
              actionItems={item.actionItems}
            />
          ))}
        </div>

        {commentsActive && (
          <>
            <div className="h-200 w-[1px] bg-neutral-200" />
            <div className="flex-grow-0 basis-2/5  p-0">
              <div className="flex flex-row gap-2 items-center">
                <div className="text-lg">Comments</div>
              </div>
              <ThreadView
                key={report.id}
                projectId={projectId!}
                object={report}
                type={"reports"}
                component={reportsComponent}
                visible={true}
                commentsOnly={true}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
