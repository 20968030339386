import React, { useState } from "react";
import { getWidthForFieldType, calculateSummaryForObjects } from "../../utils";

import { useMediaQuery } from 'react-responsive'

export default function TableSummaryCell({
  component,
  field,
  objects,
  id
}) {
  const is13Inch = useMediaQuery({ maxWidth: 1500 })

  const typeNames = {
    sum: "(Sum)",
  };

  var type = field?.fieldData?.type;

  return (
    <div
      className={`table-cell summary-cell ${
        component.table?.summaries[id] != null ? "summary-cell-active" : ""
      }`}
      style={getWidthForFieldType(type, is13Inch)}
    >
      {component.table?.summaries[id] != null && (
        <span>
          <span>
            {calculateSummaryForObjects(
              component.table?.summaries[id]?.type,
              id,
              objects
            )}
          </span>
          <span className="type-title">
            {typeNames[component.table?.summaries[id]?.type]}
          </span>
        </span>
      )}
    </div>
  );
}
