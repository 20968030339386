import { Link } from "react-router-dom";

interface MenuRowProps {
  data: {
    image: string;
    title: string;
    imageHeight?: string;
  };
  active: boolean;
  link?: string;
}

export function MenuRow({ data, active, link }: MenuRowProps) {
  const menuItemStyleHighlighted = {
    backgroundColor: "#dde5ff",
  };

  const menuItemStyle = {};

  return (
    <Link
      to={link ? link : "/"}
      style={{ textDecoration: "none", color: "#616161" }}
    >
      <div
        className="menu-item"
        style={active ? menuItemStyleHighlighted : menuItemStyle}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
            width: "28px",
          }}
        >
          <img
            style={{
              height: data.imageHeight ? data.imageHeight : "15px",
              maxWidth: "15px",
            }}
            src={data.image}
          />
        </div>
        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "10px",
            textAlign: "right",
          }}
        >
          <div
            className="lg:text-base text-sm"
            style={{ display: "flex", alignItems: "center" }}
          >
            {data.title}
          </div>
        </div>
      </div>
    </Link>
  );
}
