import object from "./object";
import project from "./project";
import threads from "./threads";
import user from "./user";
import collections from "./collections";
import history from "./history";
import configs from "./configs";

export default {
  object: object,
  project: project,
  threads: threads,
  user: user,
  collections: collections,
  history: history,
  configs: configs,
};
