import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Menu } from "./Menu";
import FloatingActionButton from "../FloatingActionButton";
import Toggle, { featureTypes, On, Off } from "../FeatureToggle";
import DashBackButton from "../../images/dash-back.svg";
import SearchInput from "../Search/SearchInput";
import { useCurrentUser, useProject } from "../../hooks";

export function DesktopSidebar() {
  const { project } = useProject();
  const currentUser = useCurrentUser();

  return (
    <>
      <div
        style={{
          display: "flex",
          paddingTop: "25px",
          paddingBottom: "25px",
          alignItems: "center",
          marginTop: 10,
          fontSize: "16px",
          lineHeight: "15px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "inline-block",
              alignItems: "center",
              marginLeft: "5px",
              marginRight: "16px",
            }}
          >
            <Link to="/">
              <img
                style={{ height: "20px" }}
                src={DashBackButton}
              // onClick={goToDashboard}
              />{" "}
            </Link>
          </div>

          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "10px",
              textAlign: "right",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Avenir Next",
                fontWeight: 400,
                fontSize: "24px",
              }}
              data-cy="project-title"
            >
              {project.name}
            </div>
          </div>
        </div>

        <Toggle user={currentUser} feature={featureTypes.SEARCH}>
          <On>
            <div style={{ float: "right" }}>
              <SearchInput />
            </div>
          </On>
          <Off>
            <></>
          </Off>
        </Toggle>
      </div>

      <Menu project={project} />
      <FloatingActionButton />
    </>
  );
}
