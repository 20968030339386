import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { MenuMobile } from "../Objects";
import search_icon from "../../images/search_icon.svg";
import DashBackButton from "../../images/dash-back.svg";
import { toggleSearchActive } from "../../store/search";
import { useProject } from "../../hooks";
import { useDispatch } from "react-redux";

export function MobileSidebar() {
  const { project } = useProject();

  const dispatch = useDispatch();

  return (
    <>
      <div
        style={{
          display: "flex",
          paddingTop: "20px",
          paddingBottom: "20px",
          alignItems: "center",
          marginTop: 10,
          fontSize: "16px",
          lineHeight: "15px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "5px",
            marginRight: "18px",
          }}
        >
          <Link to="/">
            <img
              style={{ height: "20px" }}
              src={DashBackButton}
            //      onMouseEnter={e => (e.currentTarget.src = require("../images/dash-back-expanded.svg"))}
            //     onMouseLeave={e => (e.currentTarget.src = require("../images/dash-back.svg"))}
            // onClick={() => goToDashboard()}
            />{" "}
          </Link>
        </div>

        <div
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            marginRight: "10px",
            textAlign: "right",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontFamily: "Avenir Next",
              fontWeight: 400,
              fontSize: "22px",
            }}
            data-cy="project-title"
          >
            {project.name}
          </div>
        </div>
        <div style={{ float: "right" }}>
          <img
            src={search_icon}
            style={{ height: "22px" }}
            onClick={() => dispatch(toggleSearchActive())}
          />
        </div>
      </div>

      <div>
        <MenuMobile style={{ marginTop: "15px" }} />
      </div>
    </>
  );
}
