import { ControlledMenu } from "@szhsin/react-menu";
import { useState } from "react";
export { MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

export function ContextMenu({ children, menuItems, enabled }) {
  const [open, setOpen] = useState(false);
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

  return (
    <div
      onContextMenu={(e) => {
        if (
          !enabled ||
          (typeof document.hasFocus === "function" && !document.hasFocus())
        )
          return;

        e.preventDefault();
        setAnchorPoint({ x: e.clientX, y: e.clientY });
        setOpen(true);
      }}
    >
      <ControlledMenu
        anchorPoint={anchorPoint}
        state={open ? "open" : "closed"}
        direction="right"
        onClose={() => setOpen(false)}
      >
        {menuItems}
      </ControlledMenu>
      {children}
    </div>
  );
}
