import React, { Component } from "react";
import { connect } from "react-redux";
import { finishDeepLinkAction } from "../../store/deepLinkActions";
import { scrollToRef } from "../utils";

import "./Objects.scss";

const mapStateToProps = (state) => ({
  doDeepLinkAction: state.doDeepLinkAction,
});

const mapDispatchToProps = {
  finishDeepLinkAction,
};

class Card extends Component {
  constructor() {
    super();
    this.state = {
      highlightActive: false,
    };
    this.cardRef = React.createRef();
  }

  componentDidMount() {
    this.deepLinkAction();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.doDeepLinkAction && !prevProps.doDeepLinkAction) {
      this.deepLinkAction();
    }
  };

  deepLinkAction = () => {
    if (this.props.highlight) {
      this.setState(
        {
          highlightActive: true,
        },
        () =>
          setTimeout(
            () =>
              this.setState({
                highlightActive: false,
              }),
            2000
          )
      );

      scrollToRef(this.cardRef);

      this.props.finishDeepLinkAction();
    }
  };

  render() {
    const highlightStyle = this.state.highlightActive
      ? {
          padding: "30px",
          boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #79b0fa99",
        }
      : {
          padding: "30px",
        };

    return (
      <div ref={this.cardRef}>
        <div
          className={this.props.dashCard ? "card-style-dash" : "card-style"}
          onClick={this.props.onClick}
        >
          <div
            style={{
              textAlign: "left",
              fontFamily: "Avenir Next",
              fontSize: "14px",
              fontWeight: 400,
              color: "#7d7d7d",
              textDecoration: "none",
              marginTop: "2px",
            }}
          >
            {this.props.children}
          </div>
        </div>
        <div
          style={{
            height:
              this.props.style && this.props.style.marginBottom
                ? this.props.style.marginBottom
                : "30px",
          }}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Card);
