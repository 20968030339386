import { ComponentConfig } from "@/types";

export const reportsComponent: ComponentConfig = {
  info: {
    collectionKey: "reports",
    name: "Report",
    vocabulary: {
      singular_lower: "report",
      singular_upper: "Report",
      plural_lower: "reports",
      plural_upper: "Reports",
    },
    type: "reports",
  },
  fields: {},
  search: {
    searchableAttributes: [],
  },
  notifications: {
    slack: {
      color: "",
      primaryField: "",
      fields: {
        create: [],
        edit: [],
      },
      buttons: [],
      disabled: true,
    },
  },
  // TODO: Add or modify this if we want to use it
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["admin"],
    edit: ["admin"],
    delete: ["admin"],
  },
};
