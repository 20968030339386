import { Badge } from "antd";

export function TitleBadge({ count }) {
  return (
    <Badge
      count={count}
      style={{
        backgroundColor: "#005fff",
        color: "#ffffff",
        marginLeft: "7px",
      }}
    />
  );
}
