import { AppState } from "@/types";
import { PayloadAction, createReducer } from "@reduxjs/toolkit";

export const idMatcher = (actionId) => (action: PayloadAction<any>) =>
  action.type.endsWith(actionId);

export type CollectionState = AppState["collections"];

const unsupportedBadgeCollectionKeys = ["reports", "threads"];

type CollectionAction<T> = PayloadAction<
  T & {
    projectId: string;
    collectionKey: string;
  }
>;

export type CollectionObjectsAction = CollectionAction<{
  objects: any[];
}>;

export type CollectionObjectAction = CollectionAction<{
  object: any;
}>;

// Case reducers
function loadToCollection(
  collectionsState: CollectionState,
  action: CollectionObjectsAction
) {
  const { projectId, collectionKey, objects } = action.payload;

  if (collectionsState[projectId] == null) {
    collectionsState[projectId] = {};
  }

  if (collectionsState[projectId][collectionKey] == null) {
    collectionsState[projectId][collectionKey] = {};
  }

  collectionsState[projectId][collectionKey] = {
    ...objects,
  };
}

function addToCollection(
  collectionsState: CollectionState,
  action: CollectionObjectAction
) {
  const { projectId, collectionKey, object } = action.payload;

  if (!collectionsState[projectId]) {
    collectionsState[projectId] = {};
  }

  if (!collectionsState[projectId][collectionKey]) {
    collectionsState[projectId][collectionKey] = {};
  }

  collectionsState[projectId][collectionKey][object.id] = object;
}

function updateInCollection(
  collectionsState: CollectionState,
  action: CollectionObjectAction
) {
  const { projectId, collectionKey, object } = action.payload;

  if (!collectionsState[projectId]) {
    collectionsState[collectionKey] = {};
  }

  if (!collectionsState[projectId][collectionKey]) {
    collectionsState[projectId][collectionKey] = {};
  }

  collectionsState[projectId][collectionKey][object.id] = object;
}

function removeFromCollection(
  collectionsState: CollectionState,
  action: CollectionObjectAction
) {
  const { projectId, collectionKey, object } = action.payload;

  delete collectionsState[projectId][collectionKey][object.id];
}

function batchRemoveFromCollection(
  collectionsState: CollectionState,
  action: CollectionAction<{
    items: {
      collectionKey: string;
      object: any;
    }[];
  }>
) {
  const { items, projectId } = action.payload;
  items.forEach((item) => {
    delete collectionsState[projectId][item.collectionKey][item.object.id];
  });
}

function incrementBadgeForObject(
  collectionsState: CollectionState,
  action: CollectionAction<{
    objectId: string;
    field: string;
    amount: number;
  }>
) {
  const { projectId, collectionKey, objectId, field, amount } = action.payload;

  if (unsupportedBadgeCollectionKeys.includes(collectionKey)) {
    return;
  }

  var tempObject = Object.assign(
    {},
    collectionsState[projectId][collectionKey][objectId]
  );

  if (!tempObject.badges) {
    tempObject.badges = {};
  }

  tempObject.badges[field] = (tempObject.badges[field] || 0) + amount;

  collectionsState[projectId][collectionKey][objectId] = tempObject;
}

function addThreadTimestampForObject(
  collectionsState: CollectionState,
  action: CollectionAction<{
    objectId: string;
    thread: {
      id: string;
      timestamp: number;
    };
  }>
) {
  const { projectId, collectionKey, objectId, thread } = action.payload;

  if (unsupportedBadgeCollectionKeys.includes(collectionKey)) {
    return;
  }

  var tempObject = Object.assign(
    {},
    collectionsState[projectId][collectionKey][objectId]
  );

  if (tempObject.thread_timestamps == null) {
    tempObject["thread_timestamps"] = [];
  }

  tempObject["thread_timestamps"].push({
    timestamp: thread.timestamp,
    thread: thread.id,
  });

  collectionsState[projectId][collectionKey][objectId] = tempObject;
}

function removeThreadTimestampForObject(
  collectionsState: CollectionState,
  action: CollectionAction<{
    objectId: string;
    thread: {
      id: string;
      timestamp: number;
    };
  }>
) {
  const { projectId, collectionKey, objectId, thread } = action.payload;

  if (unsupportedBadgeCollectionKeys.includes(collectionKey)) {
    return;
  }

  var tempObject = Object.assign(
    {},
    collectionsState[projectId][collectionKey][objectId]
  );

  if (tempObject.thread_timestamps == null) {
    tempObject["thread_timestamps"] = [];
  }

  var index = tempObject.thread_timestamps
    .map((x) => {
      return x.thread;
    })
    .indexOf(thread.id);

  tempObject.thread_timestamps.splice(index, 1);

  collectionsState[objectId][collectionKey][objectId] = tempObject;
}

function updateThreadReadTimestampForObject(
  collectionsState: CollectionState,
  action: CollectionAction<{
    objectId: string;
    currentUserId: string;
    timestamp: number;
  }>
) {
  const { projectId, collectionKey, objectId, currentUserId, timestamp } =
    action.payload;

  if (unsupportedBadgeCollectionKeys.includes(collectionKey)) {
    return;
  }

  var tempObject = Object.assign(
    {},
    collectionsState[projectId][collectionKey][objectId]
  );

  if (tempObject.thread_read_timestamps == null) {
    tempObject.thread_read_timestamps = {};
  }

  tempObject.thread_read_timestamps[currentUserId] = timestamp;

  collectionsState[projectId][collectionKey][objectId] = tempObject;
}

export const collectionsReducer = createReducer({}, (builder) => {
  builder
    .addMatcher(idMatcher("LOAD_TO_COLLECTION"), loadToCollection)
    .addMatcher(idMatcher("ADD_TO_COLLECTION"), addToCollection)
    .addMatcher(idMatcher("UPDATE_IN_COLLECTION"), updateInCollection)
    .addMatcher(idMatcher("REMOVE_FROM_COLLECTION"), removeFromCollection)
    .addMatcher(
      idMatcher("BATCH_REMOVE_FROM_COLLECTION"),
      batchRemoveFromCollection
    )
    .addMatcher(
      idMatcher("INCREMENT_BADGE_FOR_OBJECT"),
      incrementBadgeForObject
    )
    .addMatcher(
      idMatcher("ADD_THREAD_TIMESTAMP_FOR_OBJECT"),
      addThreadTimestampForObject
    )
    .addMatcher(
      idMatcher("REMOVE_THREAD_TIMESTAMP_FOR_OBJECT"),
      removeThreadTimestampForObject
    )
    .addMatcher(
      idMatcher("UPDATE_THREADREAD_TIMESTAMP_FOR_OBJECT"),
      updateThreadReadTimestampForObject
    );
});
