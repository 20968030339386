export default {
  admin: {
    info: {
      name: "Admin",
      key: "admin",
    },
    permissions: {
      project: {
        settings: {
          edit: true,
          read: true,
          delete: true,
        },
        collaborators: {
          read: true,
          edit: true,
          delete: true,
        },
      },
      sections: {
        status_items: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            estimated_hours: {
              edit: true,
              read: true,
            },
            priority: {
              edit: true,
              read: true,
            },
            overages: {
              edit: true,
              read: true,
            },
            name: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            qa_status: {
              edit: true,
              read: true,
            },
            est_delivery_date: {
              edit: true,
              read: true,
            },
            milestone_id: {
              edit: true,
              read: true,
            },
            related_bugs: {
              edit: true,
              read: true,
            },
          },
        },
        additional_requests: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            scope_request: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            reason: {
              edit: true,
              read: true,
            },
            projected_effort: {
              edit: true,
              read: true,
            },
            projected_cost: {
              edit: true,
              read: true,
            },
            client_decision: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
          },
        },
        bugs: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            priority: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            steps: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
            related_status_items: {
              edit: true,
              read: true,
            },
          },
        },
        environments: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            type: {
              edit: true,
              read: true,
            },
            name: {
              edit: true,
              read: true,
            },
            link: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            email: {
              edit: true,
              read: true,
            },
            password: {
              edit: true,
              read: true,
            },
            server_notes: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        feedbacks: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            priority: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            location: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        files: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            created_by_user: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        standups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            created: {
              edit: true,
              read: true,
            },
            yesterday: {
              edit: true,
              read: true,
            },
            today: {
              edit: true,
              read: true,
            },
            problems: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        meeting_minutes: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            date: {
              edit: true,
              read: true,
            },
            notes: {
              edit: true,
              read: true,
            },
            attendees: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        overages: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            description: {
              edit: true,
              read: true,
            },
            status_items: {
              edit: true,
              read: true,
            },
            discussion_tickets: {
              edit: true,
              read: true,
            },
            decision: {
              edit: true,
              read: true,
            },
            hours: {
              edit: true,
              read: true,
            },
            cost: {
              edit: true,
              read: true,
            },
          },
        },
        timesheet_entries: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            task: {
              edit: true,
              read: true,
            },
            estimated_hours: {
              edit: true,
              read: true,
            },
            actual_hours: {
              edit: true,
              read: true,
            },
            date: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            developer: {
              edit: true,
              read: true,
            },
            timesheet_group: {
              edit: true,
              read: true,
            },
          },
        },
        documentations: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            description: {
              edit: true,
              read: true,
            },
            template_link: {
              edit: true,
              read: true,
            },
            est_delivery_date: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        credentials: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            email: {
              edit: true,
              read: true,
            },
            password: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        clarifications: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            complete: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            questions: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        milestones: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
        timesheet_groups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
        request_groups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
      },
    },
  },
  client: {
    info: {
      name: "Client",
      key: "client",
    },
    permissions: {
      project: {
        settings: {
          edit: false,
          read: false,
          delete: false,
        },
        collaborators: {
          read: false,
          edit: false,
          delete: false,
        },
      },
      sections: {
        status_items: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            estimated_hours: {
              edit: true,
              read: true,
            },
            priority: {
              edit: true,
              read: true,
            },
            overages: {
              edit: true,
              read: true,
            },
            name: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            qa_status: {
              edit: true,
              read: true,
            },
            est_delivery_date: {
              edit: true,
              read: true,
            },
            related_bugs: {
              edit: true,
              read: true,
            },
            milestone_id: {
              edit: true,
              read: true,
            },
            related_bugs: {
              edit: true,
              read: true,
            },
          },
        },
        additional_requests: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            scope_request: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            reason: {
              edit: true,
              read: true,
            },
            projected_effort: {
              edit: true,
              read: true,
            },
            projected_cost: {
              edit: true,
              read: true,
            },
            client_decision: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
          },
        },
        bugs: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            priority: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            steps: {
              edit: true,
              read: true,
            },
            related_status_items: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        environments: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            type: {
              edit: true,
              read: true,
            },
            name: {
              edit: true,
              read: true,
            },
            link: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            email: {
              edit: true,
              read: true,
            },
            password: {
              edit: true,
              read: true,
            },
            server_notes: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        feedbacks: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            priority: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            location: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        files: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            created_by_user: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        standups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            created: {
              edit: true,
              read: true,
            },
            yesterday: {
              edit: true,
              read: true,
            },
            today: {
              edit: true,
              read: true,
            },
            problems: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        meeting_minutes: {
          create: true,
          edit: true,
          read: true,
          delete: false,
          comment: true,
          fields: {
            date: {
              edit: true,
              read: true,
            },
            notes: {
              edit: true,
              read: true,
            },
            attendees: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        overages: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            description: {
              edit: true,
              read: true,
            },
            status_items: {
              edit: true,
              read: true,
            },
            discussion_tickets: {
              edit: true,
              read: true,
            },
            decision: {
              edit: true,
              read: true,
            },
            hours: {
              edit: true,
              read: true,
            },
            cost: {
              edit: true,
              read: true,
            },
          },
        },
        timesheet_entries: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            task: {
              edit: true,
              read: true,
            },
            estimated_hours: {
              edit: true,
              read: true,
            },
            actual_hours: {
              edit: true,
              read: true,
            },
            date: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            developer: {
              edit: true,
              read: true,
            },
            timesheet_group: {
              edit: true,
              read: true,
            },
          },
        },
        documentations: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            description: {
              edit: true,
              read: true,
            },
            template_link: {
              edit: true,
              read: true,
            },
            est_delivery_date: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        credentials: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            email: {
              edit: true,
              read: true,
            },
            password: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        clarifications: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            complete: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            questions: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        milestones: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
        timesheet_groups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
        request_groups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
      },
    },
  },
  vendor: {
    info: {
      name: "Vendor",
      key: "vendor",
    },
    permissions: {
      project: {
        settings: {
          edit: false,
          read: false,
          delete: false,
        },
        collaborators: {
          read: false,
          edit: false,
          delete: false,
        },
      },
      sections: {
        status_items: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            estimated_hours: {
              edit: true,
              read: true,
            },
            priority: {
              edit: true,
              read: true,
            },
            overages: {
              edit: true,
              read: true,
            },
            name: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            qa_status: {
              edit: true,
              read: true,
            },
            est_delivery_date: {
              edit: true,
              read: true,
            },
            related_bugs: {
              edit: true,
              read: true,
            },
            milestone_id: {
              edit: true,
              read: true,
            },
          },
        },
        additional_requests: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            scope_request: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            reason: {
              edit: true,
              read: true,
            },
            projected_effort: {
              edit: true,
              read: true,
            },
            projected_cost: {
              edit: true,
              read: true,
            },
            client_decision: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
          },
        },
        bugs: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            priority: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            steps: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
            related_status_items: {
              edit: true,
              read: true,
            },
          },
        },
        environments: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            type: {
              edit: true,
              read: true,
            },
            name: {
              edit: true,
              read: true,
            },
            link: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            email: {
              edit: true,
              read: true,
            },
            password: {
              edit: true,
              read: true,
            },
            server_notes: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        feedbacks: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            priority: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            location: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        files: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            created_by_user: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        standups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            created: {
              edit: true,
              read: true,
            },
            yesterday: {
              edit: true,
              read: true,
            },
            today: {
              edit: true,
              read: true,
            },
            problems: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        meeting_minutes: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            date: {
              edit: true,
              read: true,
            },
            notes: {
              edit: true,
              read: true,
            },
            attendees: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        overages: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            description: {
              edit: true,
              read: true,
            },
            status_items: {
              edit: true,
              read: true,
            },
            discussion_tickets: {
              edit: true,
              read: true,
            },
            decision: {
              edit: true,
              read: true,
            },
            hours: {
              edit: true,
              read: true,
            },
            cost: {
              edit: true,
              read: true,
            },
          },
        },
        timesheet_entries: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            task: {
              edit: true,
              read: true,
            },
            estimated_hours: {
              edit: true,
              read: true,
            },
            actual_hours: {
              edit: true,
              read: true,
            },
            date: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            developer: {
              edit: true,
              read: true,
            },
            timesheet_group: {
              edit: true,
              read: true,
            },
          },
        },
        documentations: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            description: {
              edit: true,
              read: true,
            },
            template_link: {
              edit: true,
              read: true,
            },
            est_delivery_date: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        credentials: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            email: {
              edit: true,
              read: true,
            },
            password: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        clarifications: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            complete: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            questions: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        milestones: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
        timesheet_groups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
        request_groups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
      },
    },
  },
  viewer: {
    project: {
      settings: {
        edit: false,
        read: false,
        delete: false,
      },
      collaborators: {
        read: false,
        edit: false,
        delete: false,
      },
    },
    info: {
      name: "Viewer",
      key: "viewer",
    },
    permissions: {
      sections: {
        status_items: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            priority: {
              edit: true,
              read: true,
            },
            estimated_hours: {
              edit: true,
              read: true,
            },
            overages: {
              edit: true,
              read: true,
            },
            name: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            qa_status: {
              edit: true,
              read: true,
            },
            est_delivery_date: {
              edit: true,
              read: true,
            },
            related_bugs: {
              edit: true,
              read: true,
            },
            milestone_id: {
              edit: true,
              read: true,
            },
          },
        },
        additional_requests: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            scope_request: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            reason: {
              edit: true,
              read: true,
            },
            projected_effort: {
              edit: true,
              read: true,
            },
            projected_cost: {
              edit: true,
              read: true,
            },
            client_decision: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
          },
        },
        bugs: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            priority: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            steps: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
            related_status_items: {
              edit: true,
              read: true,
            },
          },
        },
        environments: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            type: {
              edit: true,
              read: true,
            },
            name: {
              edit: true,
              read: true,
            },
            link: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            email: {
              edit: true,
              read: true,
            },
            password: {
              edit: true,
              read: true,
            },
            server_notes: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        feedbacks: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            priority: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            location: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        files: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            created_by_user: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        standups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            created: {
              edit: true,
              read: true,
            },
            yesterday: {
              edit: true,
              read: true,
            },
            today: {
              edit: true,
              read: true,
            },
            problems: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        meeting_minutes: {
          create: false,
          edit: false,
          read: true,
          delete: false,
          comment: false,
          fields: {
            date: {
              edit: false,
              read: true,
            },
            notes: {
              edit: false,
              read: true,
            },
            attendees: {
              edit: false,
              read: true,
            },
            attachments: {
              edit: false,
              read: true,
            },
          },
        },
        overages: {
          create: false,
          edit: false,
          read: true,
          delete: false,
          comment: false,
          fields: {
            description: {
              edit: false,
              read: true,
            },
            status_items: {
              edit: false,
              read: true,
            },
            discussion_tickets: {
              edit: false,
              read: true,
            },
            decision: {
              edit: false,
              read: true,
            },
            hours: {
              edit: false,
              read: true,
            },
            cost: {
              edit: false,
              read: true,
            },
          },
        },
        timesheet_entries: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            task: {
              edit: true,
              read: true,
            },
            estimated_hours: {
              edit: true,
              read: true,
            },
            actual_hours: {
              edit: true,
              read: true,
            },
            date: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            developer: {
              edit: true,
              read: true,
            },
            timesheet_group: {
              edit: true,
              read: true,
            },
          },
        },
        documentations: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            description: {
              edit: true,
              read: true,
            },
            template_link: {
              edit: true,
              read: true,
            },
            est_delivery_date: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        credentials: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            email: {
              edit: true,
              read: true,
            },
            password: {
              edit: true,
              read: true,
            },
            description: {
              edit: true,
              read: true,
            },
            status: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        clarifications: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            complete: {
              edit: true,
              read: true,
            },
            created: {
              edit: true,
              read: true,
            },
            questions: {
              edit: true,
              read: true,
            },
            attachments: {
              edit: true,
              read: true,
            },
          },
        },
        milestones: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
        timesheet_groups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
        request_groups: {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: {
            name: {
              edit: true,
              read: true,
            },
          },
        },
      },
    },
  },
};
