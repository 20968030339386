import { ComponentConfig } from "@/types";

const config: ComponentConfig = {
  info: {
    name: "Feedback",
    addon: false,
    collectionKey: "feedbacks",
    vocabulary: {
      singular_upper: "Feedback",
      singular_lower: "feedback",
      plural_upper: "Feedback",
      plural_lower: "feedback",
    },
    type: "feedback",
    icon: "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Ffeedback_icon.svg?alt=media&token=3aec2e00-451b-4084-94ad-d2fa19c737ba",
  },
  fields: {
    impacted_status_items: {
      title: "Impacted Status Items",
      valueKey: "impacted_status_items",
      fieldData: {
        type: "reference",
        itemTypes: ["status_items"],
        linkedFields: {
          status_items: "impacting_items",
        },
      },
    },
    priority: {
      title: "Priority Level",
      valueKey: "priority",
      hideTitle: true,
      fieldData: {
        type: "tag",
        defaultValue: 3,
        options: {
          3: {
            id: 3,
            name: "Low (P3)",
            color: "#000000",
            backgroundColor: "#ffe800",
          },
          2: {
            id: 2,
            name: "Minor (P2)",
            color: "#000000",
            backgroundColor: "#FFC403",
          },
          1: {
            id: 1,
            name: "Major (P1)",
            color: "#ffffff",
            backgroundColor: "#F18100",
          },
          0: {
            id: 0,
            name: "Critical (P0)",
            color: "#ffffff",
            backgroundColor: "#ff0000",
          },
        },
        options_order: [3, 2, 1, 0],
      },
    },
    overages: {
      title: "Overages",
      valueKey: "overages",
      fieldData: {
        type: "reference",
        itemTypes: ["overages"],
        linkedFields: {
          overages: "discussion_tickets",
        },
      },
    },
    status: {
      title: "Status",
      valueKey: "status",
      fieldData: {
        type: "select",
        defaultValue: "open",
        options: {
          open: {
            id: "open",
            name: "Open",
            color: "#4B4B4B",
            backgroundColor: "#FFEAB6",
          },
          in_progress: {
            id: "in_progress",
            name: "In Progress",
            color: "#4B4B4B",
            backgroundColor: "#dcceff",
          },
          in_review: {
            id: "in_review",
            name: "Ready for Review",
            color: "#4B4B4B",
            backgroundColor: "#CEDFFF",
          },
          closed: {
            id: "closed",
            name: "Closed",
            color: "#4B4B4B",
            backgroundColor: "#D0F7C3",
          },
        },
        options_order: ["open", "in_progress", "in_review", "closed"],
      },
      editingDisabled: true,
      hidden: true,
    },
    created: {
      title: "Date Opened (Age)",
      valueKey: "created",
      fieldData: {
        type: "date_with_age",
      },
      editingDisabled: true,
    },
    location: {
      title: "Location",
      valueKey: "location",
      fieldData: {
        type: "text",
        placeholder: "Profile Screen",
      },
      required: true,
    },
    description: {
      title: "Description of the feedback",
      valueKey: "description",
      fieldData: {
        type: "textArea",
        placeholder:
          "Example: The color is too bright for the topbar, can we tweak it to be lighter",
      },
      required: true,
    },
    attachments: {
      title: "Attachments",
      valueKey: "attachments",
      fieldData: { type: "attachments" },
      hideTitle: true,
    },
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["admin"],
  },
  table: {
    index_row_key: "description",
    pivotBy: "priority",
    columns: [
      "description",
      "priority",
      "status",
      "location",
      "created",
      "impacted_status_items",
    ],
    pivotByFixedValues: [0, 1, 2, 3],
    exclude_from_new_button: ["feedbacks"],
  },
  search: {
    searchableAttributes: ["description", "location"],
  },
  notifications: {
    slack: {
      color: "#912FFF",
      primaryField: "description",
      fields: {
        create: [
          "description",
          "location",
          "priority",
          "status",
          "impacted_status_items",
        ],
        edit: [
          "description",
          "location",
          "priority",
          "status",
          "impacted_status_items",
        ],
      },
      buttons: [
        {
          text: "Mark as complete",
        },
      ],
    },
  },
  badge: {
    rules: { "==": [{ var: "status" }, "open"] },
  },
  cardlist: {
    fieldOrder: [
      "priority",
      "created",
      "location",
      "description",
      "attachments",
      "impacted_status_items",
    ],
    actionButtons: [
      {
        name: "Open",
        field: "status",
        newValue: "open",
        showRules: {
          or: [{ "==": [{ var: "status" }, "in_progress"] }],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
      {
        name: "Re-open",
        field: "status",
        newValue: "open",
        showRules: {
          or: [
            { "==": [{ var: "status" }, "closed"] },
            { "==": [{ var: "status" }, "in_review"] },
          ],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
      {
        name: "In Progress",
        showRules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "open"] },
          ],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
        actions: [
          {
            type: "modal",
            message:
              "By marking In Progress you agree that this is in scope and will not affect timeline or cost.",
            fields: [
              { field: "status", newValue: "in_progress", hidden: true },
            ],
            buttonTitle: "I agree",
          },
        ],
      },
      {
        name: "Ready for Review",
        field: "status",
        newValue: "in_review",
        showRules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "open"] },
          ],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
      {
        name: "Ready for Review",
        field: "status",
        newValue: "in_review",
        showRules: {
          or: [{ "==": [{ var: "status" }, "in_progress"] }],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
      {
        name: "Close",
        field: "status",
        newValue: "closed",
        showRules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "in_review"] },
          ],
        },
        permissions: {
          edit: ["client", "admin"],
        },
      },
    ],
    sorting: {
      age_descending: {
        name: "Sort by age (descending)",
        key: "age_descending",
        value: "created",
        ascending: false,
      },
      age_ascending: {
        name: "Sort by age (ascending)",
        key: "age_ascending",
        value: "created",
        ascending: true,
      },
      priority_descending: {
        name: "Sort by priority (descending)",
        key: "priority_descending",
        value: "priority",
        ascending: false,
      },
      priority_ascending: {
        name: "Sort by priority (ascending)",
        key: "priority_ascending",
        value: "priority",
        ascending: true,
      },
    },
    defaultTab: "open",
    tabs: [
      {
        name: "Open",
        key: "open",
        rules: { "==": [{ var: "status" }, "open"] },
      },
      {
        name: "In Progress",
        key: "in_progress",
        rules: { "==": [{ var: "status" }, "in_progress"] },
      },
      {
        name: "Ready for Review",
        key: "in_review",
        rules: { "==": [{ var: "status" }, "in_review"] },
      },
      {
        name: "Closed",
        key: "closed",
        rules: { "==": [{ var: "status" }, "closed"] },
      },
    ],
  },
};

export default config;
