import { PayloadAction, asyncThunkCreator, buildCreateSlice } from "@reduxjs/toolkit";
import { AppState } from "@/types";
import { GrowthOffering } from "@/types/growth";
import { db } from "../firebase";

const createAppSlice = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

const growthSlice = createAppSlice({
  name: "growth",
  initialState: {
    offers: [],
  },
  reducers: (create) => ({
    fetchOffers: create.asyncThunk(
      async (_: void) => {
        const query = await db.collection("growth").get();
        return query.docs.map((doc) => doc.data() as GrowthOffering);
      },
      {
        fulfilled: (
          state: AppState["growth"],
          action: PayloadAction<GrowthOffering[]>
        ) => {
          state.offers = action.payload;
        },
      }
    ),
  }),
});

export const growthReducers = growthSlice.reducer;
export const { fetchOffers } = growthSlice.actions;
