import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import { store } from "./store";
import { getFeatureFlags } from "./store/features";
import { basename } from "./constants";
import { useAuth } from "./hooks";

// Components
import Dashboard from "./components/Dashboard";
import NotFound from "./components/404";
import EulaDialog from "./components/Eula/EulaDialog";
import Notifications from "./components/Notifications";
import Growth from "./components/Growth";
import QuickSwitcher from "./components/Objects/QuickSwitcher";
import { Callback } from "./components/Routes/Callback";
import Loading from "./components/Loading";
import Project from "./components/App";

import "./index.css";
import { SignUp } from "./components/SignUp";

function App() {
  const { user, loading } = useAuth();

  useEffect(() => {
    document.title = "Aloa Manage";
    store.dispatch(getFeatureFlags());
  }, []);

  return (
    <BrowserRouter basename={basename}>
      {loading && <Loading />}

      {/* Authenticated routes */}
      {!loading && user && (
        <div>
          <QuickSwitcher />
          <Notifications />
          <EulaDialog />
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/growth" element={<Growth />} />
            <Route path="/p/:projectId/*" element={<Project />} />
            <Route path="/register" element={<Dashboard />} />
            <Route path="/callback" element={<Callback loggedIn={true} />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      )}

      {/* Inauthenticated routes */}
      {!loading && !user && (
        <Routes>
          <Route
            path="/register/:inviteCode"
            element={<SignUp store={store} />}
          />
          <Route path="/callback" element={<Callback loggedIn={false} />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

registerServiceWorker();
