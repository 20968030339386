import React from "react";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { AccessDenied } from "./AccessDenied";
import SectionEditor from "../Editor/Section/Editor";
import { useUserRole } from "../../hooks/useUserRole";
import { useSelector } from "react-redux";
import { AppState } from "@/types";
import { useSections } from "../../hooks/useSections";

export function ProjectCreateSectionRoute() {
  const { projectId } = useParams();
  const currentUserRole = useUserRole();
  const roles = useSelector(
    (state: AppState) => state.roles[projectId as string]
  );
  const { sections: components, loading } = useSections(projectId);

  return (
    <div>
      {roles[currentUserRole]?.permissions?.project?.settings?.edit && (
        <div>
          <SectionEditor
            creation={true}
            projectId={projectId as string}
            component={{}}
            existingKeys={Object.keys(components)}
            components={components}
            id={"test"}
          />
        </div>
      )}

      {!roles[currentUserRole]?.permissions?.project?.settings?.edit && (
        <AccessDenied />
      )}
    </div>
  );
}
