import React from "react";
import { useState, useEffect } from "react";

import { Tooltip } from "antd";

export default function RequiredChip({
  field,
  view = "cardlist",
  overrideRequired,
}) {
  if (field.required || overrideRequired) {
    return (
      <Tooltip
        title={
          "This field is required and an item cannot be created without it"
        }
      >
        <span className={`required-chip ${view === "table" ? "table" : ""}`}>
          Required
        </span>
      </Tooltip>
    );
  } else {
    return null;
  }
}
