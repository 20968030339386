import React from "react";
import { Skeleton } from "antd";

export default function TableLoading({ hidden }) {
  // This is hardcoded right now, we might want to make it random at least to seem dynamic
  return (
    <div style={{ clear: "right", paddingTop: "10px" }} hidden={hidden}>
      <div className="table-skeleton-wrapper">
        <Skeleton active />
        <Skeleton active />
      </div>
      <div className="table-skeleton-wrapper">
        <Skeleton active />
      </div>
      <div className="table-skeleton-wrapper">
        <Skeleton active />
        <Skeleton active />
      </div>
    </div>
  );
}
