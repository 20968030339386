import React from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import "../index.css";

export function NotFound() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img
        style={{ width: "80%" }}
        src="https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg"
      />
      <div
        style={{
          fontFamily: "Avenir Next",
          fontSize: "30px",
          fontWeight: 500,
          marginTop: "40px",
        }}
      >
        Error 404 (Page not found)
      </div>
      <Button
        style={{ marginTop: "20px" }}
        onClick={() => navigate("/")}
        type="primary"
      >
        Go Back Home
      </Button>
    </div>
  );
}

export default NotFound;
