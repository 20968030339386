import { url } from "./config";

/**
 * Make a request to aloa notifications/manage api
 * @param {String} type Type of item being updated
 * @param {String} field Field of the item being changed - blank for new
 * @param {String} notificationType Type of notification
 * @param {Object} project Project request involves
 * @param {Object} data Relevant request data
 * @param {Object} user User making request
 */
const sendNotification = (
  itemType,
  field,
  notificationType,
  project,
  data,
  user,
  component,
  collaborators = {}
) =>
  new Promise((resolve, reject) => {
    const postBody = {
      itemType,
      field,
      notificationType,
      project,
      data,
      user,
      component,
      collaborators,
    };

    fetch(`${url}/notifications`, {
      method: "POST",
      mode: "no-cors",
      body: JSON.stringify(postBody),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.text();
      })
      .then(async (data) => {
        resolve(data ? JSON.parse(data) : {});
      })
      .catch((error) => reject(error));
  });

export default sendNotification;
