import { changeNetworkStatus } from "../store/networkStatus";
import firebase, { db } from "../firebase";
// TODO: This should not be here, this is a quick solution to reuse the actions defined here
import { update as updateUserState } from "../store/user";
import { User } from "@/types";

export const loadPerson = (person) => {
  return {
    type: "LOAD_PERSON",
    person: person,
  };
};

export const loadPeople = (people) => {
  return {
    type: "LOAD_PEOPLE",
    people: people,
  };
};

export const updateUserObject = (updatedUserData) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;
    return dispatch(updateUser({ ...currentUser, ...updatedUserData }));
  };
};

export const updateUserSettings = (projId) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;
    const remove = currentUser.hideOn && currentUser.hideOn.includes(projId);

    const updatedHideOn = remove
      ? currentUser.hideOn.filter((id) => id != projId)
      : currentUser.hideOn;

    if (!remove) {
      updatedHideOn.push(projId);
    }

    dispatch(updateUser({ ...currentUser, hideOn: updatedHideOn }));
  };
};

export const updateSortingPreferencesForProject = (
  projectId,
  collection,
  type
) => {
  return function (dispatch, getState) {
    const currentUser = getState().currentUser;

    let sortingPrefernces = { ...(currentUser.sortingPreferences || {}) };
    sortingPrefernces = {
      ...sortingPrefernces,
      [projectId]: {
        ...sortingPrefernces[projectId],
        [collection]: type,
      },
    };

    dispatch(
      updateUser({
        ...currentUser,
        sortingPreferences: sortingPrefernces,
      })
    );
  };
};

export const updateUserSettingsFeedbackSorting = (type) => {
  return function (dispatch, getState) {
    const currentUser = getState().currentUser;
    dispatch(updateUser({ ...currentUser, feedbacksSortingMethod: type }));
  };
};

export const updateUserSettingsCompactMode = (isCompact) => {
  return function (dispatch, getState) {
    const currentUser = getState().currentUser;
    dispatch(updateUser({ ...currentUser, isCompact: isCompact }));
  };
};

export const updateUserSettingsCollapse = (milestoneId) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;
    const remove =
      currentUser.collapseOn && currentUser.collapseOn.includes(milestoneId);

    if (remove) {
      dispatch(
        updateUser({
          ...currentUser,
          collapseOn: currentUser.collapseOn.filter((id) => id != milestoneId),
        })
      );
    } else {
      dispatch(
        updateUser({
          ...currentUser,
          collapseOn: [...currentUser.collapseOn, milestoneId],
        })
      );
    }
  };
};

export const updateUserSettingsThreadFilter = (type) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;
    dispatch(updateUser({ ...currentUser, threadFilter: type }));
  };
};

export const updateUserAppVersion = (app_version) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;
    dispatch(updateUser({ ...currentUser, app_version: app_version }));
  };
};

export const updateDataForUser = (data) => {
  return function (dispatch, getState) {
    var currentUser = getState().currentUser;
    dispatch(updateUser({ ...currentUser, ...data }));
  };
};

export const refreshCurrentUser = () => (dispatch, getState) => {
  const { currentUser } = getState();
  db.collection("users")
    .doc(currentUser.id)
    .get()
    .then((doc) => dispatch(updateUser(doc.data())))
    .catch((error) => console.error(error));
};

export const fetchCurrentUser = () => (dispatch, getState) => {
  new Promise(() => {
    // Remember to return something to unsubscribe

    var user = firebase.auth().currentUser;

    if (user != null) {
      var profilesRef = db.collection("users").doc(user.uid);

      var snapshot = profilesRef.onSnapshot(function (doc) {
        var data = doc.data() as User;

        var newUser = data;

        newUser["id"] = user!.uid;

        dispatch(updateUser(newUser));
      });

      return function unsubscribe() {
        snapshot();
      };
    }
  });
};

export const updateUser = (updatedUserData) => {
  return async (dispatch, getState) => {
    var currentUser = getState().currentUser;
    dispatch(updateUserState({ ...currentUser, ...updatedUserData }));

    try {
      await db.collection("users").doc(currentUser.id).update(updatedUserData);
      setTimeout(() => {
        dispatch(changeNetworkStatus(true, "All Changes Saved"));
      });
    } catch (error) {
      setTimeout(() => {
        dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
      }, 1000);
    }
  };
};

export default {
  loadPeople: loadPeople,
  loadPerson: loadPerson,
  updateUserSettings: updateUserSettings,
  updateSortingPreferencesForProject: updateSortingPreferencesForProject,
  updateUserSettingsFeedbackSorting: updateUserSettingsFeedbackSorting,
  updateUserSettingsThreadFilter: updateUserSettingsThreadFilter,
  updateUserSettingsCollapse: updateUserSettingsCollapse,
  updateUserAppVersion: updateUserAppVersion,
  updateUserObject: updateUserObject,
  updateUserSettingsCompactMode: updateUserSettingsCompactMode,
  fetchCurrentUser: fetchCurrentUser,
};
