import React, { useEffect, useState } from "react";
import { Input, Button, Select, message } from "antd";
import _ from "lodash";
import { Project } from "../../types/project";

const { Option } = Select;

export default function SupportModal({ closeModal, currentUser, projects }) {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [formData, setFormData] = useState<Record<string, string>>({
    orgid: "00D3i000000sSbk",
    retURL: "https://manage.aloa.co",
    debug: "0",
    debugEmail: "chris@aloa.co",
    name: currentUser.name,
    email: currentUser.email,
    subject: "",
    description: "",
    "00N3i00000Dfn1r": "", // Project tier
    "00N3i00000Dfn1m": "", // project name
    "00N3i00000Dfn1w": "0", // is partner feedback (not used right now)
    "00N3i00000Dfnsb": currentUser.name, // Auth0 name
    type: "",
  });

  const [projectSelected, setProjectSelected] = useState<Partial<Project>>({});

  const handleFormSubmit = () => {
    // Get the form data and use axios to post
    // it to the salesforce endpoint
    // convert formData to
    setConfirmLoading(true);

    // post to salesforce (https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8)
    // log errors
    // fix CORS issue
    // set request mode to 'no-cors

    fetch(
      "https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8",
      {
        method: "POST",
        redirect: "follow",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      }
    )
      .then((response) => {
        message.success(
          "Your request has been submitted, expect a response shortly."
        );
        setConfirmLoading(false);
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        message.error(
          "There was an error submitting your request, please contact your strategist directly"
        );
        setConfirmLoading(false);
      });
  };

  useEffect(() => {
    if (projectSelected?.id) {
      setFormData({
        ...formData,
        "00N3i00000Dfn1r": projectSelected.project_tier ?? "",
        "00N3i00000Dfn1m": projectSelected.name ?? "",
      });
    }
  }, [projectSelected]);

  useEffect(() => {
    if (projects.length > 0) {
      console.log("useEffect called");
      // Set the first project as the default
      const url = window.location.href;

      setFormData({
        ...formData,
        retURL: url + "?support_ticket_submitted=true",
      });

      // URL structure is /p/vahdg2zw288kiik6wtvlzg/table/status_items

      const urlParts = url.split("/");
      const projectId = urlParts[4];

      console.log("Project ID", projectId);

      if (projectId) {
        const projectFromURL = projects.find((p) => p.id === projectId);
        console.log("Project from URL", projectFromURL);

        if (projectFromURL) {
          setProjectSelected(projectFromURL);
        } else {
          setProjectSelected(projects[0]);
        }
      } else {
        setProjectSelected(projects[0]);
      }
    }
  }, [projects, window.location.href]);

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ fontSize: "18px", marginBottom: "10px" }}>Get Support</div>
      <form
        action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
        method="POST"
      >
        <Input
          style={{ marginBottom: "10px" }}
          value={formData["orgid"]}
          id="orgid"
          name="orgid"
          type="hidden"
        />

        <Input
          style={{ marginBottom: "10px" }}
          value={formData["retURL"]}
          id="retURL"
          name="retURL"
          type="hidden"
        />

        <Input
          style={{ marginBottom: "10px" }}
          value={formData["debug"]}
          id="debug"
          name="debug"
          type="hidden"
        />

        <Input
          style={{ marginBottom: "10px" }}
          value={formData["debugEmail"]}
          id="debugEmail"
          name="debugEmail"
          type="hidden"
        />

        <Input
          style={{ marginBottom: "10px" }}
          value={formData.name}
          onChange={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
          id="name"
          name="name"
          type="hidden"
        />

        <Input
          style={{ marginBottom: "10px" }}
          value={formData["00N3i00000Dfnsb"]}
          onChange={(e) => {
            setFormData({ ...formData, "00N3i00000Dfnsb": e.target.value });
          }}
          id="00N3i00000Dfnsb"
          name="00N3i00000Dfnsb"
          type="hidden"
        />

        <Input
          style={{ marginBottom: "10px" }}
          value={formData.email}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
          id="email"
          name="email"
          type="hidden"
        />

        <div>Project</div>
        <Select
          style={{ marginBottom: "10px", width: "50%" }}
          value={projectSelected.id}
          onChange={(e) => {
            console.log("changed to", _.find(projects, { id: e }));
            setProjectSelected(_.find(projects, { id: e }));
          }}
        >
          {projects.map((project) => (
            <Option key={project.id} value={project.id}>
              {project.name}
            </Option>
          ))}
        </Select>

        <Input
          style={{ marginBottom: "10px" }}
          value={formData["00N3i00000Dfn1r"]}
          id="00N3i00000Dfn1r"
          name="00N3i00000Dfn1r"
          type="hidden"
        />

        <Input
          style={{ marginBottom: "10px" }}
          value={formData["00N3i00000Dfn1m"]}
          id="00N3i00000Dfn1m"
          name="00N3i00000Dfn1m"
          type="hidden"
        />

        <Input
          style={{ marginBottom: "10px" }}
          value={formData["00N3i00000Dfn1w"]}
          id="00N3i00000Dfn1w"
          name="00N3i00000Dfn1w"
          type="hidden"
        />

        <div>Subject</div>
        <Input
          style={{ marginBottom: "10px" }}
          value={formData.subject}
          placeholder="Briefly describe your concern or feedback"
          onChange={(e) => {
            setFormData({ ...formData, subject: e.target.value });
          }}
          name="subject"
          id="subject"
        />

        <div>Description</div>
        <Input.TextArea
          style={{ marginBottom: "10px" }}
          value={formData.description}
          placeholder="Add any additional details or explanation"
          onChange={(e) => {
            setFormData({ ...formData, description: e.target.value });
          }}
          name="description"
          id="description"
        />

        <div>Type</div>
        <Select
          style={{ marginBottom: "10px", width: "50%" }}
          value={formData.type}
          onChange={(e) => {
            console.log("changed to", e);
            setFormData({ ...formData, type: e });
          }}
          defaultValue=""
        >
          <Option value="">None</Option>
          <Option value="Problem">Problem</Option>
          <Option value="Feature Request">Feature Request</Option>
          <Option value="Question">Question</Option>
        </Select>

        <Input
          style={{ marginBottom: "10px" }}
          value={formData.type}
          id="type"
          name="type"
          type="hidden"
        />

        <div style={{ float: "right", marginTop: "20px" }}>
          <Button key="back" onClick={closeModal}>
            Cancel
          </Button>

          <Button
            style={{ marginLeft: "10px" }}
            key="submit"
            type="primary"
            loading={confirmLoading}
            //  onClick={handleFormSubmit}
            htmlType="submit"
          >
            Submit support request
          </Button>
        </div>
        <div style={{ clear: "right" }}> </div>
      </form>
    </div>
  );
}
