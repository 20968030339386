import React, { useState } from "react";
import { Upload, message, Badge, Modal } from "antd";
import firebase from "../../firebase";
import AttachmentCard from "./AttachmentCard";
import attach_blue from "../../images/little_attach_blue.svg";
import { newTimestamp } from "../utils";
import { useMediaQuery } from "react-responsive";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import "./Attachments.scss";

function beforeUpload(file) {
  /*
  Use this if we ever want to stop supporting certain formats

  const isJPG = file.type === 'image/jpg';
  const isPNG = file.type === 'image/png';
  const isJPEG = file.type === 'image/jpeg';
  const isGIF = file.type === 'image/gif';
  const isWebp = file.type === 'image/webp';
  const isSVG = file.type === 'image/svg+xml';

  return new Promise((resolve, reject) => {
    if(isJPG || isPNG || isJPEG || isGIF) {
      resolve(file);
    } else {
      message.error('Filetype not supported');
      reject(file);
    }
  });
  */

  return new Promise((resolve, reject) => {
    const isLt2M = file.size / 1024 / 1024 < 130;

    if (!isLt2M) {
      message.error("File must smaller than 130MB!");
      reject(file);
    } else {
      resolve(file);
    }
  });
}

export type Attachment = {
  created: any;
  id: string;
  name: string;
  size: number;
  url: string;
  user: string;
};

interface AttachmentModalProps {
  attachments?: Attachment[];
  projectId: string;
  updateAttachments: (attachments: any[]) => void;
  type: "table" | "card" | "threads";
}

export default function AttachmentModal({
  attachments = [],
  projectId,
  updateAttachments,
  type,
}: AttachmentModalProps) {
  const is13Inch = useMediaQuery({ maxWidth: 1500 });

  const [fileList, setFileList] = useState([]);
  const [filesBeingUploaded, setFilesBeingUploaded] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  const currentUser = useCurrentUser();

  function firebaseStorageUpload({ file, onSuccess, onError, onProgress }) {
    // Create a root reference
    var storageRef = firebase.storage().ref();

    var randomId =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);

    var uploadTask = storageRef
      .child("projects/" + projectId + "/" + randomId + file.name)
      .put(file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion

    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        onProgress({ percent: progress });
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            break;
        }
      },
      function (error) {
        // Handle unsuccessful uploads

        onError(error);
      },
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          onSuccess({
            url: downloadURL,
            id: randomId + file.name,
            size: uploadTask.snapshot.totalBytes,
          });
        });
      }
    );
  }

  function addAttachment(attachment) {
    message.success(`${attachment.name} file uploaded successfully.`);

    var newFilesBeingUploaded = filesBeingUploaded - 1;
    setFilesBeingUploaded(newFilesBeingUploaded);

    if (newFilesBeingUploaded == 0) {
      //   setFileList([]);
    }

    // Remove it from the file list

    var newFileList = fileList.filter((file: any) => {
      return file.name != attachment.name;
    });
    setFileList(newFileList);

    var attachmentsTemp: any[] = [...attachments];

    if (attachmentsTemp == null) {
      attachmentsTemp = [];
    }

    attachmentsTemp.push(attachment);

    updateAttachments(attachmentsTemp);
  }

  function fileChanged(info) {
    const status = info.file.status;

    setFileList(info.fileList);

    if (status !== "uploading") {
    }
    if (status === "done") {
      //  message.success(`${info.file.name} file uploaded successfully.`);
      var attachment = {
        created: newTimestamp(),
        id: info.file.response.id,
        size: info.file.response.size,
        name: info.file.name,
        user: currentUser.id,
        url: info.file.response.url,
      };

      addAttachment(attachment);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  function handleCancel(e) {
    setModalVisible(false);
  }

  if (type == "table") {
    return (
      <div className="table-attachments">
        {attachments != null && attachments.length != 0 && (
          <div onClick={() => setModalVisible(true)} className="view-files">
            View Files{" "}
            {is13Inch && (
              <Badge
                style={{
                  backgroundColor: "#353535",
                  color: "#ffffff",
                  fontSize: "12px",
                }}
                count={attachments.length}
              />
            )}
            {!is13Inch && (
              <Badge
                style={{ backgroundColor: "#353535", color: "#ffffff" }}
                count={attachments.length}
              />
            )}
          </div>
        )}
        <Modal
          title="Attachments"
          footer={null}
          visible={modalVisible}
          onCancel={handleCancel}
        >
          <div>
            {attachments.map((attachment: any) => {
              return (
                <AttachmentCard
                  key={attachment.id}
                  attachment={attachment}
                  attachments={attachments}
                  projectId={projectId}
                  updateAttachments={updateAttachments}
                />
              );
            })}
            {/* @ts-ignore */}
            <Upload
              name="file"
              multiple={true}
              beforeUpload={beforeUpload}
              customRequest={firebaseStorageUpload}
              onChange={fileChanged}
              fileList={fileList}
            >
              <div
                style={{
                  marginTop: "20px",
                  fontSize: "14px",
                  marginLeft: "5px",
                  fontFamily: "Avenir Next",
                  color: "#1563FF",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img
                  src={attach_blue}
                  style={{
                    height: "16px",
                    marginRight: "5px",
                  }}
                />

                <div
                  style={{
                    fontWeight: 500,
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  Add Files
                </div>

                {attachments != null && attachments.length != 0 && (
                  <Badge
                    style={{ backgroundColor: "#1563FF", color: "#ffffff" }}
                    count={attachments.length}
                  />
                )}
              </div>
            </Upload>
          </div>
        </Modal>

        {/* @ts-ignore */}
        <Upload
          name="file"
          multiple={true}
          beforeUpload={beforeUpload}
          customRequest={firebaseStorageUpload}
          onChange={fileChanged}
          fileList={fileList}
        >
          <div
            style={{
              fontSize: "14px",

              fontFamily: "Avenir Next",
              color: "#1563FF",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              src={attach_blue}
              style={{
                height: "16px",
                marginRight: "5px",
              }}
            />

            <div
              style={{ fontWeight: 500, marginRight: "5px", cursor: "pointer" }}
            >
              Add Files
            </div>
          </div>
        </Upload>
      </div>
    );
  } else {
    return (
      <div>
        {/* @ts-ignore */}
        <Upload
          name="file"
          multiple={true}
          beforeUpload={beforeUpload}
          customRequest={firebaseStorageUpload}
          onChange={fileChanged}
          fileList={fileList}
        >
          <div
            style={{
              marginTop: "10px",
              fontSize: "14px",
              marginLeft: "5px",
              fontFamily: "Avenir Next",
              color: "#1563FF",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              src={attach_blue}
              style={{
                height: "16px",
                marginRight: "5px",
              }}
            />

            <div
              style={{ fontWeight: 500, marginRight: "5px", cursor: "pointer" }}
            >
              Add Files
            </div>

            {attachments && (
              <Badge
                style={{ backgroundColor: "#1563FF", color: "#ffffff" }}
                count={attachments.length}
              />
            )}
          </div>
        </Upload>

        {attachments.map((attachment: any) => (
          <AttachmentCard
            key={attachment.id}
            attachment={attachment}
            attachments={attachments}
            projectId={projectId}
            updateAttachments={updateAttachments}
          />
        ))}
      </div>
    );
  }
}
