import qs from "query-string";
import _ from "lodash";
import { SectionRouteProps } from ".";
import { useLocation, useParams } from "react-router-dom";
import { AccessDenied } from "./AccessDenied";
import { Permissions, useUserPermitted } from "../../hooks/useUserPermitted";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useSections } from "../../hooks/useSections";
import { useProject } from "../../hooks/useProject";
import { useSetPage } from "../../hooks/useSetPage";
import CardList from "../CardList";

export function ProjectCardListRoute({ section }: SectionRouteProps) {
  const location = useLocation();
  const { projectId } = useParams();
  const { project } = useProject(projectId);
  const userPermitted = useUserPermitted(projectId, section, Permissions.READ);
  const currentUser = useCurrentUser();
  const { sections: components, loading } = useSections(projectId);
  const setPage = useSetPage();

  if (!userPermitted) {
    return <AccessDenied />;
  }

  return (
    <CardList
      key={section}
      projectId={projectId}
      setPage={setPage}
      currentUser={currentUser}
      project={project}
      query={qs.parse(location.search)}
      component={components[section]}
      components={components}
    />
  );
}
