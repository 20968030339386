import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loading from "../Loading";
import { auth0 } from "../../hooks/useAuth";
import { useEffect } from "react";

interface CallbackProps {
  // TODO: Don't like this here
  loggedIn: boolean;
}

export function Callback({ loggedIn }: CallbackProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const redirectUrl = `${params.get("path") ?? "/"}${params.get("query") ?? ""}`;

  useEffect(() => {
    if (!auth0.idToken) {
      auth0
        .handleAuthentication({
          navigate,
          location: location,
        })
        .then(() => {
          navigate(redirectUrl);
        })
        .catch((error) => {
          console.error("Error during authentication:", error);
          navigate("/");
        });
    } else {
      navigate(redirectUrl);
    }
  }, []);

  if (loggedIn) {
    return <Loading />;
  }

  return <Navigate replace to={redirectUrl} />;
}
