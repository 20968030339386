import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
// @ts-ignore
import { ReactComponent as Moon } from "../../images/moon.svg";
import {
  markAllNotificationsRead,
  deleteAllNotifications,
  toggleDoNotDisturb,
  setNotificationsVisible,
} from "../../actions/notifications";
import { AppState } from "@/types";

export default function NotificationMenu({ setMenuVisible }) {
  const MARK_ALL_READ_KEY = "mark_all_read";
  const DELETE_ALL_KEY = "delete_all";
  const DO_NOT_DISTURB_KEY = "do_not_disturb";

  const dispatch = useDispatch();

  const doNotDisturb = useSelector(
    (state: AppState) => state.notificationsState?.doNotDisturb
  );

  const handleClick = ({ key }) => {
    if (key === MARK_ALL_READ_KEY) {
      dispatch(markAllNotificationsRead());
    } else if (key === DELETE_ALL_KEY) {
      dispatch(deleteAllNotifications());
    } else if (key === DO_NOT_DISTURB_KEY) {
      dispatch(toggleDoNotDisturb());
      dispatch(setNotificationsVisible(false));
    }
    setMenuVisible(false);
  };

  return (
    <Menu onClick={handleClick} selectable={false}>
      <Menu.Item key={MARK_ALL_READ_KEY}>
        <CheckOutlined />
        Mark all as read
      </Menu.Item>
      <Menu.Item key={DELETE_ALL_KEY}>
        <DeleteOutlined />
        Delete all items
      </Menu.Item>
      <Menu.Item key={DO_NOT_DISTURB_KEY}>
        <span role="img" aria-label="moon" className="anticon">
          <Moon fill={doNotDisturb ? "#1890ff" : "currentColor"} />
        </span>
        Do Not Disturb
      </Menu.Item>
    </Menu>
  );
}
