import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { SectionRouteProps } from ".";
import Table from "../Table";
import { AccessDenied } from "./AccessDenied";
import { useSetPage, useCurrentUser, useSections, useProject, useUserPermitted, Permissions } from "../../hooks";

export function ProjectTableRoute({ section }: SectionRouteProps) {
  const { projectId } = useParams();
  const userPermitted = useUserPermitted(projectId, section, Permissions.READ);
  const { project } = useProject(projectId);
  const { sections: components } = useSections(projectId);
  const currentUser = useCurrentUser();
  const setPage = useSetPage();

  if (!userPermitted) {
    return <AccessDenied />;
  }

  useEffect(() => {
    // Set the page in the store to the key of the component's collection
    if (components[section].info.collectionKey) {
      setPage(components[section].info.collectionKey);
    }
  }, [section]);

  return (
    <Table
      key={section}
      project={project}
      projectId={projectId!}
      component={components[section]}
      components={components}
      currentUser={currentUser}
    />
  );
}
