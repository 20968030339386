import React, { useCallback } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import CardListDroppable from "./CardListDroppable";
import CardListDraggable from "./CardListDraggable";

export default function({ children, order, setOrder }) {
  if (!order) {
    order = children ? children.map(child => child.key) : [];
    setOrder([...order]);
  }

  const onDragEnd = useCallback(
    result => {
      const { draggableId, destination } = result;

      const childOrder = children ? children.map(child => child.key) : [];
      const childMap = childOrder.map(key => order.indexOf(key));

      if (destination) {
        order.splice(order.indexOf(draggableId), 1);
        order.splice(childMap[destination.index], 0, draggableId);
        setOrder([...order]);
      }
    },
    [children, order]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <CardListDroppable>
        {children &&
          children.map((child, index) => (
            <CardListDraggable id={child.key} index={index}>
              {child}
            </CardListDraggable>
          ))}
      </CardListDroppable>
    </DragDropContext>
  );
}
