import "./TagSelector.css";
import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import { useDispatch } from "react-redux";
import { updateUserObject } from "../../../actions/user";
import _ from "lodash";
import { BiPencil } from "react-icons/bi";
import { LabelSelectorContent } from "./LabelSelectorContent";
import { Label } from "./Label";
import { useCurrentUser } from "../../../hooks";

export default function TagSelector({ project }) {
  const currentUser = useCurrentUser();
  const [labelPickerActive, setLabelPickerActive] = useState(false);

  const [selectedLabels, setSelectedLabels] = useState(
    currentUser.project_labels ? currentUser.project_labels[project.id] : []
  );

  let labels: Record<string, Label> = currentUser.labels || {};

  function updateLabel(newValue) {
    // Let's update the current user
    const newProjectLabels = _.cloneDeep(currentUser.project_labels ?? {});
    newProjectLabels[project.id] = newProjectLabels[project.id] || [];

    // If it already exists, let's remove it
    if (newProjectLabels[project.id].includes(newValue)) {
      newProjectLabels[project.id] = newProjectLabels[project.id].filter(
        (label) => label !== newValue
      );
    } else {
      newProjectLabels[project.id].push(newValue);
    }

    const currentUserTemp = {
      ...currentUser,
      project_labels: newProjectLabels,
    };

    dispatch(updateUserObject(currentUserTemp));

    setSelectedLabels(newProjectLabels[project.id]);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLabels !== currentUser.project_labels?.[project.id]) {
      setSelectedLabels(
        currentUser.project_labels ? currentUser.project_labels[project.id] : []
      );
    }
  }, [currentUser]);

  return (
    <>
      <Popover
        visible={labelPickerActive}
        onOpenChange={(visible) => {
          setLabelPickerActive(visible);
        }}
        destroyTooltipOnHide={true}
        title={null}
        content={
          <LabelSelectorContent
            selectedLabels={selectedLabels}
            updateLabel={updateLabel}
            labelPickerActive={labelPickerActive}
            setLabelPickerActive={setLabelPickerActive}
            labels={labels}
            project={project}
          />
        }
        placement="bottom"
        trigger="click"
        id="label-popover"
      >
        <div
          className="w-fit"
          onClick={(event) => {
            setLabelPickerActive(true);
          }}
        >
          <div className="active-labels">
            {selectedLabels &&
              selectedLabels.map((label) => {
                var labelOption = labels[label];

                if (labelOption) {
                  return (
                    <div
                      key={labelOption.id + "label_option"}
                      className={"project-label"}
                    >
                      <div
                        className="label-box"
                        style={{ backgroundColor: labelOption.color }}
                      />

                      <span>{labelOption.name}</span>
                    </div>
                  );
                }
              })}

            <div className={"project-label"} style={{ fontSize: "12px" }}>
              <BiPencil className="label-box" /> Labels
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
}
