import { ComponentConfig } from "@/types";

const config: ComponentConfig = {
  info: {
    name: "Timesheet",
    addon: true,
    collectionKey: "timesheet_entries",
    vocabulary: {
      singular_upper: "Timesheet Entry",
      singular_lower: "timesheet entry",
      plural_upper: "Timesheet Entries",
      plural_lower: "timesheet entries",
    },
    default_view: "table",
    type: "timesheet",
    icon: "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Ftimesheet.svg?alt=media&token=f322321d-90a1-4052-847b-0bb691f8289e",
  },
  fields: {
    task: {
      title: "Task",
      valueKey: "task",
      fieldData: {
        type: "textArea",
        placeholder: "Task Description",
      },
      required: true,
    },
    estimated_hours: {
      title: "Estimated Hours",
      valueKey: "estimated_hours",
      fieldData: {
        type: "number",
        placeholder: "ex: 4",
        minValue: 0,
      },
      required: true,
    },
    actual_hours: {
      title: "Actual Hours",
      valueKey: "actual_hours",
      fieldData: {
        type: "number",
        placeholder: "ex: 6",
        minValue: 0,
      },
      required: false,
    },
    date: {
      title: "Date",
      valueKey: "date",
      fieldData: {
        type: "date",
        //     defaultValue: newTimestampFromDate(new Date())
      },
    },
    status: {
      title: "Status",
      valueKey: "status",
      fieldData: {
        type: "tag",
        defaultValue: "pending",
        options: {
          pending: {
            id: "pending",
            name: "Pending",
            color: "#4B4B4B",
            backgroundColor: "#FCEABC",
          },
          approved: {
            id: "approved",
            name: "Approved",
            color: "#4B4B4B",
            backgroundColor: "#D6EFFB",
          },
          complete: {
            id: "complete",
            name: "Complete",
            color: "#4B4B4B",
            backgroundColor: "#D9F5C9",
          },
          on_hold: {
            id: "on_hold",
            name: "On Hold",
            color: "#4B4B4B",
            backgroundColor: "#F9DDE5",
          },
          closed: {
            id: "closed",
            name: "Closed",
            color: "#4B4B4B",
            backgroundColor: "#FAE3D7",
          },
          in_progress: {
            id: "in_progress",
            name: "In Progress",
            color: "#4B4B4B",
            backgroundColor: "#D1DFFC",
          },
        },
        options_order: [
          "pending",
          "approved",
          "complete",
          "on_hold",
          "closed",
          "in_progress",
        ],
      },
    },
    developer: {
      title: "Developer(s)",
      valueKey: "developer",
      fieldData: {
        type: "collaborator",
        multipleEnabled: true,
      },
    },
    timesheet_group: {
      title: "Timesheet Group",
      hidden: true,
      valueKey: "timesheet_group",
      fieldData: {
        type: "object",
        collectionKey: "timesheet_groups",
        primaryKey: "name",
      },
    },
  },
  search: {
    searchableAttributes: ["task"],
  },
  notifications: {
    slack: {
      color: "#2F7EFF",
      primaryField: "date",
      fields: {
        create: [
          "task",
          "status",
          "estimated_hours",
          "actual_hours",
          "date",
          "developer",
        ],
        edit: [
          "task",
          "status",
          "estimated_hours",
          "actual_hours",
          "date",
          "developer",
        ],
      },
      buttons: [
        {
          text: "View Entry",
        },
      ],
      disabled: true,
    },
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["admin"],
  },
  cardlist: {
    enabled: false,
  },
  table: {
    index_row_key: "date",
    pivotBy: "timesheet_group",
    exclude_from_new_button: ["timesheet_entries"],
    columns: [
      "date",
      "task",
      "estimated_hours",
      "actual_hours",
      "status",
      "developer",
    ],
    summaries: {
      estimated_hours: {
        type: "sum",
      },
      actual_hours: {
        type: "sum",
      },
    },
  },
};

export default config;