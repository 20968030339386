import { useEffect, useState } from "react";
import { textReplace } from "@loomhq/loom-embed";

interface TextFieldProps {
  objectId: string;
  value: string;
  field: {
    valueKey: string;
  };
}

export function TextField({ objectId, field, value }: TextFieldProps) {
  const [loading, setLoading] = useState(value.includes("loom.com/share"));
  const [text, setText] = useState({
    __html: value,
  });

  useEffect(() => {
    const addLinksAndLoomEmbed = async (text: string) => {
      const textWithLinks = addLinksInText(text);
      const htmlText = await addLoomEmbedToText(textWithLinks);
      setText({ __html: htmlText });
      setLoading(false);
    };

    addLinksAndLoomEmbed(value);
  }, [value]);

  if (loading) {
    return (
      <div className="fieldValue">
        <div className="loading">Loading...</div>
      </div>
    );
  } else {
    return <div className="fieldValue" dangerouslySetInnerHTML={text}></div>;
  }
}

export const addLinksInText = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url) => {
    // URLs to exclude from being linked
    if (url.includes("loom.com/share")) return url;

    return `<a href="${url}" target="_blank">${url}</a>`;
  });
};

const addLoomEmbedToText = async (text: string) => {
  const loomLinkRegex = /(https:\/\/[www.]+loom.com\/share\/\w*)\?[\w=\-\&]*/gm;
  const links = text.replace(loomLinkRegex, (url) => {
    return url.split("?")[0];
  });
  return textReplace(links);
};
