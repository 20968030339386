import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { message, Popover } from "antd";
import Field from "./Fields/Field.js";
import _ from "lodash";
import TextIcon from "../../images/text_icon.svg";
import ParagraphIcon from "../../images/paragraph_icon.svg";
import NumberIcon from "../../images/number_icon.svg";
import DropdownIcon from "../../images/dropdown_icon.svg";
import DateIcon from "../../images/date_icon.svg";
import LinkIcon from "../../images/link_icon.svg";
import PasswordIcon from "../../images/password-icon.svg";
import CheckboxIcon from "../../images/checkbox_icon.svg";
import AttachmentIcon from "../../images/attachment_icon.svg";
import AvatarIcon from "../../images/avatar_icon.svg";
import ReferenceIcon from "../../images/reference_icon.svg";
import AddButtonIcon from "../../images/add-button-icon.svg";
import "./Editor.scss";
import { RiTimeLine } from "react-icons/ri";

type Errors = {
  [field_id: string]: FieldError;
};

type FieldError = {
  title?: boolean;
  dropdowns_empty?: boolean;
  dropdown?: {
    [key: string]: boolean;
  };
};

export default function FieldEditor({
  editableConfig,
  setEditableConfig,
  changesMade,
  setChangesMade,
  components,
  setSideEffects,
  creation,
}: {
  editableConfig: any;
  setEditableConfig: any;
  changesMade: any;
  setChangesMade: any;
  components: any;
  setSideEffects: any;
  creation: any;
}) {
  const [errors, setErrors] = useState<Errors>();

  function checkForErrors() {
    // Iterate through the config, the big thing to check is
    // 1. Blank Titles
    // 2. At least one dropdown option + No blank dropdown options

    var errorsTemp: Errors = {};

    Object.keys(editableConfig.fields).map(function (field_id) {
      var field = editableConfig.fields[field_id];

      if (field.title == null || field.title.replace(/\s+/g, "") == "") {
        // Log an error that it's a title issue
        if (!errorsTemp) {
          errorsTemp = {};
        }

        if (!errorsTemp[field_id]) {
          errorsTemp[field_id] = {};
        }
        errorsTemp[field_id].title = true;
      }

      if (field.fieldData?.type == "select") {
        // Log an error if it's a dropdown issue

        if (
          field.fieldData?.options &&
          Object.keys(field.fieldData?.options).length > 0
        ) {
          // Iterate through the options and see if there is a blank one
          Object.keys(field.fieldData?.options).map(function (key) {
            var option = field.fieldData?.options[key];
            if (
              option.title == null ||
              option.title.replace(/\s+/g, "") == ""
            ) {
              errorsTemp = {
                ...errorsTemp,
                [field_id]: {
                  ...errorsTemp[field_id],
                  dropdown: {
                    ...errorsTemp[field_id]?.dropdown,
                    [key]: true,
                  },
                },
              };
            }
          });
        } else {
          errorsTemp = {
            ...errorsTemp,
            [field_id]: {
              ...errorsTemp[field_id],
              dropdowns_empty: true,
            },
          };
        }
      }
    });

    setErrors(errorsTemp);

    if (errorsTemp && Object.keys(errorsTemp).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const [newFields, setNewFields] = useState<string[]>([]);

  function updateField(newField) {
    var configTemp = _.cloneDeep(editableConfig);

    configTemp.fields[newField.valueKey] = newField;

    setEditableConfig(configTemp);

    setChangesMade(true);
  }

  function deleteField(field) {
    var configTemp = _.cloneDeep(editableConfig);

    delete configTemp.fields[field.valueKey];

    if (newFields.includes(field.valueKey)) {
      // Track new fields
      var newFieldsCopy = _.cloneDeep(newFields);

      var index = newFieldsCopy.indexOf(field.valueKey);
      if (index >= 0) {
        newFieldsCopy.splice(index, 1);
      }

      setNewFields(newFieldsCopy);
    }

    setEditableConfig(configTemp);

    setChangesMade(true);
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function addField(fieldData) {
    var configTemp = _.cloneDeep(editableConfig);

    var newValueKey = makeid(5);

    var newField = {
      title: "",
      valueKey: newValueKey,
      fieldData: fieldData,
    };

    if (configTemp.fields == null) {
      configTemp.fields = {};
    }

    configTemp.fields[newValueKey] = newField;

    // Track new fields
    var newFieldsCopy = _.cloneDeep(newFields);

    newFieldsCopy.push(newValueKey);

    // We should also add them to the cardlist and tableview
    if (configTemp.table?.columns?.length > 0) {
      configTemp.table.columns.push(newValueKey);
    }

    if (configTemp.cardlist?.fieldOrder?.length > 0) {
      configTemp.cardlist.fieldOrder.push(newValueKey);
    }

    setNewFields(newFieldsCopy);

    setEditableConfig(configTemp);

    setChangesMade(true);
  }

  function duplicateField(field, index) {
    var num = 1;

    var newField = _.cloneDeep(field);

    var configTemp = _.cloneDeep(editableConfig);

    // Check if it exists, if yes increment then check again
    // If it does not exist, add it in and then end

    var newValueKey = field.valueKey + "_" + makeid(5);

    newField.valueKey = newValueKey;
    configTemp.fields[newValueKey] = newField;

    // Track new fields
    var newFieldsCopy = _.cloneDeep(newFields);

    newFieldsCopy.push(newValueKey);

    setNewFields(newFieldsCopy);

    setEditableConfig(configTemp);

    setChangesMade(true);
  }

  const dispatch = useDispatch();

  function checkFields() {
    // Iterate through fields and find foul play, reject the publish
    var valid = true;

    editableConfig.fields.map(function (field) {
      if (field.title) {
        valid = false;
      }
    });

    return valid;
  }

  function updateUser() {
    var errorsPresent = checkForErrors();

    if (errorsPresent) {
      // There are errors
      message.error("There are a few issues, check them out above");
    } else {
      /*
      var currentUserTemp = _.cloneDeep(currentUser)

      if (!currentUserTemp.customFields) {
        currentUserTemp['customFields'] = {}
      }

      currentUserTemp.customFields[type] = editableConfig

      dispatch(
        actions.configs.updateConfigForType(
          currentUser.id,
          type,
          editableConfig
        )
      ).then(function () {
        // edit
        setChangesMade(false)
        message.success('Changes Published')
      }) */
    }
  }

  if (editableConfig == null) {
    return <div></div>;
  }

  return (
    <SettingsSection title={"Fields"}>
      {editableConfig?.fields &&
        Object.keys(editableConfig?.fields).map((field_id, index) => {
          var field = editableConfig.fields[field_id];
          if (field) {
            // Lets render rerrors
            var error: FieldError | undefined;

            if (errors) {
              error = errors[field_id];
            }

            return (
              <Field
                //   type={type}
                field={field}
                updateField={updateField}
                deleteField={deleteField}
                duplicateField={duplicateField}
                index={index}
                key={field_id}
                error={error}
                isNew={
                  changesMade && newFields.includes(field_id) ? true : false
                }
                components={components}
                currentSectionId={editableConfig?.info?.collectionKey}
                setSideEffects={setSideEffects}
                creation={creation}
              />
            );
          }
        })}
      <div className="editor-footer">
        <Popover
          content={
            <div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "text",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "13px" }}
                  src={TextIcon}
                  className="add-type-icon"
                />
                Text Field
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "textArea",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "15px" }}
                  src={ParagraphIcon}
                  className="add-type-icon"
                />
                Text Area
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "number",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "15px" }}
                  src={NumberIcon}
                  className="add-type-icon"
                />
                Number
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "select",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "11px" }}
                  src={DropdownIcon}
                  className="add-type-icon"
                />
                Dropdown
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "tag",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "11px" }}
                  src={DropdownIcon}
                  className="add-type-icon"
                />
                Tag (with colors)
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "date",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "18px" }}
                  src={DateIcon}
                  className="add-type-icon"
                />
                Date Field
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "link",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "18px" }}
                  src={LinkIcon}
                  className="add-type-icon"
                />
                Link Field
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "password",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "18px" }}
                  src={PasswordIcon}
                  className="add-type-icon"
                />
                Password Field
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "checkbox",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "18px" }}
                  src={CheckboxIcon}
                  className="add-type-icon"
                />
                Checkbox Field
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "attachments",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "18px" }}
                  src={AttachmentIcon}
                  className="add-type-icon"
                />
                Attachment Field
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "user",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "15px" }}
                  src={AvatarIcon}
                  className="add-type-icon"
                />
                User Field
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "collaborator",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "15px" }}
                  src={AvatarIcon}
                  className="add-type-icon"
                />
                Collaborator Field
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "reference",
                  });
                }}
              >
                {" "}
                <img
                  style={{ height: "15px" }}
                  src={ReferenceIcon}
                  className="add-type-icon"
                />
                Reference Field
              </div>
              <div
                className="type-button"
                onClick={() => {
                  addField({
                    type: "last_updated",
                  });
                }}
              >
                {" "}
                <RiTimeLine
                  style={{ height: "15px" }}
                  className="add-type-icon"
                />
                Last Updated Field
              </div>
            </div>
          }
          title="Choose a Field"
          trigger="click"
        >
          <div
            onClick={() => {
              //     console.log("editableConfig: ", editableConfig);
            }}
            className="add-field-button"
          >
            <img src={AddButtonIcon} className="add-button-icon" />
            Add Field
          </div>
        </Popover>

        <div style={{ clear: "right" }} />
      </div>
    </SettingsSection>
  );
}

export function SettingsSection({ title, children }) {
  return (
    <div className="settings-section">
      <div className="settings-section-header">{title}</div>
      <div className="settings-section-inner">{children}</div>
    </div>
  );
}
