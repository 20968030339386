import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge } from "antd";
import { NotificationTwoTone, NotificationOutlined } from "@ant-design/icons";
import styled from "styled-components";
import _ from "lodash";
import { setNotificationsVisible } from "../../actions/notifications";
import { AppState } from "@/types";

export default function NotificationsIcon() {
  const projectUnreadNotifications = useSelector(
    (state: AppState) => state.currentUser.projectUnreadNotifications,
    _.isEqual
  );
  const notificationsVisible = useSelector(
    (state: AppState) => state.notificationsState?.visible
  );
  const doNotDisturb = useSelector(
    (state: AppState) => state.notificationsState?.doNotDisturb
  );

  const notificationCount = Object.values(
    projectUnreadNotifications ?? {}
  ).reduce((acc, curr) => acc + curr, 0);

  useEffect(() => {
    if (notificationCount > 0) {
      window.document.title = `(${notificationCount}) Aloa Manage`;
    } else {
      window.document.title = `Aloa Manage`;
    }
  }, [notificationCount]);

  const dispatch = useDispatch();

  const handleToggle = (event) => {
    console.log("Toggle notifications visibility", !notificationsVisible);
    dispatch(setNotificationsVisible(!notificationsVisible));
    event.stopPropagation();
  };

  return (
    <IconContainer onClick={handleToggle}>
      <Badge
        count={doNotDisturb ? "zzz" : notificationCount}
        style={{
          background: doNotDisturb ? "rgb(0, 0, 0, 0.4)" : "#f5222d",
        }}
        overflowCount={999}
      >
        {notificationsVisible ? (
          <NotificationTwoTone style={{ fontSize: "23px" }} />
        ) : (
          <NotificationOutlined style={{ fontSize: "23px" }} />
        )}
      </Badge>
    </IconContainer>
  );
}

const IconContainer = styled.div`
  margin-right: 20px;
  cursor: pointer;
`;
