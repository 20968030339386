import { createSlice } from "@reduxjs/toolkit";
import { User } from "../types";

export const currentUserSlice = createSlice({
  name: "currentUser",
  initialState: null as User | null,
  reducers: {
    login: (state, action) => action.payload,
    update: (state, action) => action.payload,
  },
});

export const currentUserReducers = currentUserSlice.reducer;
export const { login, update } = currentUserSlice.actions;
