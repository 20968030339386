import Text from "./Editable/Text"
import Dropdown from "./Editable/Dropdown"
import Date from "./Editable/Date"
import Avatar from "./Editable/Avatar"
import Attachment from "./Editable/Attachment"
import Number from "./Editable/Number"
import Collaborator from "./Editable/Collaborator"
import User from "./Editable/User"
import Link from "./Editable/Link"
import Password from "./Editable/Password"
import Checkbox from "./Editable/Checkbox"
import Tag from "./Editable/Tag"
import ReferenceField from "./Editable/ReferenceField"
import LastUpdated from "./Editable/LastUpdated"

export default {
    Text: Text,
    Dropdown: Dropdown,
    Date: Date,
    Avatar: Avatar,
    Attachment: Attachment,
    Number: Number,
    Collaborator: Collaborator,
    Link: Link,
    User: User,
    Password: Password,
    Checkbox: Checkbox,
    Tag: Tag,
    ReferenceField: ReferenceField,
    LastUpdated: LastUpdated
}