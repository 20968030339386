import { AppState } from "@/types";
import { useSelector } from "react-redux";
import { useUserRole } from "./useUserRole";

export enum Permissions {
  READ = "read",
  WRITE = "write",
}

export function useUserPermitted(
  projectId: string | undefined,
  section: string,
  permissions: Permissions
): boolean {
  if (!projectId) return false;

  const roles = useSelector(
    (state: AppState) => state.roles[projectId as string]
  );
  const currentUserRole = useUserRole(projectId);

  return roles[currentUserRole]?.permissions?.sections?.[section]?.[permissions];
}
