import React from "react";
import { Checkbox } from "antd";
import { FieldProps } from "./types";

export default function CheckboxField({
  valueEditable,
  checkPermissionsAndUpdateValue,
}: FieldProps<boolean>) {
  return (
    <div>
      <Checkbox
        checked={valueEditable}
        onChange={(e) => checkPermissionsAndUpdateValue(e.target.checked)}
      ></Checkbox>
    </div>
  );
}
