import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
// Actual Components
import Analytics from "../Analytics";
import Toggle, { featureTypes, On, Off } from "../FeatureToggle";
// Actions
import _ from "lodash";
import { AccessDenied } from "./AccessDenied";
import { useSelector } from "react-redux";
import { AppState } from "@/types";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useUserRole } from "../../hooks/useUserRole";
import { useProject } from "../../hooks/useProject";
import { useSetPage } from "../../hooks/useSetPage";

export function ProjectAnalyticsRoute() {
  const { projectId } = useParams();
  const roles = useSelector(
    (state: AppState) => state.roles[projectId as string]
  );
  const currentUserRole = useUserRole(projectId);
  const currentUser = useCurrentUser();
  const { project } = useProject();
  const setPage = useSetPage();

  useEffect(() => {
    setPage("analytics");
  }, []);

  return (
    <Toggle user={currentUser} feature={featureTypes.ANALYTICS}>
      <On>
        {roles[currentUserRole]?.permissions?.project?.settings?.edit && (
          <div>
            <Analytics
              // @ts-ignore
              projectId={projectId}
              currentUser={currentUserRole}
              project={project}
            />
          </div>
        )}
        {!roles[currentUserRole]?.permissions?.project?.settings?.edit && (
          <AccessDenied />
        )}
      </On>
      <Off>
        <AccessDenied />
      </Off>
    </Toggle>
  );
}
