import React from "react";
import styled from "styled-components";

export default function EmptyMessage() {
  return (
    <MessageContainer>
      No Notifications
      <ExtraMessage>
        You're up to date{" "}
        <span role="img" aria-label="celebrate">
          🎉
        </span>
      </ExtraMessage>
    </MessageContainer>
  );
}

const MessageContainer = styled.div`
  width: 100%;
  margin-top: 25vh;
  text-align: center;
  font-size: 18px;
  color: #888888;
`;

const ExtraMessage = styled.div`
  margin-top: 5px;
  font-size: 16px;
`;
