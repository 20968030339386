import { useDispatch } from "react-redux";
import { copyStringToClipboard } from "../utils";
import { Menu, Dropdown, Popconfirm, message } from "antd";
import actions from "../../actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useCurrentUserPermissions } from "../../hooks";

export default function TableRowDropdownMini({ object, projectId, component }) {
  const permissions = useCurrentUserPermissions();

  const dispatch = useDispatch();

  const confirmDeletion = () => {
    dispatch(
      actions.collections.removeObjectFromCollection(
        object,
        component.info.collectionKey,
        projectId,
        component.badge?.rules
      )
    );
  };

  const toggleCopy = () => {
    const url = `${window.location.origin}${window.location.pathname}?item=${object.id}`;
    copyStringToClipboard(url);
    message.success("Link copied to clipboard!");
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a onClick={toggleCopy} style={{ cursor: "pointer" }}>
          Share {component.info.vocabulary.singular_upper}
        </a>
      </Menu.Item>
      {permissions.sections[component.info.collectionKey]?.delete && (
        <Menu.Item key="2">
          <Popconfirm
            placement="leftTop"
            title={
              "Are you sure you want to delete this " +
              component.info.vocabulary.singular_upper +
              "?"
            }
            onConfirm={confirmDeletion}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className="dropdown-mini flex flex-row items-center h-8 w-6 cursor-pointer ml-2">
      <Dropdown overlay={menu} trigger={["click"]}>
        <BsThreeDotsVertical className="h-8 w-4 text-neutral-300 hover:text-neutral-600" />
      </Dropdown>
    </div>
  );
}
