import React, { useEffect } from "react";
import _ from "lodash";
import ProjectSettings from "../ProjectSettings";
import { AccessDenied } from "./AccessDenied";
import { useProject } from "../../hooks/useProject";
import { useParams } from "react-router-dom";
import { useSections } from "../../hooks/useSections";
import { useSetPage } from "../../hooks/useSetPage";
import { useCurrentUserPermissions } from "../../hooks";

export function ProjectSettingsRoute() {
  const { project } = useProject();
  const { projectId } = useParams();
  const { sections: components } = useSections(projectId);
  const setPage = useSetPage();

  const permissions = useCurrentUserPermissions(projectId);

  useEffect(() => {
    setPage("settings");
  }, []);

  if (!permissions) {
    return (
      <div className="text-lg font-light p-4 text-gray-400">Loading...</div>
    );
  }

  return (
    <div>
      {permissions?.project?.settings?.edit && (
        <div>
          <ProjectSettings project={project} components={components} />
        </div>
      )}

      {!permissions?.project?.settings?.edit && <AccessDenied />}
    </div>
  );
}
