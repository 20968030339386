import React from "react";
import { db } from "../../firebase";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Select, Avatar } from "antd";
import _ from "lodash";

import DefaultAvatar from "../../images/default-avatar.png";
import { useCurrentUserPermissions } from "../../hooks";
import { AppState } from "@/types";
import { useSelector } from "react-redux";

const Option = Select.Option;

export default function CollaboratorCard({ project, collaborator }) {
  const permissions = useCurrentUserPermissions();

  const collaboratorUser = useSelector(
    (state: AppState) =>
      state.collaborators?.[project.id]?.[collaborator.user]?.data
  );

  const removeCollaborator = () => {
    var newCollaborators = project.collaborators.filter(
      (projectCollaborator) => projectCollaborator.user !== collaborator.user
    );

    var newCollaborator_ids = project.collaborator_ids.filter(
      (collaboratorId) => collaboratorId !== collaborator.user
    );

    db.collection("projects")
      .doc(project.id)
      .set(
        {
          collaborators: newCollaborators,
          collaborator_ids: newCollaborator_ids,
        },
        { merge: true }
      )
      .then(() => {
        // @ts-ignore
        // window.amplitude.getInstance().logEvent("PROJECT_SETTINGS_UPDATED", {
        //   action: "collaborator_removed",
        // });
      })
      .catch((error) => { });
  };

  const handleChange = (value) => {
    const projectTemp = _.cloneDeep(project);

    // Replace
    projectTemp.collaborators.forEach((collaborator_temp) => {
      if (collaborator_temp.user === collaborator.user) {
        collaborator_temp["role"] = value;
      }
    });

    db.collection("projects")
      .doc(project.id)
      .set(
        {
          collaborators: projectTemp.collaborators,
        },
        { merge: true }
      )
      .catch(console.error);
  };

  if (!collaboratorUser) {
    return null;
  }

  var name = collaboratorUser.name;
  var initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginBottom: "20px",
      }}
    >
      {collaboratorUser.image && (
        <div style={{ display: "inline-block" }}>
          <img
            src={collaboratorUser.image}
            // @ts-ignore
            onError={(ev) => (ev.target.src = DefaultAvatar)}
            style={{
              height: 27,
              width: 27,
              borderRadius: "50%",
              marginRight: "8px",
            }}
          />
        </div>
      )}

      {!collaboratorUser.image && (
        <div style={{ display: "inline-block" }}>
          <Avatar
            style={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
              marginRight: "8px",
              verticalAlign: "middle",
            }}
          >
            {initials}
          </Avatar>
        </div>
      )}

      <div
        style={{
          display: "inline-block",
          fontFamily: "Avenir Next",
          color: "#000000",
          fontSize: "17px",
          fontWeight: "400",
        }}
      >
        {collaboratorUser.name}
      </div>

      <div
        style={{
          display: "inline-block",
          fontFamily: "Avenir Next",
          color: "#000000",
          fontSize: "17px",
          fontWeight: "400",
          marginLeft: "auto",
        }}
      >
        <div style={{ display: "inline-block", marginRight: "5px" }}>
          {permissions?.project?.collaborators?.edit && (
            <Select
              defaultValue={collaborator.role}
              style={{ width: 120 }}
              onChange={(e) => handleChange(e)}
            >
              <Option value="viewer">Viewer</Option>
              <Option value="vendor">Vendor</Option>
              <Option value="client">Client</Option>
              <Option value="admin">Admin</Option>
            </Select>
          )}

          {!permissions?.project?.collaborators?.edit && (
            <Select
              defaultValue={collaborator.role}
              style={{ width: 120 }}
              onChange={(e) => handleChange(e)}
              disabled
            >
              <Option value="viewer">Viewer</Option>
              <Option value="vendor">Vendor</Option>
              <Option value="client">Client</Option>
              <Option value="admin">Admin</Option>
            </Select>
          )}
        </div>
        {permissions?.project?.collaborators?.delete && (
          <div style={{ display: "inline-block" }}>
            <Button
              shape="circle"
              icon={<CloseOutlined />}
              style={{ border: "none" }}
              onClick={() => removeCollaborator()}
            />
          </div>
        )}
      </div>
    </div>
  );
}
