import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DownOutlined } from "@ant-design/icons";
import { message, Menu, Dropdown } from "antd";
import { MessageBubble, AddComment, ActivityBubble } from ".";
import { id, useDeepCompareEffect } from "../utils";
import actions from "../../actions";
import _ from "lodash";
import { replaceTagSpans } from "./TextField/helpers";
import { AppState, ComponentConfig } from "@/types";
import { useCurrentUser, useCurrentUserPermissions } from "../../hooks";

export default function ThreadView({
  object,
  projectId,
  type,
  component,
  visible,
  commentsOnly = false,
}: {
  object: any;
  projectId: string;
  type: string;
  component: ComponentConfig;
  visible: boolean;
  commentsOnly?: boolean;
}) {
  const [commentAttachments, setCommentAttachments] = useState([]);
  const [thread, setThread] = useState<any[]>([]);
  const [content, setContent] = useState("");
  const permissions = useCurrentUserPermissions();

  const deepEqualCheck = (left, right) => _.isEqual(left, right);

  const currentUser = useCurrentUser();
  const filter = currentUser.threadFilter || "activityandcomments";

  const comments = useSelector(
    (state: AppState) => state.threads[object.id] || {},
    deepEqualCheck
  );

  const history = useSelector(
    (state: AppState) => state.history[object.id] || {},
    deepEqualCheck
  );

  const dispatch = useDispatch();

  function handleChange(event, type) {
    var commentContent = "";

    if (type === "comment") {
      commentContent = event.target.value;
    }

    setContent(commentContent);
  }

  function updateAttachments(attachments) {
    setCommentAttachments(attachments);
  }

  useEffect(() => {
    let unsubscribeThreads = () => { };

    dispatch(
      actions.threads.fetchThreadsFromFirebase(projectId, object.id, (func) => {
        unsubscribeThreads = func;
      })
    );

    return unsubscribeThreads;
  }, []);

  useEffect(() => {
    if (!commentsOnly) {
      let unsubscribeHistory = () => { };

      dispatch(
        actions.history.fetchHistoryFromFirebase(
          projectId,
          object.id,
          (func) => {
            unsubscribeHistory = func;
          }
        )
      );

      return () => {
        unsubscribeHistory();
      };
    }
  }, []);

  useDeepCompareEffect(() => {
    // Filter relevant comments and history
    var relevantComments = _.cloneDeep(Object.values(comments));

    var relevantHistories = _.cloneDeep(Object.values(history));

    let threadTemp: any[] = [...relevantComments, ...relevantHistories];

    threadTemp.sort((a, b) => {
      var timeA = a.timestamp.toDate ? a.timestamp.toDate() : a.timestamp;
      var timeB = b.timestamp.toDate ? b.timestamp.toDate() : b.timestamp;

      return timeA - timeB;
    });

    setThread(threadTemp);

    // TODO - Figure out what this should do
    // setCommentAttachments([]);
  }, [comments, history]);

  // Let's update the last viewed timestamp for this user if applicable
  useEffect(() => {
    if (visible) {
      var threads = Object.values(comments);
      threads.sort((a: any, b: any) => {
        var timeA = a.timestamp.toDate ? a.timestamp.toDate() : a.timestamp;
        var timeB = b.timestamp.toDate ? b.timestamp.toDate() : b.timestamp;

        return timeA - timeB;
      });

      if (threads.length > 0) {
        var latestThread: any = threads.slice(-1)[0];

        if (
          object.thread_read_timestamps == null ||
          object.thread_read_timestamps[currentUser.id] == null ||
          !_.isEqual(
            object.thread_read_timestamps[currentUser.id],
            latestThread.timestamp
          )
        ) {
          // If nothing has been logged or the timestamp is not the latest, update it
          dispatch(
            actions.collections.updateThreadReadTimestampsOfObject(
              currentUser,
              latestThread.timestamp,
              projectId,
              type,
              object.id
            )
          );
        }
      }
    }
  }, [comments, visible]);

  function addComment() {
    var comment = {
      id: id(),
      user: {
        image: currentUser.image,
        name: currentUser.name,
        id: currentUser.id,
      },
      timestamp: new Date(),
      content: replaceTagSpans(content),
      attachments: commentAttachments,
      item_id: object.id,
    };

    if (permissions?.sections[component.info.collectionKey]?.comment) {
      dispatch(actions.threads.addThread(comment, projectId, type, component));
    } else {
      message.error("You don't have permission to comment on this item");
    }

    const threadTemp = [...thread];
    threadTemp.push(comment);
    threadTemp.sort((a, b) => {
      var timeA = a.timestamp.toDate ? a.timestamp.toDate() : a.timestamp;
      var timeB = b.timestamp.toDate ? b.timestamp.toDate() : b.timestamp;

      return timeA - timeB;
    });

    setThread(threadTemp);
    setContent("");
    setCommentAttachments([]);
  }

  function deleteComment(comment, projectId) {
    const threadTemp = [...thread];

    threadTemp.forEach((message) => {
      if (message.id == comment.id) {
        threadTemp.splice(threadTemp.indexOf(message), 1);
        dispatch(actions.threads.deleteThread(message, projectId));
      }
    });

    setThread(threadTemp);
  }

  function changeFilter(type) {
    dispatch(actions.user.updateUserSettingsThreadFilter(type));
  }

  if (thread == null) {
    return null;
  }

  var menu = (
    <Menu>
      <Menu.Item>
        <div onClick={() => changeFilter("activityandcomments")}>
          Activity + Comments
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => changeFilter("activity")}>Activity Only</div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => changeFilter("comments")}>Comments Only</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <div
        style={{
          flex: 6,
          textAlign: "left",
        }}
      >
        {!commentsOnly && (
          <>
            <div
              style={{
                color: "#5f5f5f",
                fontSize: "18px",
                fontWeight: "500",
                fontFamily: "Avenir Next",
                display: "inline-block",
              }}
            >
              Thread
            </div>

            <div style={{ float: "right" }}>
              {filter == "activityandcomments" && (
                <Dropdown trigger={["click"]} overlay={menu}>
                  <a className="dropdownFilter">
                    Activity + Comments <DownOutlined />
                  </a>
                </Dropdown>
              )}
              {filter == "activity" && (
                <Dropdown trigger={["click"]} overlay={menu}>
                  <a className="dropdownFilter">
                    Activity Only <DownOutlined />
                  </a>
                </Dropdown>
              )}
              {filter == "comments" && (
                <Dropdown trigger={["click"]} overlay={menu}>
                  <a className="dropdownFilter">
                    Comments Only <DownOutlined />
                  </a>
                </Dropdown>
              )}
            </div>
          </>
        )}
      </div>

      {thread.map((object) => {
        if (
          object.activity == true &&
          (filter === "activityandcomments" || filter === "activity")
        ) {
          return (
            <ActivityBubble
              key={object.eventId}
              activity={object}
              projectId={projectId}
              component={component}
            />
          );
        } else if (
          object.activity != true &&
          (filter === "activityandcomments" || filter === "comments")
        ) {
          return (
            <MessageBubble
              key={object.id}
              message={object}
              projectId={projectId}
              currentUser={currentUser}
              deleteThread={deleteComment}
            />
          );
        }
      })}

      <AddComment
        attachments={commentAttachments}
        projectId={projectId}
        currentUser={currentUser}
        onChange={(e) => handleChange(e, "comment")}
        onClick={() => addComment()}
        updateAttachments={updateAttachments}
        value={content}
        visible={visible}
      />
    </div>
  );
}
