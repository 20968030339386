import {
  configureStore,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState, User } from "@/types";
import { current_app_version } from "../constants";
import { Project } from "@/types/project";
import { reducer } from "./reducers";

export const initialState: AppState = {
  currentUser: undefined as unknown as User,
  loading: false,
  projects: {
    loaded: false,
    data: [],
  },
  project: {
    data: undefined as unknown as Project,
  },
  reports: {
    loading: false,
    data: {},
    notifications: {}
  },
  network_status: {
    saved: false,
    message: "",
  },
  threads: undefined,
  collaborators: {},
  page: "",
  features: {},
  collections: {},
  doDeepLinkAction: false,
  app: {
    version: current_app_version,
  },
  history: undefined,
  configs: {},
  roles: {},
  notifications: {},
  notificationsState: {
    visible: false,
    project: undefined as unknown as Project,
    doNotDisturb: false,
  },
  search: undefined,
  growth: {
    offers: [],
  },
};

export const idMatcher = (actionId) => (action: PayloadAction<any>) =>
  action.type.endsWith(actionId);

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
