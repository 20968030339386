import React, { Component } from "react";
import { connect } from "react-redux";

import actions from "../../actions";

import "react-vis/dist/style.css";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  DiscreteColorLegend,
  VerticalBarSeries,
} from "react-vis";

// Remove later
import { Card } from "../Objects";
import AnalyticsCard from "./AnalyticsCard";
import { Select } from "antd";
import * as projectMetrics from "../../api/projectMetrics";

const { Option } = Select;

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  ...actions.analytics,
};

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
    }; // <- set up react state
  }

  componentDidMount() {
    window.amplitude.getInstance().logEvent("ADDON_ANALYTICS_LOADED", {
      user_role: this.props.currentUser.role,
      project_name: this.props.project.name,
      project_id: this.props.project.id,
    });

    // Default is last 7 dats
    var targetSinceDate = new Date();
    targetSinceDate.setDate(targetSinceDate.getDate() - 7);

    this.loadMetricDataSince(targetSinceDate);
  }

  loadMetricDataSince = (period) => {
    projectMetrics
      .getMetricsSince(this.props.project.id, period)
      .then((data) => {
        this.setState({
          data,
          loading: false,
        });
      });
  };

  // These could be moved into a utility file, or as part of a metric api
  // They depend on the state, so leaving them here, but if needed in
  // another component they should be factored out
  getMetric = (category, type, item) => {
    const data = this.state.data;
    return Object.keys(data).map((key) => ({
      x: new Date(key),
      y: data[key][category][type][item],
    }));
  };

  getDailyMetric = (category, type, item) => {
    const dayData = {};
    const metricData = this.getMetric(category, type, item);

    metricData.forEach((datapoint) => {
      const date = datapoint.x;
      const dayKey = `${
        date.getMonth() + 1
      }-${date.getDate()}-${date.getFullYear()}`;
      if (dayData[dayKey]) {
        dayData[dayKey] = datapoint.y;
      } else {
        dayData[dayKey] = datapoint.y;
      }
    });

    return Object.keys(dayData).map((day) => ({
      x: new Date(day).getTime(),
      y: dayData[day],
    }));
  };

  getChangeMetric = (category, type, item) => {
    const metricData = this.getDailyMetric(category, type, item);

    var changeData = metricData.map((datapoint, index) => {
      if (index !== 0 && datapoint.y - metricData[index - 1].y > 0) {
        return {
          x: datapoint.x,
          y: datapoint.y - metricData[index - 1].y,
        };
      } else {
        return {
          x: datapoint.x,
          y: 0,
        };
      }
    });

    return changeData;
  };

  handleChange(value) {
    if (value == "7days") {
      var targetSinceDate = new Date();
      targetSinceDate.setDate(targetSinceDate.getDate() - 7);

      this.loadMetricDataSince(targetSinceDate);
    } else if (value == "14days") {
      var targetSinceDate = new Date();
      targetSinceDate.setDate(targetSinceDate.getDate() - 14);

      this.loadMetricDataSince(targetSinceDate);
    } else if (value == "30days") {
      var targetSinceDate = new Date();
      targetSinceDate.setDate(targetSinceDate.getDate() - 30);

      this.loadMetricDataSince(targetSinceDate);
    }
  }

  render() {
    const project = this.props.project;

    if (!project || project == {}) {
      return null;
    }

    if (this.state.loading) {
      return "Loading...";
    }

    return (
      <div>
        <div class="sectionMedium">
          <div style={{ float: "right", paddingBottom: "10px" }}>
            <Select
              defaultValue="7days"
              style={{ width: "100%" }}
              onChange={this.handleChange.bind(this)}
            >
              <Option value="7days">Last 7 Days</Option>
              <Option value="14days">Last 14 Days</Option>
              <Option value="30days">Last 30 Days</Option>
            </Select>
          </div>

          <div style={{ clear: "right" }}> </div>

          <AnalyticsCard
            seriesType="line"
            xType="time"
            stackBy={null}
            title="Current Backlog (open items)"
            subtitle="In Backlog"
            items={[
              {
                name: "Bugs",
                color: "#f76f6e",
                data: this.getDailyMetric("bugs", "count", "status_open"),
              },
              {
                name: "Feedback",
                color: "#9130ff",
                data: this.getDailyMetric("feedbacks", "count", "open"),
              },
              {
                name: "Status Items",
                color: "#2f7eff",
                data: this.getDailyMetric("statusItems", "count", "backlog"),
              },
            ]}
          />

          <AnalyticsCard
            seriesType="verticalBar"
            xType="time"
            title="Completed Work (by Day)"
            subtitle="Closed Items"
            stackBy={"y"}
            items={[
              {
                name: "Bugs",
                color: "#f76f6e",
                data: this.getChangeMetric("bugs", "count", "status_closed"),
              },
              {
                name: "Feedback",
                color: "#9130ff",
                data: this.getChangeMetric("feedbacks", "count", "closed"),
              },
              {
                name: "Status Items",
                color: "#2f7eff",
                data: this.getChangeMetric("statusItems", "count", "complete"),
              },
            ]}
          />

          <AnalyticsCard
            seriesType="line"
            xType="time"
            stackBy={null}
            title="Re-opens (by Week)"
            subtitle="Items Re-opened"
            items={[
              {
                name: "Bugs",
                color: "#f76f6e",
                data: this.getChangeMetric("bugs", "count", "reopened"),
              },
              {
                name: "Feedback",
                color: "#9130ff",
                data: this.getChangeMetric("feedbacks", "count", "reopened"),
              },
              {
                name: "Status Items",
                color: "#2f7eff",
                data: this.getChangeMetric("statusItems", "count", "reopened"),
              },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
