// @ts-ignore
import firebase from "firebase";

require("firebase/firestore");

// Initialize Firebase
var config = {
  apiKey: "AIzaSyAjU2xO3yMq8VkrpGLU6NZf0cR85HhugSA",
  authDomain: "hermes-50f48.firebaseapp.com",
  databaseURL: "https://hermes-50f48.firebaseio.com",
  projectId: "hermes-50f48",
  storageBucket: "hermes-50f48.appspot.com",
  messagingSenderId: "1068951380540",
};
firebase.initializeApp(config);

export const db = firebase.firestore();
export const auth = firebase.auth();

export default firebase;
