import { AppState } from "@/types";
import { FeatureFlag } from "@/types/featureFlags";
import { PayloadAction, asyncThunkCreator, buildCreateSlice } from "@reduxjs/toolkit";

const createAppSlice = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

export const featuresSlice = createAppSlice({
  name: "reports",
  initialState: {},
  reducers: (create) => ({
    getFeatureFlags: create.asyncThunk(
      async (_: void) => {
        const res = await fetch(`https://api.aloa.co/v1/features`);
        return await res.json();
      },
      {
        fulfilled: (
          state: AppState["features"],
          action: PayloadAction<Record<string, FeatureFlag>>
        ) => {
          state = action.payload;
        },
      }
    ),
  }),
});

export const featureReducers = featuresSlice.reducer;
export const { getFeatureFlags } = featuresSlice.actions;
