import { ComponentConfig } from "@/types";

const config: ComponentConfig = {
  info: {
    name: "Files",
    addon: true,
    collectionKey: "files",
    vocabulary: {
      singular_upper: "File",
      singular_lower: "file",
      plural_upper: "Files",
      plural_lower: "files",
    },
    default_view: "table",
    type: "file",
    icon: "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Ffiles_icon.svg?alt=media&token=4428ce03-0175-4625-b536-a0670102cebd",
  },
  fields: {
    name: {
      title: "Name",
      valueKey: "name",
      fieldData: {
        type: "text",
        placeholder: "API Schematics",
      },
      required: false,
    },
    created: {
      title: "Created",
      valueKey: "created",
      fieldData: {
        type: "date",
        //     defaultValue: newTimestampFromDate(new Date())
      },
      editingDisabled: true,
    },
    created_by_user: {
      title: "Uploader",
      valueKey: "created_by_user",
      fieldData: {
        type: "user",
      },
    },
    attachments: {
      title: "Files",
      valueKey: "attachments",
      fieldData: { type: "attachments" },
      hideTitle: false,
    },
  },
  search: {
    searchableAttributes: ["name"],
  },
  notifications: {
    slack: {
      color: "#2E7EFF",
      primaryField: "name",
      fields: {
        create: ["name"],
        edit: ["name"],
      },
      buttons: [
        {
          text: "View File",
        },
      ],
    },
  },
  cardlist: {
    enabled: false,
    fieldOrder: ["name", "created", "created_by_user", "attachments"],
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["vendor", "client", "admin"],
  },
  table: {
    index_row_key: "name",
    columns: ["name", "created", "created_by_user", "attachments"],
  },
};

export default config;