import { AppState } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const searchSlice = createSlice({
  name: "search",
  initialState: { value: "", active: false },
  reducers: {
    setSearchValue: (
      state: AppState["search"],
      action: PayloadAction<{ value: string }>
    ) => {
      state.value = action.payload.value;
    },
    toggleSearchActive: (state: AppState["search"]) => {
      state.active = !state.active;
    },
  },
});

export const searchReducer = searchSlice.reducer;
export const { setSearchValue, toggleSearchActive } = searchSlice.actions;