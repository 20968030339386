import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { deepEqualCheck } from "../utils";

import { Modal, message } from "antd";
import Field from "./Fields";

import { requiredFieldsSatisfiedForAction } from "../utils";

export default function ActionModal({
  action,
  setActiveModalAction,
  projectId,
  collectionKey,
  object,
  objectEditable,
  processedLinkedFields,
  setProcessedLinkedFields,
  updateObject,
  saveChanges,
  resetEditing,
}) {
  const components = useSelector(
    (state) => state.configs[projectId]?.sections,
    deepEqualCheck
  );

  useEffect(() => {
    if (action) {
      // Let's go through all hidden fields and auto set the values
      Object.values(action.fields).map((updateData) => {
        if (updateData.hidden) {
          var fieldId = updateData.field;

          console.log("silent update", fieldId, updateData.newValue);
          updateObject(updateData.newValue, fieldId);
        }
      });
    }
  }, [action]);

  return (
    <Modal
      title={null}
      footer={null}
      visible={action != null}
      onCancel={() => setActiveModalAction(null)}
      closable={false}
    >
      <div>
        <div className="action-modal-content">
          {action.message && (
            <div className="action-modal-message">{action.message}</div>
          )}

          {action.fields.map((updateData) => {
            var fieldId = updateData.field;

            var field = components[collectionKey]?.fields[fieldId];

            if (!field) return;

            if (!updateData.hidden) {
              return (
                <div key={field.name} className="action-modal-field">
                  <Field
                    field={field}
                    key={"action-modal-field-" + fieldId}
                    value={objectEditable[field.valueKey]}
                    projectId={projectId}
                    isEditing={true}
                    updateValue={updateObject}
                    collectionKey={collectionKey}
                    objectId={object.id}
                    components={components}
                    processedLinkedFields={processedLinkedFields}
                    setProcessedLinkedFields={setProcessedLinkedFields}
                    overrideEditingDisabled={true}
                    overrideRequired={updateData.required}
                  />
                </div>
              );
            }
          })}

          {action.buttonTitle && (
            <div>
              <div className="multiButtonSection">
                <div
                  onClick={() => {
                    resetEditing();
                    setActiveModalAction(null);
                  }}
                  className="cancelButton"
                >
                  Cancel
                </div>

                <div
                  className="createButton"
                  onClick={() => {
                    const requiredFieldsResult =
                      requiredFieldsSatisfiedForAction(
                        action,
                        objectEditable,
                        components[collectionKey]?.fields
                      );

                    if (!requiredFieldsResult.satisfied) {
                      requiredFieldsResult.missing.forEach((missingField) => {
                        message.error(
                          missingField.title +
                            " is required and cannot be blank"
                        );
                      });
                    } else {
                      saveChanges();
                      setActiveModalAction(null);
                    }
                  }}
                >
                  {action.buttonTitle || "Save"}
                </div>
              </div>

              <div style={{ clear: "right" }}> </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
