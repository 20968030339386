import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppState } from "@/types";

export type ThreadAction = PayloadAction<{
  objectId: string;
  thread: any;
}>;

export type BatchThreadAction = PayloadAction<{
  objectId: string;
  threads: { [key: string]: any };
}>;

const threadsSlice = createSlice({
  name: "threads",
  initialState: {},
  reducers: {
    loadThreads: (
      threadsState: AppState["threads"],
      action: BatchThreadAction
    ) => {
      const { objectId, threads } = action.payload;

      if (!threadsState[objectId]) {
        threadsState[objectId] = {};
      }

      threadsState[objectId] = {
        ...threads,
      };
    },
    addThread: (threadsState: AppState["threads"], action: ThreadAction) => {
      const { objectId, thread } = action.payload;

      if (!threadsState[objectId]) {
        threadsState[objectId] = {};
      }

      threadsState[objectId][thread.id] = thread;
    },
    deleteThread: (threadsState: AppState["threads"], action: ThreadAction) => {
      const { objectId, thread } = action.payload;

      if (threadsState[objectId][thread]) {
        delete threadsState[objectId][thread];
      }
    },
  },
});

export const threadReducer = threadsSlice.reducer;
export const { loadThreads, addThread, deleteThread } = threadsSlice.actions;