import React from "react";
import TableSummaryCell from "./TableSummaryCell";
import { useCurrentUserPermissions } from "../../../hooks";

export default function TableSummaries({ component, tableColumns, objects }) {
  const permissions = useCurrentUserPermissions();

  return (
    <div className="table-row">
      {tableColumns.map(function (key) {
        const field = component.fields[key];

        if (
          permissions?.sections[component.info.collectionKey]?.fields[
            field.valueKey
          ]?.read != false
        ) {
          return (
            <TableSummaryCell
              component={component}
              field={field}
              objects={objects}
              id={key}
            />
          );
        }
      })}

      <div
        className="column-header footer summary-cell"
        style={{ paddingTop: "6px" }}
      ></div>
    </div>
  );
}
