
export const styles = {
  outlinedButton: {
    color: "#7547ff",
    borderColor: "#7547ff",
    fontFamily: "Avenir Next",
    fontWeight: 500,
    fontSize: "13px"
  },
  standupValue: {
    "fontSize": "16px",
    //    backgroundColor: "#f7f7ff",
    "marginTop": "7px",
    "borderRadius": "7px",
    "padding": "7px",
    "fontWeight": "400",
    "color": "rgb(97, 97, 97)",
    "fontFamily": "\"Avenir Next\"",
    "marginLeft": "8px",
    "whiteSpace": "pre-wrap",
    outline: "none"
  },
  newStandupValue: {
    "fontSize": "15px",
    "lineHeight": "23px",
    backgroundColor: "#ffffff",
    "marginTop": "7px",
    width: "100%",
    resize: "none",
    "borderRadius": "7px",
    "padding": "10px",
    "fontWeight": "500",
    "color": "rgb(97, 97, 97)",
    border: "1px solid #d6d6d6",
    "fontFamily": "\"Avenir Next\"",
    "marginRight": "20px",
    "paddingBottom": "20px",
    "whiteSpace": "pre-wrap",
    outline: "none"
  },
  lineTextBox: {
    "fontSize": "13px",
    backgroundColor: "#ffffff",
    "marginTop": "7px",
    width: "80%",
    resize: "none",
    "borderRadius": "5px",
    "padding": "7px",
    paddingLeft: "12px",
    "fontWeight": "500",
    "color": "rgb(97, 97, 97)",
    border: "1px solid #d6d6d6",
    "fontFamily": "\"Avenir Next\"",
    "whiteSpace": "pre-wrap",
    outline: "none"
  },
  collaboratorInput: {
    "fontSize": "13px",
    backgroundColor: "#ffffff",

    width: "40%",
    resize: "none",
    "borderRadius": "5px",
    "padding": "7px",
    paddingLeft: "12px",
    "fontWeight": "500",
    "color": "rgb(97, 97, 97)",
    border: "1px solid #d6d6d6",
    "fontFamily": "\"Avenir Next\"",
    "whiteSpace": "pre-wrap",
    outline: "none"
  },
  proPic: {
    float: 'left',
    marginLeft: 15,
    marginTop: 15,
    height: '100%',
  },
  rightBlock: {
    float: 'left',
    marginLeft: 15,
    paddingBottom: 15
  },
  title: {
    fontSize: '1.4em',
    paddingTop: "1em",
    fontFamily: "Sofia Pro",
    fontWeight: 400,
    lineHeight: 'normal',
    wordWrap: 'break-word',
    maxWidth: '100%'
  },
  paper: {
    marginTop: "2.3em",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden"
  },
  paperTablet: {
    marginTop: "1.3em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: 'both',
    fontFamily: "Avenir Next",
    overflow: "hidden",
    borderRadius: "4px"
  },
  paperMobile: {
    marginTop: "2.3em",
    marginLeft: "2%",
    marginRight: "2%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden"
  },
  headerPaper: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 20,
    marginTop: "1.2em",
    clear: 'both',
    display: 'flex',
    borderRadius: "4px"
  },
  headerPaperMobile: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "2%",
    marginRight: "2%",
    clear: 'both',
    display: 'flex',
    borderRadius: "4px"
  },
  headerPaperTablet: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: 'both',
    display: 'flex',
    borderRadius: "4px"
  },
  accountHeaderItem: {
    paddingTop: "0.3em",
    fontSize: '1.0em',
    fontWeight: 500,
    color: '#666',
    fontFamily: "Avenir Next",
    wordWrap: 'break-word',
    maxWidth: '100%'
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 400,
    marginTop: 5,
    wordWrap: 'break-word',
    maxWidth: '100%'
  },
  lineItem: {
    fontSize: 20,
    paddingTop: 25
  },
  lineItemTrans: {
    fontSize: 17,
    paddingTop: 10,
    paddingBottom: 10
  },
  lineItemTransMobile: {
    fontSize: 17,
    paddingTop: 10,
    paddingBottom: 10
  },
  lineItemFileItem: {
    fontSize: 16,
    paddingTop: 16,
    paddingBottom: 16
  },
  lineItemTransFirst: {
    paddingLeft: 20,
    fontSize: 17,
    paddingTop: 10,
    paddingBottom: 10
  },
  lineItemTransFirstMobile: {
    paddingLeft: 20,
    fontSize: 17,
    paddingTop: 10,
    paddingBottom: 10
  },
  lineItemFirst: {
    fontSize: 20,
    paddingTop: 15
  },
  lineTitle: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    width: '100%',
    color: "#C1C3C6",
    paddingBottom: "4px"
  },
  lineAbout: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: 15,
    width: '100%',
    color: "#000000",
    paddingBottom: 4
  },
  lineValue: {
    fontWeight: 500,
    fontFamily: 'Avenir Next',
    fontSize: 20,
    width: '100%'
  },
  stickDivStatus: {
    //    marginLeft: "20px",
    marginRight: "20px",
    width: "220px",
    float: 'left',
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    // @ts-ignore
    // position: 'sticky',
    //     top: "20px"
  },
  stickDivStatusMobile: {
    marginLeft: "20px",
    marginRight: "20px",
    //  width: "94%",
    //  float: 'left',
    fontFamily: "Avenir Next",
    //   top: "20px"
  },
  stickDiv: {
    marginLeft: "3%",
    width: "15%",
    float: 'left',
    fontFamily: "Avenir Next",
    position: "-webkit-sticky",
    // @ts-ignore
    // position: 'sticky',
    top: "20px"
  },
  profileInfoCard: {
    paddingTop: 25,
    paddingBottom: 25,
    width: "100%",
    float: 'left',
    fontFamily: "Avenir Next",
    clear: 'both',
    "boxShadow": "rgba(0, 0, 0, 0.1) 0px 1px 5px 0px",
    "borderRadius": "8px"
  },
  profileInfoCardStatus: {
    //   paddingTop: 25,
    paddingBottom: 25,
    width: "100%",
    float: 'left',
    clear: 'both',
    //  "boxShadow": "rgba(0, 0, 0, 0.1) 0px 1px 5px 0px",
    //  "borderRadius": "8px",
    fontSize: "17px", fontFamily: "Avenir Next", fontWeight: 400
  },
  profileInfoCardStatusMobile: {
    paddingTop: 10,
    paddingBottom: 10,
    //   width: "100%",
    //  float: 'left',
    //  clear: 'both',
    "boxShadow": "rgba(0, 0, 0, 0.1) 0px 1px 5px 0px",
    "borderRadius": "8px",
    fontSize: "20px", fontFamily: "Avenir Next", fontWeight: 400
  },
  lineTitleTrans: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: "1.2vw",
    width: '100%',
    color: "#525f7f",
  },
  lineTitleTransMobile: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: "16px",
    width: '100%',
    color: "#525f7f",
  },
  lineTitleTransFiles: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: "16px",
    width: '100%',
    color: "#3d464d",
  },
  lineValueTrans: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: "1vw",
    width: '100%'
  },
  lineValueTransDate: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    color: "#5f6bff",
    fontSize: "1vw",
    width: '100%',
    cursor: "pointer"
  },
  lineValueTransDateMobile: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    color: "#5f6bff",
    fontSize: "16px",
    width: '100%',
    cursor: "pointer"
  },
  lineValueTransLeftFirst: {
    fontWeight: 400,
    padding: "5px",
    width: "80%",
    //    border: "1px solid #e2e2e2",
    //    borderRadius: "5px",
    border: "none",
    fontFamily: 'Avenir Next',
    fontSize: "15px",
    textAlign: "left",
    marginRight: "10px",
    marginLeft: "20px",
    //   height: "200px",
    //   whiteSpace: "nowrap",
    resize: "none",
    //    overflowY: "visible",
    overflowX: "none",
    textOverflow: "ellipsis"
  },
  lineValueTransLeftFirstMobile: {
    fontWeight: 400,
    padding: "5px",
    width: "80%",
    //    border: "1px solid #e2e2e2",
    //    borderRadius: "5px",
    border: "none",
    fontFamily: 'Avenir Next',
    fontSize: "15px",
    textAlign: "left",
    marginRight: "10px",
    marginLeft: "20px",
    //   height: "200px",
    //   whiteSpace: "nowrap",
    resize: "none",
    //    overflowY: "visible",
    overflowX: "none",
    textOverflow: "ellipsis"
  },
  lineValueTransLeftFirstTextArea: {
    fontWeight: 400,
    padding: "5px",
    width: "80%",
    fontFamily: 'Avenir Next',
    border: "none",
    fontSize: "15px",
    textAlign: "left",
    marginRight: "10px",
    marginLeft: "20px",
    height: "100px",
    outline: "none",
    //   whiteSpace: "nowrap",
    resize: "none",
    //    overflowY: "visible",
    //    overflowX: "none",
    textOverflow: "ellipsis"
  },
  lineValueTransLeftFirstTextAreaMobile: {
    fontWeight: 400,
    padding: "5px",
    width: "80%",
    fontFamily: 'Avenir Next',
    border: "none",
    fontSize: "15px",
    textAlign: "left",
    marginRight: "10px",
    marginLeft: "20px",
    height: "100px",
    outline: "none",
    //   whiteSpace: "nowrap",
    resize: "none",
    //    overflowY: "visible",
    //    overflowX: "none",
    textOverflow: "ellipsis"
  },
  lineValueTransLeft: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: 15,
    textAlign: "left",
    width: '100%'
  },
  exploreBox: {
    width: 400,
    display: "inline-block", marginRight: 22.5, marginLeft: 22.5,
    marginBottom: 40
  }
};

export const buttonStyle = {
  thinBlue: {
    height: "20px",
    fontSize: "12px",
    padding: "7px 16px 4px",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#dcdcdc",
    color: "#2263ff",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    marginRight: 5
  },
  thinGreen: {
    height: "20px",
    fontSize: "12px",
    padding: "7px 16px 4px",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#dcdcdc",
    color: "#0dbf0d",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    marginRight: 5
  },
  thinSkypePurp: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#00aff0",
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    color: "#00aff0",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thinJoin: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 1,
    fontWeight: 500,
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: "#F0F2F6",
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    color: "#6094F7",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thinQuoteGreen: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#12dc91",
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#ffffff",
    color: "#12dc91",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thickBlue: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "rgb(19, 30, 53)",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thickSkype: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#00aff0",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thickSkypePurp: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#00aff0",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none",
    cursor: "pointer"
  },
  thickGreen: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#02d44c",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none"
  },
  thickRed: {
    height: "30px",
    fontSize: "14px",
    paddingTop: "10px",
    width: "100%",
    border: 0,
    borderRadius: "3px",
    lineHeight: "inherit",
    backgroundColor: "#fd658f",
    color: "#ffffff",
    boxShadow: "0 1px 1px rgba(0,0,0,.3)",
    position: "relative",
    top: 0,
    marginTop: "15px!important",
    display: "inline-block",
    verticalAlign: "middle",
    userSelect: "none",
    textAlign: "center",
    textDecoration: "none"
  },
  icon: {
    height: 11,
    width: 11,
    marginRight: 5,
    display: "inline-block",
    verticalAlign: "middle"
  }
};

export const accountStyles = {
  proPic: {
    float: 'left',
    marginLeft: 15,
    marginTop: 15,
    height: '100%',
  },
  rightBlock: {
    float: 'left',
    marginLeft: 15,
    paddingBottom: 15
  },
  title: {
    fontSize: '1.4em',
    paddingTop: "1em",
    fontFamily: "Sofia Pro",
    fontWeight: 400,
    lineHeight: 'normal',
    wordWrap: 'break-word',
    maxWidth: '100%'
  },
  paper: {
    marginTop: "2.3em",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden"
  },
  paperTablet: {
    marginTop: "1.3em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: 'both',
    fontFamily: "Avenir Next",
    overflow: "hidden",
    borderRadius: "4px"
  },
  paperMobile: {
    marginTop: "2.3em",
    marginLeft: "2%",
    marginRight: "2%",
    fontFamily: "Avenir Next",
    borderRadius: "4px",
    overflow: "hidden"
  },
  headerPaper: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 20,
    marginTop: "1.2em",
    clear: 'both',
    display: 'flex',
    borderRadius: "4px"
  },
  headerPaperMobile: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "2%",
    marginRight: "2%",
    clear: 'both',
    display: 'flex',
    borderRadius: "4px"
  },
  headerPaperTablet: {
    paddingTop: 0,
    paddinLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    marginTop: "1.2em",
    marginLeft: "15%",
    marginRight: "15%",
    clear: 'both',
    display: 'flex',
    borderRadius: "4px"
  },
  accountHeaderItem: {
    paddingTop: "0.3em",
    fontSize: '1.0em',
    fontWeight: 500,
    color: '#666',
    fontFamily: "Avenir Next",
    wordWrap: 'break-word',
    maxWidth: '100%'
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 400,
    marginTop: 5,
    wordWrap: 'break-word',
    maxWidth: '100%'
  },
  lineItem: {
    fontSize: 20,
    paddingTop: 25
  },
  lineItemTrans: {
    fontSize: 17,
    paddingTop: 5
  },
  lineItemFirst: {
    fontSize: 20,
    paddingTop: 15
  },
  lineTitle: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    width: '100%',
    color: "#C1C3C6",
    paddingBottom: "4px"
  },
  lineAbout: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: 15,
    width: '100%',
    color: "#000000",
    paddingBottom: 4
  },
  lineValue: {
    fontWeight: 500,
    fontFamily: 'Avenir Next',
    fontSize: 20,
    width: '100%'
  },
  lineTitleTrans: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontsize: 13,
    width: '100%',
    color: "#525f7f",
    paddingBottom: "4px"
  },
  lineValueTrans: {
    fontWeight: 400,
    fontFamily: 'Avenir Next',
    fontSize: 15,
    width: '100%'
  },
  exploreBox: {
    width: 400,
    display: "inline-block", marginRight: 22.5, marginLeft: 22.5,
    marginBottom: 40
  }
};