import React from "react";
import { FaCheck, FaExclamation } from "react-icons/fa6";

export const ReportItem = ({
  title,
  description,
  isPassed,
  actionItems,
}: {
  title: string;
  description: string;
  isPassed: boolean;
  actionItems?: string[];
}) => {
  return (
    <div className="bg-white shadow-sm border border-solid border-neutral-200 flex flex-col rounded-xl items-center w-full">
      <div className="flex flex-row justify-between w-full items-center  p-4 gap-6 px-6">
        <div className="flex flex-col gap-1 items-start col-span-3">
          <div className="text-base font-medium">{title}</div>
          <div className="text-sm text-neutral-500">{description}</div>
        </div>

        <div
          className={`rounded-full flex flex-row items-center justify-center ${isPassed ? "bg-green-500" : "bg-orange-400"
            } text-white w-8 h-8 p-2`}
        >
          {isPassed ? <FaCheck /> : <FaExclamation />}
        </div>
      </div>
      {(actionItems?.length ?? 0) > 0 && (
        <div className="flex flex-col gap-1 items-start w-full border-solid border-x-0 border-b-0 border-t border-t-neutral-200 p-4 px-6 bg-[#f7f7f7] rounded-b-xl">
          <div className="text-base font-medium text-orange-500">
            Evaluation & Actions Taken
          </div>
          <div className="text-sm text-neutral-500">
            {actionItems?.join ? actionItems.join(", ") : actionItems}
          </div>
        </div>
      )}
    </div>
  );
};
