import { useState, useEffect } from "react";

export const DEFAULT_SORTING_VALUE = "age_descending";
export const CUSTOM_SORTING_VALUE = "Sort by -  ";

export default function useSorting(currentUser, project, component) {
  const projectId = project?.id;
  const collectionKey = component?.info?.collectionKey;

  const getInitialSortingValue = () => {
    if (currentUser?.sortingPreferences?.[projectId]?.[collectionKey]) {
      return currentUser.sortingPreferences?.[projectId]?.[collectionKey];
    } else if (component?.customOrderingOnly) {
      return CUSTOM_SORTING_VALUE;
    } else {
      return DEFAULT_SORTING_VALUE;
    }
  };

  const [sorting, setSorting] = useState(getInitialSortingValue());

  useEffect(() => {
    if (currentUser?.sortingPreferences?.[projectId]?.[collectionKey]) {
      setSorting(currentUser.sortingPreferences?.[projectId]?.[collectionKey]);
    }
  }, [currentUser]);

  return [sorting, setSorting];
}
