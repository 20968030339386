import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import actions from "../../actions";
import { isItemInView } from "../utils";
import SearchIcon from "../../images/search_icon.svg";
import { GlobalHotKeys } from "react-hotkeys";
import TopBar from "../TopBar";
import "./Objects.scss";
import { AppState } from "@/types";
import { useCurrentUser } from "../../hooks";

export default function QuickSwitcher() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useCurrentUser();
  const projects = useSelector((state: AppState) => state.projects.data);

  const [quickSwitcherActive, setQuickSwitcherActive] = useState(false);

  const handlers = {
    PROJECT_QUICK_SWITCH: (keyEvent?: KeyboardEvent | undefined) => {
      setQuickSwitcherActive(true);
    },
  };

  const keyMap = {
    PROJECT_QUICK_SWITCH: ["command+k"],
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(0);

  useEffect(() => {
    dispatch(actions.project.loadProjectsForUser(currentUser.id));
  }, []);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setQuickSwitcherActive);

  const selectedItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Once the search term changes, highlight the first result
    setSelectedProjectIndex(0);
  }, [searchQuery]);

  useEffect(() => {
    if (selectedItemRef.current && !isItemInView(selectedItemRef.current)) {
      selectedItemRef.current.scrollIntoView(false);
    }
  }, [selectedProjectIndex]);

  var validProjects = projects.filter(function (project) {
    return project.name
      .toLocaleLowerCase()
      .includes(searchQuery.toLocaleLowerCase());
  });

  return (
    <>
      <GlobalHotKeys allowChanges={true} handlers={handlers} keyMap={keyMap}>
        <TopBar />
      </GlobalHotKeys>
      {quickSwitcherActive && (
        <div ref={wrapperRef} className="quick-switcher">
          <div className="qs-search-container">
            <img className="qs-search-icon" src={SearchIcon} />
            <input
              autoFocus
              onKeyDown={(e) => {
                if (e.key == "ArrowUp") {
                  // up arrow
                  if (selectedProjectIndex > 0) {
                    setSelectedProjectIndex(selectedProjectIndex - 1);
                  }

                  e.preventDefault();
                } else if (e.key == "ArrowDown") {
                  // down arrow
                  if (selectedProjectIndex < validProjects.length - 1) {
                    setSelectedProjectIndex(selectedProjectIndex + 1);
                  }

                  e.preventDefault();
                } else if (e.key == "Enter") {
                  // enter key
                  const selectedProject = validProjects[selectedProjectIndex];
                  setQuickSwitcherActive(false);
                  navigate("/p/" + selectedProject.id + "/table/status_items");
                  e.preventDefault();
                }
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="qs-search-input"
            />
          </div>
          <div className="qs-results">
            {searchQuery != "" &&
              validProjects.map((project, index) => (
                <div
                  onClick={() => {
                    setQuickSwitcherActive(false);
                    navigate("/p/" + project.id + "/table/status_items");
                  }}
                  key={project.id + index}
                  className={
                    "qs-result " +
                    (selectedProjectIndex == index ? "switcher-selected" : "")
                  }
                  ref={selectedProjectIndex === index ? selectedItemRef : null}
                >
                  {project.name}
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, action) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
