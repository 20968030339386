import React, { useState } from "react";
import "./TagSelector.css";
import { useDispatch } from "react-redux";
import { CheckIcon, SearchIcon } from "@heroicons/react/outline";
import { useCurrentUser } from "../../../hooks";
import { update as updateUser } from "../../../store/user";

interface LabelsProps {
  hide: () => void;
}

export default function Labels({ hide }: LabelsProps) {
  const currentUser = useCurrentUser();
  const { label_filters: activeLabels, labels } = currentUser;

  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className="labels-filter-container">
      <div className="label-selector-search">
        <SearchIcon className="label-selector-search-icon" />
        <input
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          placeholder="Search labels"
          className="label-selector-search-input"
        />
      </div>

      <div className="labels-container">
        {Object.values(labels ?? {})
          .filter((label) =>
            label.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((label) => (
            <div
              key={label.id}
              onClick={() => {
                // If label is already active, remove it from activeLabels
                if (activeLabels?.includes(label.id)) {
                  const newActiveLabels =
                    activeLabels?.filter((id) => id !== label.id) || [];
                  // Update active labels for current user

                  const currentUserTemp = {
                    ...currentUser,
                    label_filters: newActiveLabels,
                  };

                  console.log("New active labels (removing)", newActiveLabels);

                  dispatch(updateUser(currentUserTemp));
                } else {
                  if (activeLabels) {
                    const newActiveLabels = [...activeLabels, label.id];

                    const currentUserTemp = {
                      ...currentUser,
                      label_filters: newActiveLabels,
                    };

                    console.log("New active labels (adding)", newActiveLabels);

                    dispatch(updateUser(currentUserTemp));
                  } else {
                    const newActiveLabels = [label.id];

                    const currentUserTemp = {
                      ...currentUser,
                      label_filters: newActiveLabels,
                    };

                    console.log("New active labels (adding)", newActiveLabels);

                    dispatch(updateUser(currentUserTemp));
                  }
                }

                hide();
              }}
              className={`label-container ${activeLabels?.includes(label.id) ? "selected" : ""
                }`}
            >
              <div className="label">
                <div
                  className="label-box"
                  style={{ backgroundColor: label.color }}
                />{" "}
                {label.name}
              </div>

              {activeLabels?.includes(label.id) && (
                <CheckIcon className="label-selected-icon" />
              )}
            </div>
          ))}
      </div>
    </div>
  );
}
