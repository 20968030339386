import { AppState } from "@/types";
import { Roles } from "../types/roles";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { addReportPermissions } from "./reports";

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {},
  reducers: {
    loadRolesForProject(
      state: AppState["roles"],
      action: PayloadAction<{ projectId: string; roles: Roles }>
    ) {
      const { projectId, roles } = action.payload;

      if (!state[projectId]) {
        state[projectId] = {
          admin: {},
          client: {},
          vendor: {},
        };
      }

      const newState = addReportPermissions(roles);
      state[projectId] = { ...newState } as Roles;
    },
  },
});

export const rolesReducer = rolesSlice.reducer;
export const { loadRolesForProject } = rolesSlice.actions;
