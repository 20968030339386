import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Loading({ visible }) {
  return (
    <LoadingContainer hidden={!visible}>
      <Spin indicator={antIcon} />
    </LoadingContainer>
  );
}

const LoadingContainer = styled.div`
  text-align: center;
`;
