import object, { deleteAttachmentFromFirebase } from "./object";
import { id } from "../components/utils";
import notifications from "../api/notifications";
import { db } from "../firebase";
import {
  incrementThreadCountForObject,
  addThreadTimestampToObject,
  removeThreadTimestampFromObject,
} from "./collections";
import {
  loadThreads as loadThreadsToObject,
  addThread as addThreadForObject,
  deleteThread as deleteThreadAction,
} from "../store/threads";

export const fetchThreadsFromFirebase =
  (projectId, objectId, setUnsubscribe) => (dispatch) =>
    new Promise((resolve) => {
      var collectionRef = db
        .collection("projects")
        .doc(projectId)
        .collection("threads")
        .where("item_id", "==", objectId);

      const unsubscribe = collectionRef.onSnapshot((snapshot) => {
        var objects = {};

        let updateComesFromLocal = false;

        snapshot.forEach((doc) => {
          updateComesFromLocal =
            doc.metadata.hasPendingWrites || updateComesFromLocal;

          if (doc.exists) {
            objects[doc.id] = doc.data();

            if (!doc.data().id || doc.data().id != doc.id) {
              objects[doc.id].id = doc.id;
            }
          }
        });

        // See https://firebase.google.com/docs/firestore/query-data/listen#events-local-changes
        // Firebase is firing for local changes, but we already handle local updates through redux actions directly
        // Our redux updates fire faster and this way keeps our coupling with firebase down
        // So to avoid duplicate loadCollection calls, we should only reload collections when the *remote* data changes
        if (!updateComesFromLocal) {
          dispatch(loadThreadsToObject({ threads: objects, objectId }));
        } else {
        }

        setUnsubscribe(unsubscribe);
      });
    });

export const addThread =
  (thread, projectId, itemType, component) => async (dispatch, getState) => {
    const { project, collections, collaborators, reports } = getState();

    const collectionData = collections[projectId];
    const projectData = { ...project.data, ...collectionData };

    if (component.info.collectionKey === "reports") {
      projectData.reports = reports?.data?.[projectId] ?? {}
    }

    if (!thread.id) thread.id = id();

    thread["item_type"] = itemType;

    // @ts-ignore
    window.amplitude.getInstance().logEvent("COMMENTED_CREATED", {
      thread_id: thread.id,
      itemType: itemType,
      user_role: getState().currentUser.role,
      project_id: getState().project.data.id,
      project_name: getState().project.data.name,
    });

    // Save it to firebase
    dispatch(
      object.addObjectToFirebase(thread, projectId, "threads", thread.id)
    ).then(() => {
      dispatch(addThreadForObject({ thread, objectId: thread.item_id }));

      notifications.newComment(
        projectData,
        thread,
        getState().currentUser,
        component,
        collaborators
      );

      // Then add it to the actual store

      dispatch(
        incrementThreadCountForObject(1, projectId, itemType, thread.item_id)
      );
      dispatch(
        addThreadTimestampToObject(thread, projectId, itemType, thread.item_id)
      );
    });
  };

export const updateThread = (thread, projectId) => {
  return function (dispatch, getState) {
    const threads = getState().threads;

    threads[thread.id] = thread;

    dispatch(loadThreadsToObject(threads));
    dispatch(
      object.updateObjectInFirebase(thread, projectId, "threads", undefined)
    );
  };
};

export const deleteThread = (thread, projectId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    //  var thread = threads[thread.id];

    // Let's check if there are attachments

    let p = Promise.resolve();

    if (thread != null) {
      for (let i = 0; i < thread.attachments.length; i++) {
        var attachment = thread.attachments[i];
        p = p.then(
          (_) =>
            new Promise((innerResolve) =>
              dispatch(
                deleteAttachmentFromFirebase(attachment, projectId, () => {
                  innerResolve();
                })
              )
            )
        );
      }

      p = p.then(
        (_) =>
          new Promise((innerResolve) => {
            // window.amplitude.getInstance().logEvent("COMMENT_DELETED", {
            //   thread_id: thread.id,
            //   user_role: getState().currentUser.role,
            //   project_id: getState().project.data.id,
            //   project_name: getState().project.data.name,
            // });

            dispatch(
              object.deleteObjectFromFirebase(
                thread.id,
                thread,
                projectId,
                "threads"
              )
            ).then(() => {
              dispatch(
                incrementThreadCountForObject(
                  -1,
                  projectId,
                  thread.item_type,
                  thread.item_id
                )
              );
              dispatch(
                removeThreadTimestampFromObject(
                  thread,
                  projectId,
                  thread.item_type,
                  thread.item_id
                )
              );
              innerResolve();
            });

            dispatch(deleteThreadAction({ thread, objectId: thread.item_id }));
          })
      );
    } else {
      //@ts-ignore
      window.amplitude.getInstance().logEvent("COMMENT_DELETED", {
        thread_id: thread.id,
        user_role: getState().currentUser.role,
        project_id: getState().project.data.id,
        project_name: getState().project.data.name,
      });

      dispatch(
        object.deleteObjectFromFirebase(thread.id, thread, projectId, "threads")
      ).then(() => {
        dispatch(
          incrementThreadCountForObject(
            -1,
            projectId,
            thread.item_type,
            thread.item_id
          )
        );
      });
    }
  });

export default {
  loadThreadsToObject: loadThreadsToObject,
  addThread: addThread,
  fetchThreadsFromFirebase: fetchThreadsFromFirebase,
  updateThread: updateThread,
  deleteThread: deleteThread,
};
