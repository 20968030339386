import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar } from "antd";
import { deepEqualCheck, getInitialsFromUser } from "../utils";

import { fetchCollaborator } from "../../actions/collaborator";

import DefaultAvatar from "../../images/default-avatar.png"

export default function CardHeader({ userId, objectName, object, projectId }) {
  const dispatch = useDispatch();

  const collaborators = useSelector((state) => state.collaborators);

  const user = useSelector(
    (state) => state.collaborators[projectId]?.[userId]?.data,
    deepEqualCheck
  );

  useEffect(() => {
    // We check if the user exists, if not then we should fetch
    if (!user) {
      dispatch(fetchCollaborator(userId, projectId));
    }
  }, [userId]);

  if (!user) {
    return <div />;
  }

  let name = "";
  let initials = getInitialsFromUser(user)

  return (
    <div>
      <div className="userImageContainer">
        {user.image != null && user.image != "" && (
          <img
            className="userImage"
            src={user.image}
            onError={(ev) =>
              (ev.target.src = DefaultAvatar)
            }
          />
        )}

        {(user.image == null || user.image == "") && (
          <Avatar size={"medium"}  className="initialsAvatar">
            {initials}
          </Avatar>
        )}
      </div>

      <div className="userNameContainer">
        <span className="userName">
          {user.name}{" "}
          <span style={{ color: "#8f8f8f" }}>
            &nbsp;({objectName} #
            <span data-cy="header-ext-id">{object.ext_id}</span>)
          </span>
        </span>
      </div>
    </div>
  );
}
