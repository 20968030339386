import { ComponentConfig } from "@/types"

export const config: ComponentConfig = {
  info: {
    name: "Credentials",
    addon: false,
    collectionKey: "credentials",
    vocabulary: {
      singular_upper: "Credential",
      singular_lower: "credential",
      plural_upper: "Credentials",
      plural_lower: "credentials"
    },
    default_view: "cardlist",
    type: "credential",
    icon:
      "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Fcred_v2.svg?alt=media&token=2db8825e-a4e8-4f20-8c31-973a638cafc0"
  },
  fields: {
    name: {
      title: "Credential Name",
      valueKey: "name",
      fieldData: {
        type: "text",
        placeholder: "Example: AWS Credentials"
      },
      required: true
    },
    created: {
      title: "Date Added (Age)",
      valueKey: "created",
      fieldData: {
        type: "date_with_age"
      },
      editingDisabled: true
    },
    email: {
      title: "Email or Username",
      valueKey: "email",
      fieldData: {
        type: "text",
        placeholder: "Example: temp-email@gmail.com"
      },
      required: true
    },
    password: {
      title: "Password",
      valueKey: "password",
      fieldData: {
        type: "password",
        placeholder: "Example: password123"
      },
      required: true
    },
    notes: {
      title: "Additional Notes",
      valueKey: "notes",
      fieldData: {
        type: "textArea",
        placeholder:
          "Example: Remember to log in via SSH and not through the DigitalOcean panel"
      },
      required: false
    },
    status: {
      title: "Status",
      valueKey: "status",
      fieldData: {
        type: "select",
        defaultValue: "valid",
        options: {
          valid: { id: "valid", name: "Valid" },
          invalid: { id: "invalid", name: "Invalid" }
        },
        options_order: ["valid", "invalid"]
      },
      editingDisabled: true,
      hidden: true
    },
    attachments: {
      title: "Attachments",
      valueKey: "attachments",
      fieldData: { type: "attachments" },
      hideTitle: true
    }
  },
  search: {
    searchableAttributes: ["name", "email"]
  },
  notifications: {
    slack: {
      color: "#FFB154",
      primaryField: "name",
      fields: {
        create: ["name", "email", "status"],
        edit: ["name", "email", "status"]
      },
      buttons: [
        {
          text: "Update Credential"
        }
      ]
    }
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["vendor", "client", "admin"]
  },
  table: {
    index_row_key: "name",
    pivotBy: "status",
    columns: ["name", "created", "email", "password", "notes", "status", "attachments"]
  },
  badge: {
    rules: { "==": [{ var: "status" }, "invalid"] }
  },
  cardlist: {
    fieldOrder: ["name", "created", "email", "password", "notes", "attachments"],
    actionButtons: [
      {
        name: "Mark as 'Not Working'",
        field: "status",
        newValue: "invalid",
        showRules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "valid"] }
          ]
        },
        permissions: {
          edit: ["vendor", "client", "admin"]
        }
      },
      {
        name: "Mark as 'Working'",
        field: "status",
        newValue: "valid",
        showRules: { "==": [{ var: "status" }, "invalid"] },
        permissions: {
          edit: ["vendor", "client", "admin"]
        }
      }
    ],
    defaultTab: "valid",
    tabs: [
      {
        name: "Working",
        key: "valid",

        rules: {
          or: [
            { "==": [{ var: "status" }, null] },
            { "==": [{ var: "status" }, "valid"] }
          ]
        }
      },
      {
        name: "Not Working",
        key: "invalid",
        rules: { "==": [{ var: "status" }, "invalid"] }
      }
    ],
  },
};

export default config;