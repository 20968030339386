import React, { useState } from "react";
import { Switch, Modal, Input, message, Button } from "antd";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import _ from "lodash";
import actions from "../../actions";

import PurpleArrow from "../../images/purp-button-right2.svg";

export function Section({
  sectionConfig,
  addonToggled,
  project,
  existingKeys,
}) {
  const navigate = useNavigate();

  const roles = useSelector((state) => state.roles[project.id]);

  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);
  const [duplicationLoading, setDuplicationLoading] = useState(false);

  const [sectionConfigEditable, setSectionConfigEditable] =
    useState(sectionConfig);

  const dispatch = useDispatch();

  function updateRoles() {
    var componentId = sectionConfigEditable?.info?.collectionKey;

    const defaultRoles = ["admin", "client", "vendor", "viewer"];

    console.log("roles: ", roles);

    const rolesCopy = _.cloneDeep(roles);

    var fieldsTemp = {};

    if (sectionConfigEditable.fields) {
      Object.keys(sectionConfigEditable.fields).forEach(function (field_id) {
        fieldsTemp[field_id] = {
          edit: true,
          read: true,
        };
      });
    }

    // Iterate through all the roles
    defaultRoles.forEach(function (role_id) {
      // If no section exists, create one
      if (rolesCopy[role_id].permissions?.sections == null) {
        rolesCopy[role_id].permissions.sections = {};
      }

      if (rolesCopy[role_id].permissions?.sections[componentId] == null) {
        rolesCopy[role_id].permissions.sections[componentId] = {
          create: true,
          edit: true,
          read: true,
          delete: true,
          comment: true,
          fields: fieldsTemp,
        };
      }

      // If a field is missing, add it
      if (
        sectionConfigEditable.fields &&
        rolesCopy[role_id].permissions?.sections[componentId].fields
      ) {
        if (
          !Object.keys(sectionConfigEditable.fields).every((item) =>
            Object.keys(
              rolesCopy[role_id].permissions.sections[componentId].fields
            ).includes(item)
          )
        ) {
          // Get all the fields that have been added that don't exist in roles
          // Add them here
          var newFields = _.difference(
            Object.keys(sectionConfigEditable.fields),
            Object.keys(
              rolesCopy[role_id].permissions?.sections[componentId].fields
            )
          );

          newFields.forEach(function (field_id) {
            rolesCopy[role_id].permissions.sections[componentId].fields[
              field_id
            ] = {
              edit: true,
              read: true,
            };
          });
        }
      }
    });

    return rolesCopy;
  }

  function handleDuplication() {
    setDuplicationLoading(true);

    if (existingKeys.includes(sectionConfigEditable.info.collectionKey)) {
      message.error("Collection Key already exists, please rename");

      setDuplicationLoading(false);
      return;
    }

    dispatch(
      actions.configs.updateSectionConfigsForProject(
        project.id,
        sectionConfigEditable
      )
    ).then(function () {
      // Now lets update the roles
      var updatedRoles = updateRoles();

      dispatch(
        actions.project.updateRolesForProject(project.id, updatedRoles)
      ).then(function () {
        setDuplicationLoading(true);
        message.success("Section Duplicated");
        window.location.reload();
      });
    });
  }

  function editField(value, key) {
    var sectionConfigEditableTemp = _.cloneDeep(sectionConfigEditable);

    if (key == "name") {
      sectionConfigEditableTemp.info[key] = value;
    } else {
      sectionConfigEditableTemp.info[key] = value.replace(/[\W]+/g, "_");
    }

    setSectionConfigEditable(sectionConfigEditableTemp);
  }

  const coreMenu = [
    "status_items",
    "standups",
    "clarifications",
    "feedbacks",
    "bugs",
    "credentials",
    "milestones",
    "request_groups",
    "timesheet_groups",
  ];

  return (
    <div className="project-settings-section-card">
      <Modal
        visible={duplicateModalVisible}
        onCancel={() => setDuplicateModalVisible(false)}
        footer={null}
        title={null}
        bodyStyle={{
          paddingBottom: "20px",
          paddingTop: "20px",
          padding: "30px",
        }}
        closable={false}
      >
        <div className="newCardTitle">Duplicate Section</div>

        <div className="input-label-title">Section Title</div>
        <Input
          className="input"
          value={sectionConfigEditable?.info?.name}
          onInput={(e) => editField(e.target.value, "name")}
        />

        <div className="input-label-title">Section Type</div>
        <Input
          className="input"
          value={sectionConfigEditable?.info?.type}
          onInput={(e) => editField(e.target.value, "type")}
        />

        <div className="input-label-title">Collection Key</div>
        <Input
          className="input"
          value={sectionConfigEditable?.info?.collectionKey}
          onInput={(e) => editField(e.target.value, "collectionKey")}
        />

        <div style={{ float: "right" }}>
          <Button
            key="back"
            onClick={() => {
              setDuplicateModalVisible(false);
            }}
          >
            Cancel
          </Button>

          <Button
            style={{ marginLeft: "10px" }}
            key="submit"
            type="primary"
            loading={duplicationLoading}
            onClick={handleDuplication}
          >
            Create Section
          </Button>
        </div>
        <div style={{ clear: "right" }}> </div>
      </Modal>
      <div className="project-settings-section-header">
        <div className="project-settings-section-icon">
          <img style={{ width: "22px" }} src={sectionConfig?.info?.icon} />
        </div>
        <div className="project-settings-section-name">
          {sectionConfig?.info?.name}
        </div>
      </div>
      <div className="field-footer">
        <div className="editor-button">
          <span style={{ marginRight: "5px" }}>Enabled</span>{" "}
          <Switch
            size={"small"}
            onClick={(checked, event) => {
              //   updateSearchable(checked)
              addonToggled(sectionConfig?.info.collectionKey);
            }}
            // checked={searchable}
            checked={
              project.addonsEnabled.includes(
                sectionConfig?.info.collectionKey
              ) || coreMenu.includes(sectionConfig?.info.collectionKey)
            }
            disabled={coreMenu.includes(sectionConfig?.info.collectionKey)}
            style={
              {
                //  backgroundColor: searchable ? '#5c56ff' : '#C9CCDF'
              }
            }
          />
        </div>

        <div className="divider" />

        <div
          onClick={() => {
            setDuplicateModalVisible(true);
          }}
          className="project-settings-section-action"
        >
          Duplicate
        </div>

        <div className="divider" />

        <div
          onClick={() => {
            navigate(`editor/section/${sectionConfig.info.collectionKey}`);
          }}
          className="project-settings-section-action"
        >
          Edit Config <img className="next-arrow" src={PurpleArrow} />
        </div>
      </div>
    </div>
  );
}
