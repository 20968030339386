import { ComponentConfig } from "@/types";

export const config: ComponentConfig = {
  info: {
    name: "Clarifications",
    addon: false,
    collectionKey: "clarifications",
    vocabulary: {
      singular_upper: "Clarification",
      singular_lower: "clarification",
      plural_upper: "Clarifications",
      plural_lower: "clarifications",
    },
    type: "clarification",
    icon: "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/icons%2Fclari_v2.svg?alt=media&token=e26f1cd3-6a29-49e8-9234-de9c3071ea1a",
  },
  search: {
    searchableAttributes: ["question"],
  },
  overages: {
    title: "Overages",
    valueKey: "overages",
    fieldData: {
      type: "reference",
      itemTypes: ["overages"],
      linkedFields: {
        overages: "discussion_tickets",
      },
    },
  },
  fields: {
    complete: {
      title: "Complete",
      valueKey: "complete",
      fieldData: {
        type: "checkbox",
        defaultValue: false,
      },
      editingDisabled: true,
      hidden: true,
    },
    created: {
      title: "Date Opened (Age)",
      valueKey: "created",
      fieldData: {
        type: "date_with_age",
      },
      editingDisabled: true,
    },
    question: {
      title: "What do you need clarified?",
      valueKey: "question",
      fieldData: {
        type: "textArea",
        placeholder:
          "Example: What was the exact hex code of the primary color we are using?",
      },
      required: true,
    },
    attachments: {
      title: "Attachments",
      valueKey: "attachments",
      fieldData: { type: "attachments" },
      hideTitle: true,
    },
  },
  notifications: {
    slack: {
      color: "#E97360",
      primaryField: "question",
      fields: {
        create: ["question", "complete"],
        edit: ["question", "complete"],
      },
      buttons: [
        {
          text: "Respond to Question",
        },
      ],
    },
  },
  permissions: {
    view: ["viewer", "vendor", "client", "admin"],
    create: ["vendor", "client", "admin"],
    edit: ["vendor", "client", "admin"],
    delete: ["admin"],
  },
  badge: {
    rules: { "==": [{ var: "complete" }, false] },
  },
  table: {
    index_row_key: "question",
    pivotBy: "complete",
    columns: ["question", "complete", "created", "attachments"],
  },
  cardlist: {
    sorting: {
      age_descending: {
        name: "Sort by age (descending)",
        key: "age_descending",
        value: "created",
        ascending: false,
      },
      age_ascending: {
        name: "Sort by age (ascending)",
        key: "age_ascending",
        value: "created",
        ascending: true,
      },
    },
    defaultTab: "open",
    tabs: [
      {
        name: "Open",
        key: "open",
        rules: { "==": [{ var: "complete" }, false] },
      },
      {
        name: "Closed",
        key: "closed",
        rules: { "==": [{ var: "complete" }, true] },
      },
    ],
    fieldOrder: ["question", "created", "attachments"],
    actionButtons: [
      {
        name: "Mark As Clarified",
        field: "complete",
        newValue: true,
        showRules: {
          or: [
            { "==": [{ var: "complete" }, null] },
            { "==": [{ var: "complete" }, false] },
          ],
        },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
      {
        name: "Re-open Question",
        field: "complete",
        newValue: false,
        showRules: { "==": [{ var: "complete" }, true] },
        permissions: {
          edit: ["vendor", "client", "admin"],
        },
      },
    ],
  },
};

export default config;