import React from "react";
import { useSelector } from "react-redux";
import { getInitialsFromUser } from "../../utils";

import DefaultAvatar from "../../../images/default-avatar.png";
import { AppState } from "@/types";
import moment from "moment";

export default function LastUpdatedField({
  projectId,
  last_updated_timestamp,
}) {
  if (!last_updated_timestamp) return <span>N/A</span>;
  return (
    <div>
      {moment(
        last_updated_timestamp.toDate
          ? last_updated_timestamp.toDate()
          : last_updated_timestamp
      ).fromNow()}
    </div>
  );
}
