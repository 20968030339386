import { AppState } from "@/types";
import { Project } from "@/types/project";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ProjectRow from "./ProjectRow";
import { useUsers, useCurrentUser } from "../../hooks";
import { fetchReportNotificationsForUser } from "../../store/reports";

type SortOption = "date_started" | "last_updated";

interface SortParams {
  sorting: SortOption;
  searchTerm: string;
}

export function useSortedProjects({ sorting, searchTerm }: SortParams) {
  const projects = useSelector(
    (state: AppState) => state.projects.data || [],
    _.isEqual
  );
  const projectsLoaded = useSelector(
    (state: AppState) => state.projects.loaded
  );

  const reportNotifications = useSelector(
    (state: AppState) => state.reports.notifications,
    _.isEqual
  );

  const currentUser = useCurrentUser();
  const { users, loading: usersLoading } = useUsers(true);

  const [activeProjects, setActiveProjects] = useState<JSX.Element[]>([]);
  const [archivedProjects, setArchivedProjects] = useState<JSX.Element[]>([]);

  const { label_filters, project_labels } = currentUser;

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch report notifications when user changes
    if (currentUser.id) {
      dispatch(fetchReportNotificationsForUser(currentUser.id));
    }
  }, [currentUser.id]);

  useEffect(() => {
    if (projectsLoaded) {
      // Sort projects by date
      // Make a copy of projects to sort
      var sortedProjects: Project[] = [...projects];

      sortedProjects.sort((a, b) => b[sorting] - a[sorting]);

      // Filter projects by label filters
      var filteredProjectsLabel = sortedProjects.filter((project) => {
        if (label_filters == null || label_filters.length === 0) {
          return true;
        }

        if (project_labels && project_labels[project.id] === undefined) {
          return false;
        }

        if (label_filters) {
          for (var i = 0; i < label_filters.length; i++) {
            if (project_labels?.[project.id].includes(label_filters[i])) {
              return true;
            }
          }
        }

        return false;
      });

      let filteredProjectsSearch = filteredProjectsLabel;

      // Filter projects by search term
      if (searchTerm !== "") {
        filteredProjectsSearch = filteredProjectsLabel.filter((project) =>
          project.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      const activeProjectsTemp = filteredProjectsSearch
        .filter((project) => project.archived === false)
        .map((project) => (
          <ProjectRow
            currentUser={currentUser}
            project={project}
            users={users}
            highlight={searchTerm}
          />
        ));

      const archivedProjectsTemp = filteredProjectsSearch
        .filter((project) => project.archived === true)
        .map((project) => (
          <ProjectRow
            currentUser={currentUser}
            project={project}
            users={users}
            highlight={searchTerm}
          />
        ));

      setActiveProjects(activeProjectsTemp);
      setArchivedProjects(archivedProjectsTemp);
    }
  }, [
    projectsLoaded,
    projects,
    label_filters?.length,
    searchTerm,
    project_labels,
    users,
  ]);

  return {
    activeProjects: activeProjects,
    archivedProjects: archivedProjects,
    loading: !projectsLoaded || usersLoading,
    reportNotifications: reportNotifications,
  };
}
