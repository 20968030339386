import { useEffect, useState } from "react";
import { useComponentsForProject, useProject } from "../../../hooks";
import settings_image from "../../../images/settings_v2.svg";
import { TitleBadge } from "./TitleBadge";

const order = [
  "status_items",
  "standups",
  "clarifications",
  "feedbacks",
  "bugs",
  "credentials",
  "settings",
  "meeting_minutes",
  "timesheet_entries",
  "additional_requests",
  "environments",
  "documentations",
  "files",
  "overages",
  "performance"
];

export function useMenuRows(projectId: string) {
  const { project, loading: projectsLoading } = useProject(projectId);
  const components = useComponentsForProject(projectId);

  const [enabledPageDataListMain, setEnabledPageDataListMain] = useState<any[]>(
    []
  );
  const [enabledPageDataListAddons, setEnabledPageDataListAddons] = useState<
    any[]
  >([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Let's get the badge counts!
    let openCounts = {};

    if (!components) {
      return;
    }

    Object.keys(components ?? {}).forEach((collectionKey) => {
      const component = components[collectionKey];

      if (!component.badge) {
        return;
      }

      if (project.badges != null && project.badges[collectionKey] != null) {
        openCounts[collectionKey] = project.badges[collectionKey]?.length;
      }
    });

    const pageData = {
      settings: {
        title: "Settings",
        image: settings_image,
        imageHeight: "10px",
      },
      null: {
        disabled: true,
      },
    };

    Object.keys(components ?? {}).forEach((collectionKey) => {
      var component = components[collectionKey];

      pageData[collectionKey] = {};

      pageData[collectionKey]["image"] = component.info.icon;

      if (openCounts[collectionKey] != null) {
        pageData[collectionKey]["title"] = (
          <>
            {component.info.name}{" "}
            <TitleBadge count={openCounts[collectionKey]} />
          </>
        );
      } else {
        pageData[collectionKey]["title"] = component.info.name;
      }

      if (component.info.default_view == "table") {
        pageData[collectionKey]["type"] = "table";
      } else {
        pageData[collectionKey]["type"] = "cardlist";
      }

      pageData[collectionKey]["addon"] = component.info.addon;

      if (component.info.addon) {
        pageData[collectionKey]["disabled"] = !(
          project.addonsEnabled?.includes(collectionKey) || false
        );
      }

      if (component.hideOnMenu) {
        pageData[collectionKey]["disabled"] = true;
      }
    });

    // Will replace this with a component order eventually
    const enabledPageDataListMain = Object.keys(pageData)
      .filter((key) => !pageData[key].disabled)
      .filter((key) => !pageData[key].addon)
      .map((key) => ({ ...pageData[key], id: key }))
      .sort(function (a, b) {
        return order.indexOf(a.id) - order.indexOf(b.id);
      });

    const enabledPageDataListAddons = Object.keys(pageData)
      .filter((key) => !pageData[key].disabled)
      .filter((key) => pageData[key].addon)
      .map((key) => ({ ...pageData[key], id: key }))
      .sort(function (a, b) {
        return order.indexOf(a.id) - order.indexOf(b.id);
      });

    setEnabledPageDataListMain(enabledPageDataListMain);
    setEnabledPageDataListAddons(enabledPageDataListAddons);

    setLoading(false);
  }, [components, project.addonsEnabled, project.badges]);

  return {
    enabledPageDataListMain,
    enabledPageDataListAddons,
    loading: projectsLoading || loading,
  };
}
