import firebase from "../../firebase";

export const callCloudFunction = (functionName: string, functionData: any) =>
  new Promise((resolve, reject) => {
    var cloudFunction = firebase.functions().httpsCallable(functionName);

    cloudFunction(functionData)
      .then(function (result) {
        resolve(result);
      })
      .catch(function (err) {
        console.warn(err);
        reject(err);
      });
  });
