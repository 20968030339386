import { useSelector } from "react-redux";
import { User } from "../types";
import { deepEqualCheck } from "../components/utils";
import { AppState } from "@/types";

export function useCurrentUser(): User {
  const currentUser = useSelector((state: AppState) => {
    return state.currentUser;
  }, deepEqualCheck);

  return currentUser;
}
