import React from "react";
import { isMobile } from "../utils";
import { Drawer } from "antd";
import { useMediaQuery } from "react-responsive";

export function CommentDrawer({ title, onClose, visible, children }) {
  const is13Inch = useMediaQuery({ maxWidth: 1500 });

  return (
    <Drawer
      width={isMobile() ? "100%" : is13Inch ? "550px" : "650px"}
      drawerStyle={{ backgroundColor: "#FBFAF9", borderRadius: "13px" }}
      headerStyle={{
        backgroundColor: "#FBFAF9",
      }}
      bodyStyle={{ padding: "20px" }}
      title={title}
      placement={"right"}
      closable={true}
      onClose={onClose}
      open={visible}
    >
      {children}
    </Drawer>
  );
}
