import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Popconfirm, message, Avatar } from "antd";
import _ from "lodash";
import { Card } from "../Objects";
import { callCloudFunction } from "../../api/cloud-functions";
import actions from "../../actions";
import localRoles from "../ProjectConfigs/Roles";
import TagSelector from "./Tags/TagSelector";
import { loadProjectsForUser } from "../../actions/project";
import { ContextMenu, MenuItem } from "./ContextMenu";
interface ProjectRowProps {
  project: any;
  currentUser: any;
  users: any;
  highlight?: string;
}

export default function ProjectRow({
  project,
  currentUser,
  users,
}: ProjectRowProps) {
  const dispatch = useDispatch();

  const hide_avatars = currentUser.hide_avatars;

  const [avatars, setAvatars] = useState([]);
  const [avatarInitials, setAvatarInitials] = useState([]);
  const [action, setAction] = useState(null);
  const [visible, setVisible] = useState(false);

  const roles = project.roles || localRoles;

  var currentRole = "user";

  project.collaborators.forEach(function (collaborator) {
    if (collaborator.user === currentUser.id) {
      currentRole = collaborator.role;
    }
  });

  const confirmDeletion = () => {
    // window.amplitude
    //   .getInstance()
    //   .logEvent("PROJECT_SETTINGS_UPDATED", { action: "project_deleted" });

    const hide = message.loading(
      "Running deletion, please wait a few seconds",
      0
    );

    if (project.id != null && project.id != "") {
      callCloudFunction("recursiveDelete", { path: `projects/${project.id}` })
        .then(() => {
          dispatch(loadProjectsForUser(currentUser.id));
          hide();
          setVisible(false);
          message.success("Deletion Complete!");
        })
        .catch(() => {
          hide();
          setVisible(false);
          message.error("Deletion Failed");
        });
    }
  };

  async function confirmArchive(e) {
    const hide = message.loading(
      "Archiving Project, please wait a few seconds",
      0
    );

    await dispatch(
      actions.project.updateProject(
        {
          ...project,
          archived: true,
        },
        project.id
      )
    );
    hide();
    setVisible(false);
    dispatch(loadProjectsForUser(currentUser.id));
  }

  useEffect(() => {
    setAvatars(
      project.collaborator_ids
        .filter((id) => users[id])
        .map((id) => {
          let image =
            users[id]?.image ||
            "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/default_avatar.jpg?alt=media&token=4828d53a-5240-448c-8647-30464a6240f5";
          return (
            <Avatar
              key={id}
              src={image}
              // @ts-ignore
              tips={users[id].name}
            />
          );
        })
    );

    const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];

    function consistentColorIndex(id: string, arrayLength: number): number {
      let hash = 0;
      for (let i = 0; i < id.length; i++) {
        hash = (hash << 5) - hash + id.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
      }
      return Math.abs(hash) % arrayLength;
    }

    // Go through and set the avatar
    setAvatarInitials(
      project.collaborator_ids
        .filter((id) => users[id])
        .map((id) => {
          let image =
            users[id]?.image ||
            "https://firebasestorage.googleapis.com/v0/b/hermes-50f48.appspot.com/o/default_avatar.jpg?alt=media&token=4828d53a-5240-448c-8647-30464a6240f5";
          return (
            <Avatar
              key={id}
              // @ts-ignore
              tips={users[id].name}
              style={{
                backgroundColor:
                  ColorList[consistentColorIndex(id, ColorList.length)],
              }}
            >
              {users[id]?.name?.charAt(0) || users[id]?.email?.charAt(0) || "A"}
            </Avatar>
          );
        })
    );
  }, [project, users]);

  if (project.last_updated_timestamp != null) {
    var date = moment(project.last_updated_timestamp);
    if (!date.isValid()) {
      project.last_updated_timestamp = project.last_updated_timestamp.toDate();
    }
  }

  const showPopconfirm = (action) => {
    setAction(action);
    setVisible(true);
  };

  return (
    <div className="dashCard" style={{ lineHeight: 1.5 }}>
      <ContextMenu
        enabled={
          roles[currentRole]?.permissions?.project?.settings?.delete &&
          currentUser?.acc_type == "2"
        }
        menuItems={
          <>
            <MenuItem onClick={() => showPopconfirm("delete")}>Delete</MenuItem>
            <MenuItem onClick={() => showPopconfirm("archive")}>
              Archive
            </MenuItem>
          </>
        }

      >
        <Popconfirm
          title={`Are you sure you want to ${action}?`}
          open={visible}
          onConfirm={action === "delete" ? confirmDeletion : confirmArchive}
          onCancel={() => setVisible(false)}
          okText="Yes"
          cancelText="No"
          placement="bottomRight"
        />
        <Link
          to={"/p/" + project.id + "/table/status_items"}
          style={{ textDecoration: "none" }}
        >
          {/* @ts-ignore */}
          <Card
            dashCard={true}
            style={{ marginBottom: "0px", padding: "20px" }}
          >
            <div style={{ textAlign: "left" }}>
              <div className="project-card-title" data-cy="dash-project-title">
                {project.name}
              </div>

              <div className="project-updated-title">
                Updated on{" "}
                {moment(project.last_updated_timestamp).format(
                  "MMM Do hh:mm A"
                )}
              </div>

              {!hide_avatars && (
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Avatar.Group
                    size="small"
                    maxCount={3}
                    maxStyle={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                      fontSize: "12px",
                    }}
                  >
                    {avatars}
                  </Avatar.Group>
                </div>
              )}

              {hide_avatars && (
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <Avatar.Group
                    size="small"
                    maxCount={3}
                    maxStyle={{
                      color: "#f56a00",
                      backgroundColor: "#fde3cf",
                      fontSize: "12px",
                    }}
                  >
                    {avatarInitials}
                  </Avatar.Group>
                </div>
              )}
              {currentUser.acc_type == 2 && (
                <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  <TagSelector project={project} />
                </div>
              )}
            </div>
          </Card>
        </Link>
      </ContextMenu>
    </div>
  );
}
