import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { CUSTOM_SORTING_VALUE } from "./useSorting";
import actions from "../../../actions";

/**
 * UseCardListOrder custom hook
 * Will determine the order of the items in the cardlist based on sorting
 * @param {object} project
 * @param {object} component
 * @param {object} collections
 * @param {object} sorting
 */
export default function useCardListOrder(
  project,
  component,
  collections,
  sorting
) {
  const [cardListOrder, setCardListOrder] = useState(
    buildCardListOrder(project, component, collections, sorting)
  );
  const dispatch = useDispatch();

  useEffect(
    () =>
      setCardListOrder(
        buildCardListOrder(project, component, collections, sorting)
      ),
    [sorting]
  );

  useEffect(() => {
    const unsortedObjectsExist =
      !cardListOrder.includes(component.info.collectionKey) ||
      !cardListOrder.every((value) =>
        component.info.collectionKey?.includes(value)
      );

    if (unsortedObjectsExist) {
      const newOrder = buildCardListOrder(
        project,
        component,
        collections,
        sorting
      );

      const shouldSaveNewOrder = sorting === CUSTOM_SORTING_VALUE;

      if (shouldSaveNewOrder) {
        setAndSaveCardistOrder(newOrder);
      } else {
        setCardListOrder(newOrder);
      }
    }
  }, [collections]);

  const setAndSaveCardistOrder = (newOrder) => {
    dispatch(
      actions.project.updateCollectionViewConfig(
        project.id,
        component.info.collectionKey,
        "cardlist",
        "order",
        newOrder
      )
    );
    setCardListOrder(newOrder);
  };

  // The only time we'll want to directly set the order instead of building it is on drag/drop
  return [cardListOrder, setAndSaveCardistOrder];
}

const buildCardListOrder = (project, component, collections, sorting) => {
  if (_.isEmpty(collections) || _.isEmpty(collections)) {
    return [];
  }

  const collectionKey = component.info.collectionKey;
  const objects = collections[collectionKey] ?? {};

  // Get all objects - we want them all in the order, filtering will happen on render
  const cardListObjects = Object.values(objects);

  // Sorting helper fields, pulled from config
  const sortingData = component?.cardlist?.sorting?.[sorting] ?? {};

  const sortByValue = sortingData.value;
  const fieldToSortBy = component.fields?.[sortByValue];
  const sortByfieldData = Object.assign({}, fieldToSortBy?.fieldData);
  const fieldType = sortByfieldData?.type;

  let sortedObjects = cardListObjects;

  // TODO: These field types should NOT be strings - they need to be constants. This is going to cause issues
  // If we are sorting with any kind of date
  if (fieldType == "date_with_age" || fieldType == "date") {
    sortedObjects = cardListObjects.sort((a, b) =>
      sortingData.ascending
        ? b[sortByValue].seconds - a[sortByValue].seconds
        : a[sortByValue].seconds - b[sortByValue].seconds
    );
  }

  // If we are sorting with select
  if (
    fieldType == "tag" ||
    fieldType == "select" ||
    fieldType == "multi_select"
  ) {
    // Converting the tag stuff to be strings for consistency
    sortByfieldData.options_order = sortByfieldData.options_order.map((value) =>
      value.toString()
    );

    sortedObjects = cardListObjects.sort((a, b) => {
      return sortingData.ascending
        ? sortByfieldData.options_order.indexOf(
            a[sortByValue] != null ? a[sortByValue].toString() : null
          ) -
            sortByfieldData.options_order.indexOf(
              b[sortByValue] != null ? b[sortByValue].toString() : null
            )
        : sortByfieldData.options_order.indexOf(
            b[sortByValue] != null ? b[sortByValue].toString() : null
          ) -
            sortByfieldData.options_order.indexOf(
              a[sortByValue] != null ? a[sortByValue].toString() : null
            );
    });
  }

  if (sorting === CUSTOM_SORTING_VALUE) {
    const customOrder = getSavedOrder(project, collectionKey);
    if (customOrder) {
      sortedObjects = customOrder
        .filter((id) => !_.isEmpty(objects[id]))
        .map((id) => objects[id]);
    }

    const sortedIds = sortedObjects.map((object) => object.id);

    const missingObjects = cardListObjects.filter(
      (object) => !sortedIds.includes(object.id)
    );
    if (missingObjects.length > 0) {
      missingObjects.forEach((object) => sortedObjects.push(object));
    }
  }

  const order = sortedObjects.map((object) => object.id);

  return order;
};

const getSavedOrder = (project, collectionKey) =>
  project.view_configs?.default?.configs?.[collectionKey]?.cardlist?.order;
