import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchModalContent from "./SearchModalContent";
import { toggleSearchActive } from "../../store/search";
import { isMobile } from "../utils";
import { Modal } from "antd";
import { AppState } from "@/types";

interface SearchModalProps {
  projectId: string | undefined;
  components: any;
}

export default function SearchModal({ projectId, components }: SearchModalProps) {
  const searchState = useSelector((state: AppState) => state.search);
  const dispatch = useDispatch();

  const toggleSearch = () => {
    dispatch(toggleSearchActive());
  };

  return (
    <Modal
      closable={false}
      open={searchState.active}
      onCancel={toggleSearch}
      width={isMobile() ? "95%" : "60%"}
      footer={null}
      bodyStyle={{ padding: "0px" }}
      className="searchModal"
    >
      <SearchModalContent
        projectId={projectId}
        components={components}
        onClose={toggleSearch}
        isActive={searchState.active}
      />
    </Modal>
  );
}
