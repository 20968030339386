import React, { useState, useRef, useEffect } from "react";

import { Input, Select, Tooltip, Switch } from "antd";

import _ from "lodash";

const { Option } = Select;

import AvatarIcon from "../../../../images/avatar_icon.svg"
import LockedIcon from "../../../../images/locked_icon.svg"

export default function Collaborator({
  error,
  isEditing,
  field,
  updateField,
  disabled,
}) {
  const [fieldEditable, setFieldEditable] = useState(field);

  function handleTypeChange(value) {

    // setIsEditing(true)
  }

  function updateTitle(e) {
    var value = e.target.value;

    var fieldCopy = _.cloneDeep(field);

    fieldCopy["title"] = value;

    setFieldEditable(fieldCopy);
  }

  function updateMultipleEnabled(checked) {

    var fieldEditableTemp = _.cloneDeep(fieldEditable);

    if (fieldEditableTemp.fieldData) {
      fieldEditableTemp.fieldData.multipleEnabled = checked
    }

    setFieldEditable(fieldEditableTemp);


    updateField(fieldEditableTemp);
  }


  function sendUpdate() {
    updateField(fieldEditable);
  }

  return (
    <div>
      <div className="fields">
        <div class="header-fields">
          <img
            className="header-icon"
            src={AvatarIcon}
          />

          <span className="header-text">Collaborator</span>

          {disabled && (
            <Tooltip
              placement="right"
              title={"This is a default field and cannot be edited"}
            >
              <img
                className="locked-icon"
                src={LockedIcon}
              />
            </Tooltip>
          )}
        </div>

        <div className="input-box-with-title">
          <label class="required">
            Field Title{" "}
            {error?.title && (
              <span style={{ marginLeft: "5px", color: "#ff5858" }}>
                Field Cannot be Blank
              </span>
            )}
          </label>
          <div className={"input-value" + (error?.title ? " error" : "")}>
            <Input
              disabled={disabled}
              value={fieldEditable.title}
              onChange={(e) => updateTitle(e)}
              onBlur={() => sendUpdate()}
            />
          </div>
        </div>

        <div className="input-box-with-title">
          <label class="required">
            Multiple Allowed{" "}
          </label>
          <div className={"input-value" + (error?.title ? " error" : "")}>
          <Switch checked={fieldEditable?.fieldData?.multipleEnabled || false} onChange={updateMultipleEnabled} />
    
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
