import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MediaQuery from "react-responsive";
import logo from "../../images/aloa_small_line.png";

import { Avatar, Menu, Drawer } from "antd";
import { getInitialsFromUser } from "../utils";
import NotificationsIcon from "./NotificationsIcon";
import actions from "../../actions";
import { Modal } from "antd";
import SupportModal from "./SupportModal";

import HamburgerIcon from "../../images/hamburger.svg";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { AppState } from "@/types";

export default function TopBar() {
  const currentUser = useCurrentUser();
  const [visible, setVisible] = useState(false);

  const [growthTab, setGrowthTab] = useState(false);

  const projects = useSelector((state: AppState) => state.projects.data);

  const [projectsValidForSupport, setProjectsValidForSupport] = useState(
    projects.filter((project) => project.project_tier)
  );

  const [supportModalVisible, setSupportModalVisible] = useState(false);

  const initials = getInitialsFromUser(currentUser);

  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.includes("growth")) {
      setGrowthTab(true);
    } else {
      setGrowthTab(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setProjectsValidForSupport(
      projects.filter((project) => {
        // Project has a tier and user is a client of the project

        // Find the user info for project in collaborators
        const user = project.collaborators.find(
          (collaborator) => collaborator.user === currentUser.id
        );
        const role = user ? user.role : null;

        return project.project_tier && role === "client";
      })
    );
  }, [projects]);

  useEffect(() => {
    dispatch(actions.project.loadProjectsForUser(currentUser.id));
  }, [currentUser.id]);

  return (
    <div>
      <div className="flex w-full items-center py-1 px-5 bg-white border-b border-gray-200 border-solid border-0">
        <Modal
          className="p-5"
          open={supportModalVisible}
          closable={false}
          footer={null}
        >
          <SupportModal
            projects={projectsValidForSupport}
            closeModal={() => setSupportModalVisible(false)}
            currentUser={currentUser}
          />
        </Modal>

        <div className="flex w-full items-center">
          <MediaQuery minWidth={701}>
            <div style={{ flex: 1 }}>
              <div style={{ display: "inline-block" }}>
                <Link to="/">
                  <img
                    src={logo}
                    alt="Logo"
                    height="28"
                    //@ts-ignore
                    vspace="13"
                    hspace="14"
                  />
                </Link>
              </div>

              <div style={{ display: "inline-block", marginLeft: "50px" }}>
                <Link
                  style={{
                    color: growthTab ? "#272727" : "#2d89f6",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Avenir Next",
                  }}
                  to="/"
                // onClick={() => topBarMenuClicked("manage")}
                >
                  Manage
                </Link>
              </div>
              <div style={{ display: "inline-block", marginLeft: "25px" }}>
                <a
                  style={{
                    color: "#272727",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Avenir Next",
                  }}
                  href={`${process.env.REACT_APP_PAY_URL}`}
                // onClick={() => topBarMenuClicked("pay")}
                >
                  Pay
                </a>
              </div>
              <div style={{ display: "inline-block", marginLeft: "25px" }}>
                <Link
                  style={{
                    color: growthTab ? "#2d89f6" : "#272727",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Avenir Next",
                  }}
                  to="/growth"
                // onClick={() => topBarMenuClicked("growth")}
                >
                  Growth
                </Link>
              </div>
            </div>

            {projectsValidForSupport.length > 0 && (
              <div style={{ display: "inline-block", marginLeft: "25px" }}>
                <div
                  style={{
                    color: "#4200ffe6",
                    fontSize: "16px",
                    fontWeight: "500",
                    fontFamily: "Avenir Next",
                    marginRight: "14px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSupportModalVisible(true);
                  }}
                >
                  Get Support
                </div>
              </div>
            )}

            <NotificationsIcon />

            <div style={{ paddingTop: 2, paddingRight: 10 }}>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "10px",
                  textAlign: "right",
                }}
              >
                <div
                  style={{
                    fontFamily: "Brown",
                    color: "#5A7685",
                    fontSize: "13px",
                    fontWeight: 200,
                  }}
                >
                  <Link
                    style={{
                      fontFamily: "Brown",
                      color: "#5A7685",
                      fontSize: "13px",
                      fontWeight: 400,
                      textDecoration: "none",
                    }}
                    to={process.env.REACT_APP_ACCOUNTS_URL!}
                  >
                    {currentUser.name}
                  </Link>
                </div>
                <div
                  style={{
                    fontFamily: "Brown",
                    color: "#A8A8A8",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {currentUser.company}
                </div>
              </div>

              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginTop: "3px",
                }}
              >
                {currentUser.image != null && currentUser.image != "" && (
                  <a href={`${process.env.REACT_APP_ACCOUNTS_URL}`}>
                    <img
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                      src={currentUser.image}
                    />
                  </a>
                )}

                {(currentUser.image == null || currentUser.image == "") && (
                  <a href={`${process.env.REACT_APP_ACCOUNTS_URL}`}>
                    <Avatar
                      style={{
                        color: "#f56a00",
                        fontSize: "15px",
                        height: "35px",
                        width: "35px",
                        backgroundColor: "#fde3cf",
                        verticalAlign: "middle",
                      }}
                    >
                      {initials}
                    </Avatar>
                  </a>
                )}
              </div>
            </div>
          </MediaQuery>

          <MediaQuery maxWidth={700}>
            <div style={{ flex: 1 }}>
              <div
                style={{ display: "inline-block" }}
                onClick={() => setVisible(true)}
              >
                <img style={{ width: "20px" }} src={HamburgerIcon} />
              </div>

              <Drawer
                title="Menu"
                placement="left"
                closable={true}
                onClose={() => setVisible(false)}
                visible={visible}
              >
                <Menu mode="inline" defaultSelectedKeys={["manage"]}>
                  <Menu.Item key="manage">
                    <Link to="/">Manage</Link>
                  </Menu.Item>
                  <Menu.Item key="pay">
                    <a
                      href={`${process.env.REACT_APP_PAY_URL}`}
                    // onClick={() => topBarMenuClicked("pay")}
                    >
                      Pay
                    </a>
                  </Menu.Item>
                  <Menu.Item key="growth">
                    <Link
                      to="/growth"
                    // onClick={() => topBarMenuClicked("growth")}
                    >
                      Growth
                    </Link>
                  </Menu.Item>
                </Menu>
              </Drawer>

              <div style={{ display: "inline-block" }}>
                <Link to="/">
                  <img
                    src={logo}
                    alt="Logo"
                    height="28"
                  // vpace="13"
                  // hspace="14"
                  />
                </Link>
              </div>
            </div>
            <div style={{ display: "inline-block", marginRight: "15px" }}>
              <a
                href="https://aloa.gitbook.io/aloa-project-manual/"
                target="_blank"
              >
                Help & FAQ
              </a>
            </div>
            <NotificationsIcon />

            <div style={{ paddingTop: 2, paddingRight: 10, flex: 0.3 }}>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginTop: "3px",
                }}
              >
                {currentUser.image != null && currentUser.image != "" && (
                  <a href={`${process.env.REACT_APP_ACCOUNTS_URL}`}>
                    <img
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                      }}
                      src={currentUser.image}
                    />
                  </a>
                )}

                {(currentUser.image == null || currentUser.image === "") && (
                  <a href={`${process.env.REACT_APP_ACCOUNTS_URL}`}>
                    <Avatar
                      style={{
                        color: "#f56a00",
                        fontSize: "15px",
                        height: "35px",
                        width: "35px",
                        backgroundColor: "#fde3cf",
                        verticalAlign: "middle",
                      }}
                    >
                      {initials}
                    </Avatar>
                  </a>
                )}
              </div>
            </div>
          </MediaQuery>
        </div>
      </div>
    </div>
  );
}
