import auth0 from "auth0-js";
import { auth } from "../firebase";

export default class Auth {
  auth0;
  accessToken;
  idToken;
  userId;
  expiresAt;
  currentUser;
  loading;
  onUserUpdateCallbacks: ((any) => void)[] = [];
  callbackUri;

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.checkSession = this.checkSession.bind(this);
    this.setSession = this.setSession.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.handleFirebaseToken = this.handleFirebaseToken.bind(this);
    this.onUserUpdate = this.onUserUpdate.bind(this);
    this.refreshUser = this.refreshUser.bind(this);
    this.callbackUri = `${window.location.origin}/callback`;

    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: this.callbackUri,
      responseType: "token id_token",
      scope: "openid profile email",
    });

    auth.onAuthStateChanged(async (user) => {
      if (user || !this.currentUser) {
        this.checkSession()
          .then((session) => {
            const { email } = session.idTokenPayload;
            this.userId = session.idTokenPayload.sub;
            // getUserForEmail(email).then(result => {
            //   this.currentUser = result;
            //   this.onUserUpdateCallbacks.forEach(callback => callback(this.currentUser));
            // });
          })
          .catch(() => {
            this.onUserUpdateCallbacks.forEach((callback) => callback(null));
          });
      } else {
        this.onUserUpdateCallbacks.forEach((callback) => callback(null));
      }
    });
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          realm: "Username-Password-Authentication",
          username,
          password,
        },
        (error, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            resolve(authResult);
          } else if (error) {
            reject(Error(error.description));
          }
        }
      );
    });
  }

  logout() {
    this.accessToken = null;
    this.idToken = null;
    this.expiresAt = 0;

    this.auth0.logout({
      returnTo: `${process.env.REACT_APP_HOST}/login`,
    });

    auth
      .signOut()
      .then(() => { })
      .catch((error) => console.error(error))
      .finally(() => {
        this.loading = false;
      });
  }

  checkSession(): any {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
          resolve(authResult);
        } else {
          reject(err);
        }
      });
    });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;
  }

  handleAuthentication({ navigate, location }) {
    return new Promise((resolve, reject) => {
      if (/access_token|id_token|error/.test(location.hash)) {
        console.log("Handling callback...");
        this.auth0.parseHash(async (err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.setSession(authResult);
            const result = await this.handleFirebaseToken(authResult.idToken);
            console.log("Logged in successfully!");
            resolve(result);
          } else if (err) {
            console.error(err);
            reject(
              Error("Error parsing authentication credentials. Redirecting...")
            );
            setTimeout(() => navigate("/"), 1000);
          }
        });
      } else {
        reject(
          Error("Error parsing authentication credentials. Redirecting...")
        );
        setTimeout(() => navigate("/"), 1000);
      }
    });
  }

  // eslint-disable-next-line class-methods-use-this
  handleFirebaseToken(token) {
    return new Promise((resolve, reject) => {
      // auth.onAuthStateChanged(user => {
      //   if (user) {
      //     resolve(user);
      //   }
      // });

      fetch(process.env.REACT_APP_FIREBASE_TOKEN_API_ENDPOINT!, {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Origins": "*",
        },
      })
        .then(async (response) => {
          if (response.status < 400) {
            const responseJson = await response.json();
            const userCredentials = auth.signInWithCustomToken(responseJson.token);
            resolve(userCredentials);
          } else {
            reject(Error("Error logging in. Please try again later."));
          }
        })
        .catch((error) => reject(error));
    });
  }

  onUserUpdate(callback) {
    this.onUserUpdateCallbacks.push(callback);
  }

  refreshUser(email) {
    if (email) {
      // getUserForEmail(email).then(result => {
      //   this.currentUser = result;
      //   this.onUserUpdateCallbacks.forEach(callback =>
      //     callback(this.currentUser)
      //   );
      // });
    }
  }

  sendResetPasswordEmail(email) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        {
          connection: "Username-Password-Authentication",
          email,
        },
        (err, resp) => {
          if (err) {
            reject(err);
          } else {
            resolve(resp);
          }
        }
      );
    });
  }
}
