import React from "react";
import { IoMdEye } from "react-icons/io";
import { Popover } from "antd";

import { Avatar } from "antd";
import moment from "moment";
import { User } from "@/types";
import { Report } from "@/types/reports";

export function ActivityPopover({
  report,
  users,
  children,
}: {
  report: Report;
  users: Record<string, Partial<User>>;
  children: React.ReactNode;
}) {
  const reportActivity = [
    ...(report.seenBy?.map((seenBy) => ({
      user: getUserData(seenBy.user, users),
      date: seenBy.date,
      text: "Seen",
    })) ?? []),
    ...(report.downloadedBy?.map((downloadedBy) => ({
      user: getUserData(downloadedBy.user, users),
      date: downloadedBy.date,
      text: "Downloaded",
    })) ?? []),
  ].sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf());

  return (
    <div className="flex flex-col gap-2 justify-center min-w-fit">
      <Popover
        trigger={["hover"]}
        content={
          <div className="flex flex-col gap-4 items-center px-1 py-2 w-[320px] rounded-lg">
            {reportActivity.map((activity) => (
              <div className="flex flex-row gap-2 items-center justify-between w-full">
                <div className="flex flex-row gap-2 items-center">
                  {activity.user.image ? (
                    <Avatar src={activity.user.image} size={25} />
                  ) : (
                    <Avatar style={{ backgroundColor: "#f56a00" }} size={25}>
                      {activity.user.image}
                    </Avatar>
                  )}
                  <div className="text-base">{activity.user.name}</div>
                </div>
                <div className="text-sm flex gap-1 text-neutral-500">
                  <span>{activity.text}</span>
                  {moment(activity.date).fromNow()}
                </div>
              </div>
            ))}
          </div>
        }
      >
        {children}
      </Popover>
    </div>
  );
}

const getUserData = (
  userId: string,
  users: Record<string, Partial<User>>
): {
  name: string;
  initial: string;
  image?: string;
} => {
  const user = users[userId];
  return {
    name: user?.name ?? user?.email ?? "?",
    initial: (user?.name ?? user?.email ?? "?")[0],
    image: user?.image,
  };
};
