import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Draggable, Droppable } from "react-beautiful-dnd";
import _ from "lodash";
import TableSectionTitle from "./TableSectionTitle";
import TableRow from "../TableRow";
import TableSummaries from "../TableSummaries";
import actions from "../../../actions";
import sendNotification from "../../../api/notifications/sendNotification";
import {
  newTimestamp,
  getWidthForFieldType,
  getTotalWidthFromComponent,
  isArrayEqual,
  deepEqualCheck,
} from "../../utils";
import { useMediaQuery } from "react-responsive";
import LegacyChip from "../../CardList/Fields/LegacyChip";
import { useCurrentUser, useCurrentUserPermissions } from "../../../hooks";
import { AppState } from "@/types";

function TableSection({
  objects = [],
  projectId,
  project,
  component,
  sectionHeaderValue = "Bloop",
  children,
  index,
}) {
  const is13Inch = useMediaQuery({ maxWidth: 1500 });
  const [collapse, setCollapse] = useState(false);

  const currentUser = useCurrentUser();
  const collaborators = useSelector(
    (state: AppState) => state.collaborators[projectId] || {},
    deepEqualCheck
  );

  const permissions = useCurrentUserPermissions();

  const dispatch = useDispatch();

  useEffect(() => {
    // Use this to check collapse settings
    const shouldCollapse =
      project.collaborator_component_settings?.[currentUser.id]?.[
        component.info.collectionKey
      ]?.collapse_on?.includes(sectionHeaderValue) ?? false;

    if (collapse != shouldCollapse) {
      setCollapse(shouldCollapse);
    }
  }, [project]);

  // TODO - Lots of repeated logic here
  function collapseSection() {
    let collapseOnTemp =
      project.collaborator_component_settings?.[currentUser.id]?.[
        component.info.collectionKey
      ]?.collapse_on ?? [];

    collapseOnTemp = [...collapseOnTemp];

    if (collapse) {
      // Then we remove it from the array
      if (collapseOnTemp.includes(sectionHeaderValue)) {
        const itemIndex = collapseOnTemp.indexOf(sectionHeaderValue);
        collapseOnTemp.splice(itemIndex, 1);
      }
    } else {
      // Then we add it to the array
      collapseOnTemp.push(sectionHeaderValue);
    }

    setCollapse(!collapse);

    dispatch(
      actions.project.updateCollaboratorComponentSettings(
        projectId,
        currentUser.id,
        component.info.collectionKey,
        "collapse_on",
        collapseOnTemp
      )
    );
  }

  // Check if there is an index row, if so, put it at the front
  const hasIndexColumn = component.table?.index_row_key ?? false;
  var indexColumn: JSX.Element;

  if (hasIndexColumn) {
    const field = component.fields[component.table.index_row_key];

    if (field !== null) {
      indexColumn = (
        <div
          className="column-header index-row"
          style={getWidthForFieldType(field.fieldData.type, is13Inch)}
        >
          <div className="column-header-inner">{field.title}</div>
        </div>
      );
    }
  }

  var totalWidth = getTotalWidthFromComponent(component, is13Inch);
  var totalWidthpx = totalWidth + "px";

  const confirmDeletion = (sectionObjectType, collections, field) => {
    if (sectionObjectType === "object") {
      // Only if object is still in existence
      var object =
        collections[projectId][field.fieldData.collectionKey][
        sectionHeaderValue
        ];

      const itemsToRemove = objects.map((object: any) => ({
        object,
        collectionKey: component.info.collectionKey,
      }));

      if (object) {
        itemsToRemove.push({
          object,
          collectionKey: field.fieldData.collectionKey,
        });
      }

      dispatch(
        actions.collections.batchRemoveObjectFromCollection(
          itemsToRemove,
          projectId
        )
      );
    }
  };

  function addRow() {
    var object = {};

    if (object["created"] == null) {
      // This is added because some legacy objectConfigs actually use
      // "created" as an editable field, such as Standups
      object["created"] = newTimestamp();
    }

    // This is the next source of truth for creation, but those that don't
    // have this should rely on "created"
    object["created_timestamp"] = newTimestamp();
    object["created_by_user"] = currentUser.id;

    const fields = component.fields;

    Object.keys(fields).forEach((key) => {
      if (fields[key].fieldData.defaultValue) {
        object[key] = fields[key].fieldData.defaultValue;
      }
    });

    // This is to make sure it ends up in the right section
    object[component.table.pivotBy] = sectionHeaderValue;

    dispatch(
      actions.collections.addObjectToCollection(
        object,
        component.info.collectionKey,
        projectId,
        component.badge?.rules
      )
    );

    // Send a notification if the table does not have an index row specified (e.g. name)
    // Or the newly created object has an index value
    if (
      !component?.table?.index_row_key ||
      object[component?.table?.index_row_key] != null
    ) {
      sendNotification(
        component.info.collectionKey,
        null,
        "new",
        project,
        object,
        currentUser,
        component,
        collaborators
      );
    }
  }

  var tableColumns: string[] = [];

  if (component.table.columns != null) {
    tableColumns = component.table.columns;
  } else {
    tableColumns = Object.keys(component.fields);
  }

  return (
    <Draggable
      draggableId={sectionHeaderValue.toString()}
      key={sectionHeaderValue}
      index={index}
      type="section"
      isDragDisabled={
        component.table.pivotBy == null ||
        component.table.pivotByFixedValues != null
      }
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          data-cy="table-section"
        >
          <div
            style={{
              paddingBottom: "20px",
              minWidth: totalWidthpx,
              width: "100%",
            }}
          >
            <TableSectionTitle
              sectionHeaderValue={
                component.table.pivotBy
                  ? sectionHeaderValue
                  : component.info.name
              }
              field={
                component.table.pivotBy
                  ? component.fields[component.table.pivotBy]
                  : null
              }
              projectId={projectId}
              collapseSection={collapseSection}
              collapsed={collapse}
              component={component}
              confirmDeletion={confirmDeletion}
            />

            <div
              style={{
                boxShadow: "0 1px 5px 0 rgba(0,0,0,.1)",
                borderRadius: "8px",
              }}
            >
              <div>
                <div
                  style={{
                    borderTop: "1px solid #f1f1f1",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                  }}
                >
                  <div className="group-header-component">
                    {indexColumn && indexColumn}
                    {tableColumns.map(function (key) {
                      const field = component.fields[key];

                      var type = field?.fieldData?.type;

                      if (
                        component.table?.index_row_key !== key &&
                        type != null &&
                        permissions?.sections[component.info.collectionKey]
                          ?.fields[field.valueKey]?.read != false
                      ) {
                        return (
                          <div
                            key={key}
                            className="column-header"
                            style={getWidthForFieldType(type, is13Inch)}
                          >
                            <div className="column-header-inner">
                              {field.title}
                              <LegacyChip field={field} view="table" />
                            </div>
                          </div>
                        );
                      }
                    })}
                    {/*
                    <div
                      className="column-header footer"
                      style={{ paddingTop: "6px" }}
                  ></div> */}
                  </div>
                </div>

                {!collapse && (
                  <div>
                    <Droppable
                      droppableId={sectionHeaderValue.toString()}
                      type="row"
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{ minHeight: "25px" }}
                        >
                          {objects?.length == 0 && (
                            <div className="section-empty-message">
                              Drag items here or click "add" below to create a
                              new one ✌️
                            </div>
                          )}
                          {children}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}

                {permissions?.sections[component.info.collectionKey]?.create &&
                  !collapse && (
                    <div
                      className="table-row"
                      onClick={(event: any) => addRow()}
                    >
                      <div
                        className="new-row-cell"
                        style={{
                          flexBasis: totalWidth > 900 ? totalWidthpx : "900px",
                          flexGrow: "1000",
                          flexShrink: "0",
                          minWidth: totalWidth > 900 ? totalWidthpx : "900px",
                        }}
                        data-cy={"add-row_" + sectionHeaderValue}
                      >
                        +Add
                      </div>
                    </div>
                  )}
              </div>
            </div>

            {component.table?.summaries && (
              <TableSummaries
                component={component}
                tableColumns={tableColumns}
                objects={objects}
              />
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
}

/*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
const secAreEqual = (prevProps, nextProps) =>
  _.isEqual(prevProps.component, nextProps.component) &&
  isArrayEqual(prevProps.objects, nextProps.objects) &&
  prevProps.index == nextProps.index;

export default React.memo(TableSection, secAreEqual);
