import React from "react";
import ReferenceModal from "../../ReferenceModal";
import { FieldProps } from "./types";

export default function ReferenceField({
  field,
  valueEditable,
  projectId,
  collectionKey,
  checkPermissionsAndUpdateValue,
  currentObjectId,
  activelyEditing
}: FieldProps<any>) {

  return (
    <div className="attachments">
      <ReferenceModal
        field={field}
        referencedItems={valueEditable}
        projectId={projectId!}
        collectionKey={collectionKey}
        viewType="table"
        updateReferencedItems={checkPermissionsAndUpdateValue}
        currentObjectId={currentObjectId}
        activelyEditing={activelyEditing}
        processedLinkedFields={undefined}
        setProcessedLinkedFields={undefined}
      />
    </div>
  );
}
