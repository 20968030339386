import { AppState, ComponentConfig, GroupingComponentConfig } from "@/types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type ConfigState = AppState["configs"];

export const configsSlice = createSlice({
  name: "configs",
  initialState: {},
  reducers: {
    loadConfigsForProject: (
      state: ConfigState,
      action: PayloadAction<{
        projectId: string;
        sections: {
          [sectionId: string]: ComponentConfig | GroupingComponentConfig;
        };
      }>
    ) => {
      const { projectId, sections } = action.payload;

      if (!state[projectId]) {
        state[projectId] = {
          sections: {},
        };
      }

      if (!state[projectId].sections) {
        state[projectId].sections = {};
      }

      state[projectId].sections = { ...sections };
    },
  },
});

export const configsReducers = configsSlice.reducer;
export const { loadConfigsForProject } = configsSlice.actions;