import React from "react";
import { stringReplaceAll } from "../../utils";
import { FieldProps } from "./types";

export default function TextField({
  field,
  valueEditable,
  checkPermissionsAndUpdateValue,
  activelyEditing,
  setActivelyEditing,
}: FieldProps<string>) {
  return (
    <div className="full-width">
      {!activelyEditing && (
        <div style={{ padding: "10px 10px 10px 10px" }}>
          <input
            className="table-input"
            placeholder={field.fieldData.placeholder}
            value={stringReplaceAll(valueEditable, "\n", " ")}
            onChange={(e) => checkPermissionsAndUpdateValue(e.target.value)}
            onFocus={() => setActivelyEditing(true)}
          />
        </div>
      )}

      {activelyEditing && (
        <div className="pop-out">
          <input
            autoFocus
            className="expanded-input single-line"
            placeholder={field.fieldData.placeholder}
            value={valueEditable}
            onChange={(e) => checkPermissionsAndUpdateValue(e.target.value)}
            onBlur={() => setActivelyEditing(false)}
          />
        </div>
      )}
    </div>
  );
}
