import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const pageSlice = createSlice({
  name: "page",
  initialState: "",
  reducers: {
    setPage: (state, action: PayloadAction<string>) => {
      state = action.payload;
      return state;
    },
  },
});

export const pageReducers = pageSlice.reducer;
export const { setPage } = pageSlice.actions;
