import { createSlice } from "@reduxjs/toolkit";

const doDeepLinkActionSlice = createSlice({
  name: "doDeepLinkAction",
  initialState: false,
  reducers: {
    startDeepLinkAction: (state) => true,
    finishDeepLinkAction: (state) => false,
  },
});

export const doDeepLinkActionReducer = doDeepLinkActionSlice.reducer;
export const { startDeepLinkAction, finishDeepLinkAction } = doDeepLinkActionSlice.actions;