import { AppState, Collaborator } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CollaboratorState = AppState["collaborators"];

export const collaboratorSlice = createSlice({
  name: "collaborator",
  initialState: {},
  reducers: {
    loadCollaborator: (
      state: CollaboratorState,
      action: PayloadAction<{
        projectId: string;
        collaborator: Collaborator;
      }>
    ) => {
      const { projectId, collaborator } = action.payload;

      if (!state[projectId]) {
        state[projectId] = {};
      }

      state[projectId][collaborator.id] = collaborator;
    },
    loadCollaborators: (
      state: CollaboratorState,
      action: PayloadAction<{
        projectId: string;
        collaborators: {
          [collaborator_id: string]: Collaborator;
        };
      }>
    ) => {
      const { projectId, collaborators } = action.payload;

      if (!state[projectId]) {
        state[projectId] = {};
      }

      state[projectId] = {
        ...state[projectId],
        ...collaborators,
      };
    },
  },
});

export const collaboratorReducers = collaboratorSlice.reducer;
export const { loadCollaborator, loadCollaborators } =
  collaboratorSlice.actions;
