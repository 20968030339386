import { aloa_gitbook_manual } from "../../constants";
import React, { CSSProperties, useState } from "react";
import { RiShareBoxLine } from "react-icons/ri";

const FloatingActionButton = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div style={styles.container as CSSProperties}>
      <div style={styles.wrapper as CSSProperties}>
        {menuOpen && (
          <div
            style={styles.menuItem}
            onClick={() =>
              window.open(
                aloa_gitbook_manual,
                "_blank"
              )
            }
          >
            <RiShareBoxLine style={{ marginRight: 8, fontSize: "16px" }} /> Help
            & FAQ
          </div>
        )}
        <button
          style={styles.fabButton}
          onClick={() => setMenuOpen((prevState) => !prevState)}
        >
          <span style={{ fontSize: "18px", color: "black" }}>?</span>
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 999, // 999 because we want to be behind comment drawer at 1000
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  fabButton: {
    backgroundColor: "#F9F9F9",
    borderRadius: "50%",
    width: 36,
    height: 36,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    border: "1px solid #CCCCCC",
    cursor: "pointer",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: 10,
    marginBottom: 10,
    borderRadius: 4,
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
  },
};

export default FloatingActionButton;
