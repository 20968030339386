import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { copyStringToClipboard, hasUnreadThreads } from "../utils";
import { ThreadView, CommentDrawer } from "../Objects";
import { Menu, Popconfirm, message } from "antd";
import actions from "../../actions";
import { TbMessageCircle2, TbMessageCircle2Filled } from "react-icons/tb";
import { AppState } from "@/types";
import { useCurrentUser, useCurrentUserPermissions } from "../../hooks";

export default function TableRowCommentsMini({
  object,
  projectId,
  allowComments,
  component,
  setTableRowHighlighted,
}) {
  const currentUser = useCurrentUser();
  const permissions = useCurrentUserPermissions();

  const [commentsVisible, setCommentsVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const openQuery = "open";

    if (query.get("item") === object.id && query.get(openQuery)) {
      setCommentsVisible(true);
    }
  }, [window.location.search]);

  const confirmDeletion = () => {
    dispatch(
      actions.collections.removeObjectFromCollection(
        object,
        component.info.collectionKey,
        projectId,
        component.badge?.rules
      )
    );
  };

  const toggleCopy = () => {
    const url = `${window.location.origin}${window.location.pathname}?item=${object.id}`;
    copyStringToClipboard(url);
    message.success("Link copied to clipboard!");
  };

  const toggleCommentsVisible = () => {
    setCommentsVisible(!commentsVisible);
    setTableRowHighlighted(!commentsVisible, 1000);

    const searchQuery = new URLSearchParams(window.location.search);
    const openKey = "open";

    if (!commentsVisible) {
      searchQuery.set("item", object.id);
      searchQuery.set(openKey, "true");
    } else {
      searchQuery.delete("item");
      if (searchQuery.has(openKey)) {
        searchQuery.delete(openKey);
      }
    }

    const nextUrl = `${window.location.pathname}${commentsVisible ? "" : "?"
      }${searchQuery}`;

    window.history.pushState(null, "", nextUrl);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a onClick={toggleCopy} style={{ cursor: "pointer" }}>
          Share {component.info.vocabulary.singular_upper}
        </a>
      </Menu.Item>
      {permissions?.sections[component.info.collectionKey]?.delete && (
        <Menu.Item key="2">
          <Popconfirm
            placement="leftTop"
            title={
              "Are you sure you want to delete this " +
              component.info.vocabulary.singular_upper +
              "?"
            }
            onConfirm={confirmDeletion}
            okText="Yes"
            cancelText="No"
          >
            <a>Delete</a>
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div>
      <div>
        <CommentDrawer
          title={
            component.info.vocabulary.singular_upper +
            " #" +
            (object.ext_id || 0)
          }
          visible={commentsVisible}
          onClose={toggleCommentsVisible}
        >
          <ThreadView
            key={object.id}
            projectId={projectId}
            object={object}
            type={component.info.collectionKey}
            component={component}
            visible={commentsVisible}
          />
        </CommentDrawer>

        {allowComments && (
          <div onClick={toggleCommentsVisible}>
            <div>
              {object.badges &&
                object.badges.threads != null &&
                object.badges.threads != 0 && (
                  <div
                    className="h-8 w-10 cursor-pointer flex flex-row gap-1 items-center p-1 mr-1 text-[14px] rounded-md hover:bg-neutral-100 text-neutral-400 hover:text-neutral-800"
                    style={
                      hasUnreadThreads(currentUser, object)
                        ? { color: "#7f05f5" }
                        : { color: "#969696" }
                    }
                  >
                    {hasUnreadThreads(currentUser, object) ? (
                      <TbMessageCircle2Filled className="h-4 w-4" />
                    ) : (
                      <TbMessageCircle2 className="h-4 w-4" />
                    )}

                    <span>{object.badges.threads}</span>
                  </div>
                )}

              {(!object.badges || !object.badges.threads) && (
                <div className="mini-comment h-8 cursor-pointer flex flex-row gap-1 items-center p-1 mr-1 text-[12px] rounded-md hover:bg-neutral-100 text-neutral-400 hover:text-neutral-800">
                  <TbMessageCircle2 className="h-4 w-4" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
