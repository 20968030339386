import firebase, { db } from "../firebase";
import { addObjectToFirebase, updateObjectInFirebase } from "./object";
import { id, has } from "../components/utils";
import { fetchCollaborators } from "./collaborator";
import { changeNetworkStatus } from "../store/networkStatus";
import jsonLogic from "json-logic-js";
import _ from "lodash";
import { Project } from "@/types/project";
import { loadProjects, loadProject } from "../store/projects";

export const updateProjectViewConfig = (
  projectId,
  componentId,
  view_type,
  config_type,
  configuration_data
) => {
  return {
    type: "UPDATE_PROJECT_VIEW_CONFIG",
    projectId: projectId,
    componentId: componentId,
    view_type: view_type,
    config_type: config_type,
    configuration_data: configuration_data,
  };
};

export const addProject = (project) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const projects = _.cloneDeep(getState().projects.data);

    if (!project.id) project.id = id();

    projects[project.id] = project;

    dispatch(loadProjects({ projects }));
    dispatch(addObjectToFirebase(project, null, "projects"))
      .then(() => {
        resolve(project);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const fetchProject = (projectId, setUnsubscribe) => {
  return function (dispatch) {
    var projectRef = db.collection("projects").doc(projectId);
    const unsubscribe = projectRef.onSnapshot((doc) => {
      if (doc.exists) {
        const projectData = doc.data();

        if (!projectData?.id) {
          projectData!.id = doc.id;
        }

        dispatch(
          fetchCollaborators(projectData!.collaborators, projectData!.id)
        );
        dispatch(loadProject({ project: projectData as Project }));
      }
    });

    setUnsubscribe(unsubscribe);
  };
};

export const updateProjectOrder = (milestonesOrder, projectId) => {
  return function (dispatch, getState) {
    const project = getState().project;

    // @ts-ignore
    window.amplitude.getInstance().logEvent("PROJECT_UPDATED", {
      updateType: "milestone_order",
      user_role: getState().currentUser.role,
      project_id: getState().project.data.id,
      project_name: getState().project.data.name,
    });

    if (project) {
      project.data.milestonesOrder = milestonesOrder;
      dispatch(updateProjectOrderInFirebase(milestonesOrder, projectId));
    }
  };
};

export const updateCollectionViewConfig = (
  projectId,
  componentId,
  view_type,
  config_type,
  configuration_data
) => {
  return function (dispatch, getState) {
    db.collection("projects")
      .doc(projectId)
      .update({
        [`view_configs.default.configs.${componentId}.${view_type}.${config_type}`]:
          configuration_data,
      })
      .catch(console.error);
  };
};

export const updateRolesForProject = (projectId, roles) => (dispatch) =>
  new Promise((resolve, reject) => {
    db.collection("projects")
      .doc(projectId)
      .update({
        [`roles`]: roles,
      })
      .then(function () {
        resolve({});
      })
      .catch(console.error);
  });

export const updateCollaboratorComponentSettings = (
  projectId,
  collaboratorId,
  componentId,
  config_type,
  configuration_data
) => {
  return function (dispatch, getState) {
    // Right now we are just doing collapsing additions/removal, we can tweak this down the line

    db.collection("projects")
      .doc(projectId)
      .update({
        [`collaborator_component_settings.${collaboratorId}.${componentId}.${config_type}`]:
          configuration_data,
      });
  };
};

export const updateProjectOrderInFirebase = (milestonesOrder, projectId) => {
  return function (dispatch, getState) {
    if (milestonesOrder) {
      dispatch(changeNetworkStatus(false, "Saving...."));

      var updateObject = {
        milestonesOrder: milestonesOrder,
        last_updated_timestamp: new Date(),
        last_updated_user: getState().currentUser.id,
      };

      var docRef = db.collection("projects").doc(projectId);
      return docRef
        .update(updateObject)
        .then(function () {
          setTimeout(function () {
            dispatch(changeNetworkStatus(true, "All Changes Saved"));
          }, 1000);
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          dispatch(changeNetworkStatus(false, "Error Saving, Please Refresh!"));
        });
    }
  };
};

export const loadProjectsForUser = (userId) => (dispatch) =>
  new Promise((resolve, reject) => {
    db.collection("projects")
      .where("collaborator_ids", "array-contains", userId)
      .get()
      .then((querySnapshot) => {
        const projects: Partial<Project>[] = [];
        let activeProjects = 0;
        let archivedProjects = 0;

        querySnapshot.forEach(function (doc) {
          var data = doc.data();

          // great time to do some backfill on newer variables
          if (data.archived == null) {
            data["archived"] = false;
          }

          if (data.active == null) {
            data["active"] = true;
          }

          if (data.archived == true) {
            archivedProjects++;
          } else {
            activeProjects++;
          }

          var project = {
            name: data.name,
            last_updated_timestamp: data.last_updated_timestamp.toDate(),
            archived: data.archived,
            date_started: data.date_started.toDate(),
            collaborator_ids: data.collaborator_ids,
            id: doc.id,
            collaborators: data.collaborators,
          };

          projects.push(project);
        });

        dispatch(loadProjects({ projects }));
      });
  });

export const updateProject = (project, projectId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(
      loadProject({
        project: project as Project,
      })
    );
    dispatch(updateObjectInFirebase(project, projectId, "projects"))
      .then((project) => {
        resolve(project);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const addItemToCollectionSorting =
  (item, collection) => (dispatch, getState) => {
    const project = getState().project.data;
    let order: string[] = [];

    if (
      has(
        project,
        "view_configs.default.configs." + collection + ".cardlist.order"
      )
    ) {
      order = project.view_configs.default.configs[collection].cardlist.order;
    }

    order.push(item.id);

    dispatch(
      updateCollectionViewConfig(
        project.id,
        collection,
        "cardlist",
        "order",
        order
      )
    );
  };

export const updateProjectBadgeArrayForObject =
  (
    object: any,
    projectId: string,
    collection: string,
    rules: any,
    isDeletion?: boolean
  ) =>
    (dispatch, getState) =>
      new Promise((resolve, reject) => {
        // Check if there are rules for this collection, if not then return
        if (!rules) {
          resolve({});
        }

        var projectRef = db.collection("projects").doc(projectId);

        // If there are rules, add or remove from the correct array based on the rules
        // due to the atomic nature of arrayRemove, removal only happens if it is already there
        if (jsonLogic.apply(rules, object)) {
          // It fits the criteria for a badge, so add it to the array
          if (isDeletion) {
            projectRef
              .update({
                [`badges.${collection}`]:
                  firebase.firestore.FieldValue.arrayRemove(object.id),
              })
              .then(() => {
                resolve({});
              })
              .catch(console.error);
          } else {
            projectRef
              .update({
                [`badges.${collection}`]:
                  firebase.firestore.FieldValue.arrayUnion(object.id),
              })
              .then(() => {
                resolve({});
              })
              .catch(console.error);
          }
        } else {
          // It does not fit the criteria any longer so delete it from the array if it exists
          const projectData = getState().project.data;

          // If it is already not in the badge collection then we have nothing to remove so resolve
          if (projectData.badges && projectData.badges[collection] != null) {
            if (!projectData.badges[collection].includes(object.id)) {
              resolve({});
            }
          }

          projectRef
            .update({
              [`badges.${collection}`]: firebase.firestore.FieldValue.arrayRemove(
                object.id
              ),
            })
            .then(() => {
              resolve({});
            })
            .catch(console.error);
        }
      });

export const removeItemFromCollectionSorting =
  (itemId, collection) => (dispatch, getState) => {
    const project = getState().project.data;

    if (
      has(
        project,
        "view_configs.default.configs." + collection + ".cardlist.order"
      )
    ) {
      const indexToRemove = project.view_configs.default.configs[
        collection
      ].cardlist.order.findIndex((item) => item === itemId);

      if (indexToRemove >= 0) {
        project.view_configs.default.configs[collection].cardlist.order.splice(
          indexToRemove,
          1
        );
        dispatch(
          updateCollectionViewConfig(
            project.id,
            collection,
            "cardlist",
            "order",
            project.view_configs.default.configs[collection].cardlist.order
          )
        );
      }
    }
  };

export default {
  updateCollectionViewConfig: updateCollectionViewConfig,
  changeNetworkStatus: changeNetworkStatus,
  fetchProject: fetchProject,
  updateProjectOrder: updateProjectOrder,
  updateProject: updateProject,
  updateCollaboratorComponentSettings: updateCollaboratorComponentSettings,
  addProject: addProject,
  updateProjectBadgeArrayForObject: updateProjectBadgeArrayForObject,
  loadProjectsForUser: loadProjectsForUser,
  updateRolesForProject: updateRolesForProject,
};
