import { Button } from "antd";
import { useNavigate } from "react-router-dom";

export function AccessDenied() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <img src="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg" />
      <div
        style={{
          fontFamily: "Avenir Next",
          fontSize: "30px",
          fontWeight: 500,
          marginTop: "20px",
        }}
      >
        Error 403 (Access Denied)
      </div>
      <Button
        style={{ marginTop: "20px" }}
        onClick={() => navigate("/")}
        type="primary"
      >
        Go Back Home
      </Button>
    </div>
  );
}
