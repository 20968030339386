import { useState, useCallback } from "react";
import { auth, db } from "../firebase";
import { useDispatch } from "react-redux";
import { fetchOffers } from "../store/growth";
import { fetchAppVersion } from "../store/app";
import { updateUserAppVersion, fetchCurrentUser } from "../actions/user";
import { useEffect } from "react";
import { current_app_version } from "../constants";
import { User } from "../types";
import { login } from "../store/user";
import Auth from "../auth/Auth";
import posthog from "posthog-js";

export const auth0 = new Auth();

interface AuthStatus {
  user: User | undefined;
  loading: boolean;
}

export function useAuth(): AuthStatus {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const handleAuthChange = useCallback(async (authUser) => {
    if (authUser) {
      const doc = await db.collection("users").doc(authUser.uid).get();

      if (doc.exists) {
        const userData = doc.data() ?? {};
        userData.id = authUser.uid;

        posthog.identify(userData.id, {
          email: userData.email,
          name: userData.name,
        });

        // Load the user into the store
        dispatch(login(userData));

        // Fetch all growth offers
        dispatch(fetchOffers());

        // Check and update the user's app version if necessary
        checkAppVersion(userData);

        setUser(userData as User);
        setLoading(false);
      } else {
        auth.signOut();
      }
    }
  }, []);

  const checkAppVersion = (userData: any) => {
    // Fetch server side app version, this is purely to force a refresh
    dispatch(fetchAppVersion());

    if (!userData.app_version || userData.app_version != current_app_version) {
      // User version differs from this local version number or there isn't a version saved
      dispatch(updateUserAppVersion(current_app_version));
    }
  };

  // useEffect calling fetchCurrentUser
  // But we need to unusbscribe from the listener if the component unmounts
  useEffect(() => {
    let unsubscribe = () => { };

    //@ts-ignore
    dispatch(fetchCurrentUser((func) => (unsubscribe = func)));

    return unsubscribe;
  }, []);

  useEffect(() => {
    auth.onAuthStateChanged(handleAuthChange);

    const handleSession = async () => {
      try {
        const { idToken } = await auth0.checkSession();
        if (idToken) {
          console.log("handling firebase token..");
          auth0.handleFirebaseToken(idToken);
        }
      } catch ({ error }: typeof error) {
        if (error === "login_required") {
          auth.signOut();

          if (!user) {
            if (window.location.pathname.includes("register")) {
              window.location.href = `${process.env.REACT_APP_ACCOUNTS_URL}${window.location.pathname}`;
            } else if (!window.location.pathname.includes("callback")) {
              let redirectLocation = `${process.env.REACT_APP_ACCOUNTS_URL}/login?ref=manage`;

              if (window.location.pathname !== "/") {
                redirectLocation += `&path=${window.location.pathname}`;
              }

              if (window.location.search !== "") {
                redirectLocation += `&query=${window.location.search}`;
              }

              window.location.href = redirectLocation;
            }
          }
        }
      }
    };

    handleSession();
  }, []);

  return { user, loading };
}
