import "./TagSelector.css";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUserObject } from "../../../actions/user";
import { TwitterPicker } from "react-color";
import _ from "lodash";
import { BiCheck } from "react-icons/bi";
import { BiTrash } from "react-icons/bi";

export type Label = {
  id: string;
  name: string;
  color: string;
};

export const Label = ({
  labelOption,
  index,
  updateLabel,
  setLabelPickerActive,
  selected,
}) => {
  return (
    <div
      key={labelOption.id + "label_option"}
      className={"label-selector-option"}
      onClick={() => {
        updateLabel(labelOption.id);
      }}
    >
      <div className="label-content">
        <div
          className="label-box"
          style={{ backgroundColor: labelOption.color }}
        />

        <span>{labelOption.name}</span>
      </div>

      {selected && <BiCheck className="label-selector-option-check-icon" />}
    </div>
  );
};

export const LabelEditable = ({ label, deleteLabel, currentUser }) => {
  const dispatch = useDispatch();

  const [labelEditable, setLabelEditable] = useState(label);

  const [colorPickerActive, setColorPickerActive] = useState(false);

  useEffect(() => {
    setLabelEditable(label);
  }, [label]);

  return (
    <div className={`create-label-container inline`}>
      <div className={`label-input-container flex`}>
        <div className="label-color-picker">
          <div className="color-picker-color-outer">
            <div
              onClick={() => {
                setColorPickerActive(!colorPickerActive);
              }}
              className="color-picker-color"
              style={{
                backgroundColor: labelEditable.color,
              }}
            />
          </div>

          {colorPickerActive ? (
            <div className="color-picker-container">
              <TwitterPicker
                color={labelEditable.color}
                onChange={(color) => {
                  // LEt's update the color of labelEditable

                  setLabelEditable({
                    ...labelEditable,
                    color: color.hex,
                  });

                  // LEt's update the current user

                  var newLabels = currentUser.labels || {};

                  newLabels[labelEditable.id] = {
                    ...labelEditable,
                    color: color.hex,
                  };

                  var currentUserTemp = _.cloneDeep(currentUser);

                  currentUserTemp.labels = newLabels;

                  dispatch(updateUserObject(currentUserTemp));

                  setColorPickerActive(false);
                }}
              />
            </div>
          ) : null}
        </div>
        <input
          value={labelEditable?.name}
          onChange={(e) => {
            setLabelEditable({
              ...labelEditable,
              name: e.target.value,
            });
          }}
          onBlur={() => {
            const currentUserCopy = _.cloneDeep(currentUser);

            // Let's update the current user
            const newLabels = currentUserCopy.labels || {};

            newLabels[labelEditable.id] = labelEditable;

            currentUserCopy.labels = newLabels;

            dispatch(updateUserObject(currentUserCopy));
          }}
          placeholder="Label Name"
          className="label-name-input"
        />

        <div>
          <BiTrash
            onClick={() => {
              deleteLabel(labelEditable.id);
            }}
            className="label-delete-icon"
          />
        </div>
      </div>
    </div>
  );
};
