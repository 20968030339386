import { AppState, User } from "@/types";
import { useSelector } from "react-redux";

export function useCollaboratorsForProject(): User[] {
  return useSelector((state: AppState) => {
    const projectId = state.project?.data?.id;
    if (projectId) {
      return Object.values(state.collaborators[projectId]).map(
        (entry: any) => entry.data
      );
    }
    return [];
  });
}
