import React from "react";
import { Avatar, notification, message } from "antd";
import _ from "lodash";
import { db } from "../firebase";
import { getParsedNotificationText } from "../components/Notifications/parse";
import { buildContent } from "../components/Notifications/ContentBuilder";
import { getInitialsFromUser, newTimestampFromDate } from "../components/utils";
import { updateUser, refreshCurrentUser } from "./user";
import { NotificationText } from "../components/Notifications/NotificationText";
import { User, Notification } from "@/types";

const USERS_COLLECTION_ID = "users";
const NOTIFICATION_COLLECTION_ID = "notifications";

let unsubscribe: () => void = () => { };

const PAGINATION_SIZE = 15;
let paginationCursor;

export const setNotificationsVisible = (visible) => (dispatch) => {
  dispatch({
    type: "SET_NOTIFICATIONS_VISIBLE",
    visible,
  });
};

export const setNotificationsProject = (project) => (dispatch) => {
  dispatch({
    type: "SET_NOTIFICATIONS_PROJECT",
    project,
  });
};

export const clearNotificationsProject = () => (dispatch) => {
  dispatch({
    type: "SET_NOTIFICATIONS_PROJECT",
    project: null,
  });
};

const loadNotifications = (notifications) => (dispatch) => {
  dispatch({
    type: "LOAD_NOTIFICATIONS",
    notifications,
  });
};

export const listenForNewNotifications = (user) => (dispatch, getState) => {
  if (user && user.id) {
    const timestampNow = newTimestampFromDate(new Date());

    const query = db
      .collection(USERS_COLLECTION_ID)
      .doc(user.id)
      .collection(NOTIFICATION_COLLECTION_ID)
      .orderBy("date")
      .where("date", ">", timestampNow);

    unsubscribe = query.onSnapshot((snapshot) => {
      const notifications = {};
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          notifications[change.doc.id] = change.doc.data();

          if (!getState().notificationsState?.doNotDisturb) {
            showNotification(change.doc.data() as Notification, user);
          }
        }
      });

      dispatch(loadNotifications(notifications));
      dispatch(refreshCurrentUser());

      return { message: "Notifications loaded" };
    });
  }
};

const showNotification = (notificationItem: Notification, currentUser) => {
  const notificationContent = buildContent(notificationItem);

  const description = (
    <NotificationText message={notificationContent} currentUser={currentUser} />
  );
  const icon = notificationItem.user?.image ? (
    <Avatar
      src={notificationItem.user.image}
      size={30}
      style={{ marginRight: "7px" }}
    />
  ) : (
    <Avatar size={30} style={{ marginRight: "7px" }}>
      {getInitialsFromUser(notificationItem.user)}
    </Avatar>
  );

  notification.open({
    message: getParsedNotificationText(notificationItem, true),
    description,
    icon,
    duration: 5,
    onClick: () => { },
  });
};

export const fetchNotifications =
  (currentUser: Partial<User> = {}) =>
    (dispatch, getState) =>
      new Promise((resolve, reject) => {
        if (_.isEmpty(currentUser)) {
          currentUser = getState().currentUser;
        }
        const notifications = {};

        if (currentUser && currentUser.id) {
          let notificationQuery = db
            .collection(USERS_COLLECTION_ID)
            .doc(currentUser.id)
            .collection(NOTIFICATION_COLLECTION_ID)
            .orderBy("date", "desc")
            .limit(PAGINATION_SIZE);

          if (paginationCursor) {
            notificationQuery = notificationQuery.startAt(paginationCursor);
          }

          notificationQuery
            .get()
            .then((documentSnapshots) => {
              paginationCursor =
                documentSnapshots.docs[documentSnapshots.docs.length - 1];

              documentSnapshots.docs.forEach((doc) => {
                notifications[doc.id] = doc.data();
              });

              dispatch(loadNotifications(notifications));
              resolve(documentSnapshots.docs.length < PAGINATION_SIZE);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });

export const updateNotificationStatus =
  (item, status) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const { currentUser } = getState();

      dispatch(
        loadNotifications({
          [item.id]: {
            ...item,
            status,
          },
        })
      );

      db.collection(USERS_COLLECTION_ID)
        .doc(currentUser.id)
        .collection(NOTIFICATION_COLLECTION_ID)
        .doc(item.id)
        .update({
          status,
        })
        .then(() => {
          dispatch(
            updateUserNotificationCount(
              item,
              status === "read"
                ? notificationOperationTypes.MARK_AS_READ
                : notificationOperationTypes.ADD_UNREAD
            )
          );
          resolve(`Notification marked as ${status}`);
        })
        .catch((error) => reject(error));
    });

export const markAllNotificationsRead = () => async (dispatch, getState) => {
  // TODO: Move to an environment variable with paths (bulkMarkNotificationsRead) specified
  const BULK_MARK_READ_FUNCTION_URL =
    "https://us-central1-hermes-50f48.cloudfunctions.net/bulkMarkNotificationsRead";

  const {
    notifications,
    currentUser,
  }: { notifications: Record<string, Notification>; currentUser: User } =
    getState();

  try {
    fetch(BULK_MARK_READ_FUNCTION_URL, {
      method: "POST",
      mode: "no-cors",
      body: JSON.stringify({ userId: currentUser.id }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    const userRef = db.collection(USERS_COLLECTION_ID).doc(currentUser.id);
    const userResult = await userRef.update({ projectUnreadNotifications: {} });

    const storeUpdate = {};

    Object.values(notifications)
      .filter((notification) => notification.status === "unread")
      .forEach((item) => {
        storeUpdate[item.id] = { ...item, status: "read" };
      });

    dispatch(loadNotifications(storeUpdate));
    dispatch(updateUser({ ...currentUser, projectUnreadNotifications: {} }));

    return userResult;
  } catch (error) {
    message.error((error as any).message);
  }
};

export const deleteNotification = (item) => (dispatch, getState) => {
  const { currentUser, notifications } = getState();

  dispatch(
    updateUserNotificationCount(item, notificationOperationTypes.MARK_AS_READ)
  );

  delete notifications[item.id];
  dispatch(loadNotifications(notifications));

  return db
    .collection(USERS_COLLECTION_ID)
    .doc(currentUser.id)
    .collection(NOTIFICATION_COLLECTION_ID)
    .doc(item.id)
    .delete();
};

export const deleteAllNotifications = () => async (dispatch, getState) => {
  const { currentUser } = getState();

  const batch = db.batch();

  try {
    await new Promise((resolve, reject) => {
      db.collection(USERS_COLLECTION_ID)
        .doc(currentUser.id)
        .collection(NOTIFICATION_COLLECTION_ID)
        .get()
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
          });
          resolve({});
        })
        .catch((error) => reject(error));
    });
  } catch (error) {
    message.error(
      "Sorry, there was an error deleting the notifications. Please try again later"
    );
  }

  const userRef = db.collection(USERS_COLLECTION_ID).doc(currentUser.id);
  batch.update(userRef, { projectUnreadNotifications: {} });

  dispatch({
    type: "DELETE_ALL_NOTIFICATIONS",
  });
  dispatch(refreshCurrentUser());

  return batch.commit();
};

export const updateUserNotificationCount = (notification, operation) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();

    const userRef = db.collection(USERS_COLLECTION_ID).doc(currentUser.id);
    db.runTransaction((transaction) => {
      return transaction.get(userRef).then((userDoc) => {
        if (!userDoc.exists) {
          throw "User does not exist!";
        }

        const project = notification.project;
        let data = { ...currentUser.projectUnreadNotifications }

        if (operation === notificationOperationTypes.MARK_AS_READ) {
          const currentCount = getProjectNotificationCount(
            currentUser,
            project
          );
          data = setProjectNotificationCount(
            currentUser,
            project,
            Math.max(currentCount - 1, 0)
          );
        } else if (operation === notificationOperationTypes.ADD_UNREAD) {
          const currentCount = getProjectNotificationCount(
            currentUser,
            project
          );
          data = setProjectNotificationCount(
            currentUser,
            project,
            currentCount + 1
          );
        }

        transaction.update(userRef, data);
      });
    }).then(() => {
      dispatch(updateUser(currentUser));
    });
  };
};

const getProjectNotificationCount = (user, project) => {
  return user?.projectUnreadNotifications?.[project.id] ?? 0;
};

const setProjectNotificationCount = (user, project, newCount) => {
  if (user.projectUnreadNotifications) {
    const updateData = {
      projectUnreadNotifications: {
        ...user.projectUnreadNotifications,
        [project.id]: newCount,
      },
    };
    return updateData;
  } else {
    const updateData = {
      projectUnreadNotifications: {
        [project.id]: newCount,
      },
    };
    return updateData;
  }
};

const notificationOperationTypes = {
  MARK_AS_READ: "MARK_AS_READ",
  ADD_UNREAD: "ADD_UNREAD",
};

export const toggleDoNotDisturb = () => (dispatch, getState) => {
  const current = getState().notificationsState?.doNotDisturb;

  message.info(`Do not Disturb: ${current ? "Off" : "On"}`);

  dispatch({
    type: "SET_DO_NOT_DISTRUB",
    doNotDisturb: !current,
  });
};
