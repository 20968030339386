import "./TagSelector.css";
import React, { useState } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { PlusCircleIcon, ArrowSmRightIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import CreateLabel from "./CreateTag";
import { updateUserObject } from "../../../actions/user";
import _ from "lodash";
import { Project } from "@/types/project";
import { User } from "@/types";
import { Label, LabelEditable } from "./Label";
import { useCurrentUser } from "../../../hooks";

export function LabelSelectorContent({
  setLabelPickerActive,
  labels,
  updateLabel,
  project,
  selectedLabels,
}: {
  labelPickerActive: boolean;
  setLabelPickerActive: (labelPickerActive: boolean) => void;
  labels: Record<string, Label>;
  updateLabel: (label: Label) => void;
  project: Project;
  selectedLabels: string[];
}) {
  const [createLabelActive, setCreateLabelActive] = useState(false);

  const [editLabelsActive, setEditLabelsActive] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();

  const currentUser = useCurrentUser();

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
      className="label-selector-container"
      data-no-dnd="true"
    >
      <div className="label-selector-search">
        <SearchIcon className="label-selector-search-icon" />
        <input
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          placeholder="Search labels"
          className="label-selector-search-input"
        />
      </div>
      <div className="label-selector-options">
        {createLabelActive && (
          <CreateLabel
            searchTerm={searchTerm}
            setCreateLabelActive={setCreateLabelActive}
            setSearchTerm={setSearchTerm}
            currentUser={currentUser}
            project={project}
          />
        )}

        {!createLabelActive && (
          <div>
            {Object.values(labels)
              .filter((label) =>
                label.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map((labelOption, index) => {
                if (!editLabelsActive) {
                  return (
                    <Label
                      key={labelOption.id + "_label-option"}
                      labelOption={labelOption}
                      index={index}
                      updateLabel={updateLabel}
                      setLabelPickerActive={setLabelPickerActive}
                      selected={
                        selectedLabels &&
                        selectedLabels.includes(labelOption.id)
                      }
                    />
                  );
                } else {
                  return (
                    <LabelEditable
                      key={labelOption.id + "_label-option_editable"}
                      label={labelOption}
                      deleteLabel={(labelId) => {
                        // Let's update the current user
                        var newLabels = { ...currentUser.labels }

                        delete newLabels[labelId];

                        var currentUserTemp = _.cloneDeep(currentUser);

                        currentUserTemp.labels = newLabels;

                        dispatch(updateUserObject(currentUserTemp));
                      }}
                      currentUser={currentUser}
                    />
                  );
                }
              })}
            {!editLabelsActive && (
              <>
                <div
                  className={"create-label-button-container"}
                  onClick={() => setCreateLabelActive(true)}
                >
                  <PlusCircleIcon className="create-label-icon" />
                  Create {searchTerm ? `"${searchTerm}"` : "Label"}
                </div>

                <div
                  className={"create-label-button-container"}
                  onClick={() => {
                    setEditLabelsActive(!editLabelsActive);
                  }}
                >
                  Edit Labels
                  <ArrowSmRightIcon className="edit-labels-arrow-icon" />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {editLabelsActive && (
        <div className="floating-done-footer">
          <div
            onClick={() => {
              setEditLabelsActive(false);
            }}
            className="done-button"
          >
            Done
          </div>
        </div>
      )}
    </div>
  );
}
