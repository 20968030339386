import React, { useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FiCalendar } from "react-icons/fi";
import { BsChevronRight } from "react-icons/bs";
import { PiChatTeardropDotsBold } from "react-icons/pi";
import { Report } from "@/types/reports";
import { CircularProgress } from "./Common/CircularProgress";
import { useCurrentUser, useSetPage, useUsers } from "../../hooks";
import { AppState } from "../../types";
import { formatReportDates } from "./utils";
import { Avatar, Popover } from "antd";
import moment from "moment";
import actions from "../../actions";
import { IoMdEye } from "react-icons/io";
import { ActivityPopover } from "./Common/ActivityPopover";

export function PerformanceReportsPage() {
  const setPage = useSetPage();
  const { projectId } = useParams();

  const { loading, reports } = useSelector((state: AppState) => ({
    loading: state.reports.loading,
    reports: state.reports.data?.[projectId!],
  }));

  useEffect(() => {
    setPage("performance");
  }, [setPage]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="sectionLarge pb-8 overflow-x-auto">
      <div className="sectionTitle">Performance Reports</div>

      <div className="flex flex-col gap-4 w-full">
        {Object.values(reports ?? {}).map((report) => (
          <PerformanceSummaryCard key={report.id} report={report} />
        ))}
      </div>
    </div>
  );
}

const PerformanceSummaryCard = ({ report }: { report: Report }) => {
  const navigate = useNavigate();
  const { id } = useCurrentUser();
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const comments = useSelector(
    (state: AppState) => state.threads[report.id] || {}
  );

  const currentUser = useCurrentUser();
  const { users, loading: usersLoading } = useUsers(true);

  const itemsLength = report.items.filter((item) => !item.skipped).length;
  const passedItems = report.items.filter(
    (item) => item.passed && !item.skipped
  );

  const passedText = `${passedItems.length} of ${itemsLength} items passed`;
  const percentage = Math.round((passedItems.length / itemsLength) * 100);

  const commentCount = useMemo(
    () =>
      Object.values(comments).filter(
        (thread: any) => thread.item_id == report.id
      ).length,
    [comments, report, id]
  );
  const unreadComments = useMemo(() => {
    const unreadCount = Object.values(comments).filter(
      (thread: any) =>
        thread.item_id == report.id &&
        thread.created_by_user != id &&
        thread.last_updated_timestamp &&
        thread.last_updated_timestamp.toDate() >
        new Date(report.thread_read_timesetamps?.[id] ?? 0)
    ).length;
    return unreadCount > 0;
  }, [comments, report, id]);

  useEffect(() => {
    let unsubscribeThreads = () => { };

    dispatch(
      actions.threads.fetchThreadsFromFirebase(projectId, report.id, (func) => {
        unsubscribeThreads = func;
      })
    );

    return unsubscribeThreads;
  }, []);

  const uniqueSeenBy = useMemo(() => {
    const seenByMap = new Map();
    report.seenBy?.forEach((seenBy) => {
      const existingEntry = seenByMap.get(seenBy.user);
      if (
        !existingEntry ||
        moment(seenBy.date).isAfter(moment(existingEntry.date))
      ) {
        seenByMap.set(seenBy.user, seenBy);
      }
    });
    return Array.from(seenByMap.values());
  }, [report.seenBy]);

  return (
    <div
      className="bg-white shadow-sm border border-solid border-neutral-200 rounded-xl p-4 px-6 cursor-pointer hover:shadow-md hover:border-blue-100 flex "
      onClick={() => navigate(report.id)}
    >
      <div className="w-full md:grid md:grid-cols-10 md:gap-6 gap-4 flex flex-col flex-1">
        <div className="flex flex-col gap-1 items-start col-span-3 min-w-fit">
          <div className="text-base font-medium">
            Report #{report.ext_id ?? 0}
          </div>
          <div className="text-sm text-neutral-500">
            Created {moment(report.date).format("M/D/YYYY")}
          </div>
        </div>

        <div className="flex flex-col gap-1 items-start col-span-3">
          <div className="text-base flex flex-row gap-1 items-center">
            <FiCalendar />
            Report Period
          </div>
          <div className="text-sm text-neutral-500">
            {formatReportDates(report.periodStart)} -{" "}
            {formatReportDates(report.periodEnd)}
          </div>
        </div>

        <div className="flex flex-row gap-2 items-center col-span-3">
          <CircularProgress percentage={percentage} size="medium" />
          <div className="text-neutral-500 font-medium text-base">
            {passedText}
          </div>
        </div>

        {
          currentUser.acc_type == "2" && (
            <ActivityPopover report={report} users={users}>
              <div className="font-semibold text-base flex flex-row gap-1 items-center mr-4 text-blue-500 cursor-pointer">
                <IoMdEye className="w-5 h-5" />
                <span>{report.seenBy?.length || 0}</span>
              </div>
            </ActivityPopover>
          )
        }
      </div >

      <div
        className={`flex md:flex-row flex-col gap-4 items-center justify-end col-span-1 md:w-16`}
      >
        <div
          hidden={Number(commentCount) <= 0}
          className={`${unreadComments ? "text-blue-500" : "text-neutral-500"}
           flex flex-row font-semibold gap-1 text-xl items-center justify-end`}
        >
          <PiChatTeardropDotsBold />
          <div className="text-base">{commentCount}</div>
        </div>

        <BsChevronRight className="md:flex-none flex-1" />
      </div>
    </div >
  );
};
