import { useSelector } from "react-redux";
import { AppState } from "@/types";
import { useUserRole } from "./useUserRole";
import { useProject } from "./useProject";

export function useCurrentUserPermissions(projectId?: string): any {
  const { project } = useProject();
  const roles = useSelector((state: AppState) => state?.roles?.[project.id]);
  const currentUserRole = useUserRole(projectId);
  return roles?.[currentUserRole]?.permissions ?? undefined;
}
