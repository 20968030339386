import { PayloadAction, asyncThunkCreator, buildCreateSlice } from "@reduxjs/toolkit";
import { AppState, User } from "@/types";
import { db } from "../firebase";
import { current_app_version } from "../constants";
import { Project } from "@/types/project";

const createAppSlice = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

const appSlice = createAppSlice({
  name: "app",
  initialState: {
    version: current_app_version,
  },
  reducers: (create) => ({
    fetchAppVersion: create.asyncThunk(
      async (_: void) => {
        const query = await db.collection("growth").get();

        const snapshot = await db.collection("app").doc("info").get();
        const data = snapshot.data();
        return data?.version;
      },
      {
        fulfilled: (
          state: AppState["app"],
          action: PayloadAction<string | number>
        ) => {
          const currentVersion = state.version;
          const incomingVersion = action.payload;
          if (currentVersion == null) {
            state.version = incomingVersion;
          }

          if (currentVersion != null && currentVersion < incomingVersion) {
            state.version = incomingVersion;

            if (currentVersion != null) {
              window.location.reload();
            }
          }
        },
      }
    ),
  }),
});

export const appReducers = appSlice.reducer;
export const { fetchAppVersion } = appSlice.actions;
