import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  Document,
  Font,
  Page,
  View,
  Text,
  PDFDownloadLink,
  StyleSheet,
  PDFViewer,
  Svg,
  Path,
  Circle,
} from "@react-pdf/renderer";
import { formatReportDates, getReportPassedPercentage } from "../utils";
import { Report, ReportItem } from "../../../types/reports";
import { Project } from "@/types/project";
import { useDispatch } from "react-redux";
import {
  updateReportAsDownloaded,
  updateReportAsSeen,
} from "../../../store/reports";
import { useCurrentUser } from "../../../hooks";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  body: {
    flexGrow: 1,
    fontFamily: "Open Sans",
  },
  heading: {
    marginBottom: 4,
    fontFamily: "Lato Bold",
    color: "#111",
  },
  subHeading: {
    fontSize: 12,
    marginBottom: 5,
    color: "#888",
  },
  headerSection: {
    marginBottom: 5,
  },
  passedSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  circularProgressContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  checksPassed: {
    fontSize: 12,
    marginBottom: 5,
    color: "#666",
  },
  reportItemSection: {
    display: "flex",
    flexDirection: "column",
    marginTop: 8,
    gap: 12,
  },
  reportItem: {
    border: "1px solid #ccc",
    borderRadius: 5,
  },
  reportItemHeaderSection: {
    display: "flex",
    flexDirection: "row",
    gap: 4,
    padding: "12 15",
    alignItems: "center",
  },
  reportItemHeaderText: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    flex: "1 0",
  },
  reportItemTitle: {
    fontSize: 11,
    fontFamily: "Lato Bold",
    color: "#333",
  },
  reportItemText: {
    fontSize: 10,
    color: "#333",
  },
  actionsSection: {
    backgroundColor: "#eee",
    display: "flex",
    flexDirection: "column",
    gap: 4,
    padding: "12 16",
  },
  passedImage: {
    color: "green",
  },
});

Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

export function DownloadReportLink({
  project,
  report,
  children,
}: {
  project: Project;
  report: Report;
  children: React.ReactNode;
}) {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  return (
    <PDFDownloadLink
      document={<ReportDocument report={report} />}
      fileName={`${project.name} - Report${report.ext_id ? ` #${report.ext_id}` : ""
        }.pdf`}
      onClick={() =>
        dispatch(
          updateReportAsDownloaded({
            report,
            userId: currentUser.id,
          })
        )
      }
    >
      {children}
    </PDFDownloadLink>
  );
}

export function ReportPdfViewer({ report }: { report: Report }) {
  return (
    <PDFViewer width="100%" height="800px">
      <ReportDocument report={report} />
    </PDFViewer>
  );
}

function ReportDocument({ report }: { report: Report }) {
  const reportItems = report.items.filter((item) => !item.skipped);
  const percentage = useMemo(() => getReportPassedPercentage(report), [report]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
          <View style={styles.headerSection}>
            <Text style={styles.heading}>
              Report {report.ext_id ? `#${report.ext_id ?? 0}` : ""}
            </Text>
            <Text style={styles.subHeading}>
              {formatReportDates(report.periodStart)} -{" "}
              {formatReportDates(report.periodEnd)}
            </Text>
          </View>
          <View style={styles.passedSection}>
            {/* <View style={styles.circularProgressContainer}>
            <CircularProgress percentage={percentage} size="small" />
          </View> */}
            <Text style={styles.checksPassed}>
              {reportItems.filter((item) => item.passed).length}/
              {reportItems.length} checks passed
            </Text>
          </View>
          <View style={styles.reportItemSection}>
            {reportItems.map((item, index) => (
              <ReportItemView key={index} item={item} />
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
}

export const ReportItemView = ({ item }: { item: ReportItem }) => {
  return (
    <View style={styles.reportItem}>
      <View style={styles.reportItemHeaderSection}>
        <View style={styles.reportItemHeaderText}>
          <Text style={styles.reportItemTitle}>{item.title}</Text>
          <Text style={styles.reportItemText}>{item.description}</Text>
        </View>
        {item.passed ? (
          <CheckSvg color={"#22C55E"} width={20} height={20} />
        ) : (
          <WarningSvg color={"#FB923C"} width={20} height={20} />
        )}
      </View>
      {item.actionItems?.length > 0 && (
        <View style={styles.actionsSection}>
          <Text style={styles.reportItemTitle}>
            Evaluations & Actions Taken
          </Text>
          <Text style={styles.reportItemText}>{item.actionItems}</Text>
        </View>
      )}
    </View>
  );
};

type SvgProps = {
  color: string;
  width: number;
  height: number;
};

const CheckSvg = ({ color, width, height }: SvgProps) => {
  return (
    <Svg
      fill={color}
      stroke={color}
      stroke-width="0"
      viewBox="0 0 512 512"
      height={`${height}px`}
      width={`${width}px`}
    >
      <Path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></Path>
    </Svg>
  );
};

const WarningSvg = ({ color, width, height }: SvgProps) => {
  return (
    <Svg
      fill={color}
      stroke={color}
      stroke-width="0"
      viewBox="0 0 512 512"
      height={`${height}px`}
      width={`${width}px`}
    >
      <Path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"></Path>
    </Svg>
  );
};

// This is a WIP to get working with the PDF.
interface CircularProgressProps {
  percentage: number;
  size: "small" | "medium" | "large";
}

const CircularProgress = ({
  percentage,
  size = "large",
}: CircularProgressProps) => {
  // Define size configurations
  const sizeConfig = {
    small: { radius: 8, strokeWidth: 4, svgSize: 28 },
    medium: { radius: 14, strokeWidth: 5, svgSize: 40 },
    large: { radius: 28, strokeWidth: 10, svgSize: 80 },
  };

  // Get the current size configuration
  const { radius, strokeWidth, svgSize } = sizeConfig[size];

  // Calculate the stroke-dasharray based on the percentage
  const circumference = 2 * Math.PI * radius;
  const progress = (percentage / 100) * circumference;

  let color = "#22C55E";
  if (percentage <= 25) {
    color = "#FB923C";
  } else if (percentage <= 50) {
    color = "#F59E0B";
  }

  return (
    <Svg
      // className="transform rotate-[-90deg]"
      style={{
        transform: "rotate(90deg)",
      }}
      fill="#fff"
      stroke="#fff"
      stroke-width="0"
      width={svgSize}
      height={svgSize}
    >
      <Circle
        fill="#fff"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeDasharray={`${progress}, ${circumference}`}
        // strokeLineCap="round"
        r={radius}
        cx={svgSize / 2}
        cy={svgSize / 2}
      />
      <Circle
        stroke="#dadada"
        strokeWidth={strokeWidth / 4}
        fill="#fff"
        r={radius}
        cx={svgSize / 2}
        cy={svgSize / 2}
      />
    </Svg>
  );
};
